<template>
  <div class="with-footer">
    <el-form ref=form label-position="left" :model=form class=box-set label-width=120px :disabled="is_end == 1" v-loading.fullscreen.lock="dataLoading">
      <el-row :gutter="20">
        <el-col :md="18">
          <el-card header=基础信息 :body-style="{padding: '20px 30px'}">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item required label="活动标题">
                  <el-input v-model="form.activity_title" maxlength="20" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item label="活动副标题">
                  <el-input v-model="form.activity_subtitle"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24">
                <el-form-item required label="活动描述">
                  <el-input
                    type="textarea"
                    v-model="form.activity_desc"
                    :autosize="{ minRows: 6, maxRows: 20}"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item required label="报名时间">
                  <el-date-picker
                    v-model="dateTime"
                    type="datetimerange"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    @change="signUpDateTimeChange"
                    :default-time="['00:00:00', '23:59:59']"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item required label="活动主图">
                  <div>
                    <div @click="handleCoverImgChange" class="upload-box">
                      <img v-if="form.cover_url" :src="wximageurl + form.cover_url" class="avatar"/>
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                  </div>
                  <imgPicker :dialog-visible="coverImgDialog" :sc-status="isGetPics" @chooseImg="pickCoverImg"
                             @closeImgDialog="closeHomeImgDialog"></imgPicker>
                  <div class="frm-tips">
                    <p>最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M</p>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item required label="企微分享图">
                  <div @click="handleShareImgChange" class="upload-box">
                    <img v-if="form.share_img_url" :src="wximageurl + form.share_img_url" class="avatar"/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                  <imgPicker :dialog-visible="shareImgDialog" :sc-status="isGetPics" @chooseImg="pickShareImg"
                             @closeImgDialog="closeShareImgDialog"></imgPicker>
                  <div class="frm-tips">
                    <p>1.最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M</p>
                    <p>2.建议大小500*400</p>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item label="视频">
                  <div>
                    <videoPicker :data="itemVideo" @change="pickVideo"></videoPicker>
                    <el-button v-if="form.video_url" @click="deleteVideo" type="text">删除</el-button>
                  </div>
                  <div>
                    <span class="frm-tips">是否自动播放</span>
                    <el-switch v-model="form.video_auto_play == 1" @change="handleSwitch('video_auto_play', form.video_auto_play)"></el-switch>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item label="视频封面">
                  <div>
                    <div>
                      <div @click="handleVideoCoverImgChange" class="upload-box">
                        <img v-if="form.video_cover_url" :src="wximageurl + form.video_cover_url" class="avatar"/>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </div>
                    </div>
                    <imgPicker :dialog-visible="videoCoverImgDialog" :sc-status="isGetPics" @chooseImg="pickVideoCoverImg"
                               @closeImgDialog="closeVideoImgDialog"></imgPicker>
                    <div class="frm-tips">
                      <p>最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M</p>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24">
                <el-form-item label="礼品">
                  <el-input
                    type="textarea"
                    v-model="form.gift"
                    :autosize="{ minRows: 5, maxRows: 10}"
                  >
                  </el-input>
                  <div class="frm-tips">
                    填写规则
                    <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover"
                      content="多个礼品使用回车分隔，每一行作为一个礼品">
                      <i class="el-icon-question" slot="reference"></i>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <!-- <el-col :xs="24" :sm="12" :md="12">
                <el-form-item required label="隐私协议">
                  <template>
                    <div v-if="selectPrivacyTitle">
                      <span class="frm-tips">已设隐私协议：</span>
                      <span>{{this.selectPrivacyTitle}}</span>
                      <el-button @click="handleDeletePrivacyAgreement" type="text">删除</el-button>
                    </div>
                    <el-button size="medium" @click="handlePrivacyAgreement" type="primary" plain>设置隐私协议</el-button>
                  </template>
                </el-form-item>
              </el-col> -->
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item label="调研问卷">
                  <div v-if="surveyName">
                    <span class="frm-tips">已设问卷：</span>
                    <span>{{surveyName}} </span>
                    <el-button style="padding-bottom: 0" type="text" @click="handleDeleteQuestionnaire(1)">删除</el-button>
                  </div>
                  <div v-if="surveyName">
                    <span class="frm-tips">是否可跳过：</span>
                    <el-switch
                      v-model="form.questionnaire_allow_skip"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                    <span style="margin-left: 30px" class="frm-tips">报名成功页展示：</span>
                    <el-switch
                      v-model="form.questionnaire_show_signup_page"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </div>
                  <el-button size="medium" @click="onDialog(1)" type="primary" plain>设置调查问卷</el-button>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item label="评价问卷">
                  <div v-if="evaluateName">
                    <span class="frm-tips">已设问卷：</span>
                    <span>{{evaluateName}} </span>
                    <el-button style="padding-bottom: 0" type="text" @click="handleDeleteQuestionnaire(2)">删除</el-button>
                  </div>
                  <el-button size="medium" @click="onDialog(2)" type="primary" plain>设置评价问卷</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <el-card v-show="form.join_type === 'appointment'" :body-style="{padding: '20px 30px'}">
            <div slot="header" class="clearfix">
              <span>活动日期及场次</span>
              <el-button style="float: right;" size="small" type="primary" plain @click="openSessionDrawer">设置场次</el-button>
            </div>
            <!--活动预约可选场次-->
            <el-table :data="form.sessions">
              <el-table-column label="可预约日期">
                <template slot-scope="scope">
                  {{scope.row.session_date/1000 | datetime('yyyy-MM-dd')}}
                </template>
              </el-table-column>
              <el-table-column label="可预约场次">
                <template slot-scope="scope">
                  <div v-for="item in scope.row.session_names">
                    场次名称：{{item.name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="deleteFormSessionDate(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>

          <el-card :body-style="{padding: '20px 30px'}">
            <div slot="header" class="clearfix">
              <span>签到赠券设置</span>
              <el-button style="float: right;" size="small" type="primary" plain @click="openCardList(true,'1')">设置赠券</el-button>
            </div>
            <el-table
              :data="cardListSelect"
            >
              <el-table-column
                prop="card_id"
                label="卡券ID"
                width="80px"
              ></el-table-column>
              <el-table-column
                prop="title"
                label="券名称"
              >
              <template slot-scope="scope">
                <div>{{ scope.row.title }}</div>
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="副标题"
                    placement="right"
                  >
                    <span class="subtitle">{{ scope.row.subtitle }}</span>
                  </el-tooltip>
                </div>
                <div v-if="scope.row.card_type == 'gift'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="兑换商品"
                    placement="right"
                  >
                    <span class="subtitle">{{ scope.row.gift }}</span>
                  </el-tooltip>
                </div>
              </template>
              </el-table-column>
              <el-table-column
                label="卡券类型"
                width="140"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.card_type === 'discount'
                        ? 'primary'
                        : scope.row.card_type === 'cash'
                        ? 'danger'
                        : 'warning'
                    "
                    size="mini"
                  >{{ scope.row.card_type | formatCardStr }}</el-tag>

                  <div v-if="scope.row.card_type === 'gift'">
                    <el-tag
                      size="mini"
                      type="warning"
                      v-if="scope.row.use_scan == 'external_offline'"
                    >线下核销</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.use_scan == 'local_system'"
                    >本地系统核销</el-tag>
                    <el-tag
                      size="mini"
                      type="danger"
                      v-if="scope.row.use_scan == 'third_system'"
                    >对接第三方核销</el-tag>
                  </div>

                  <div v-if="scope.row.receive === 'password'">
                    <el-tag size="mini" type="info">口令卡领取</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="卡券有效期"
                width="280"
              >
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <template v-if="scope.row.takeEffect">
                  {{ scope.row.takeEffect }}
                  <div v-if="scope.row.end_date > 0">
                    统一过期时间：{{ scope.row.end_date | datetime }}
                    <span style="color: red">{{
                      Date.parse(new Date()) > scope.row.end_date * 1000
                        ? "已过期"
                        : ""
                    }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ scope.row.begin_time | datetime }}
                  <template v-if="scope.row.end_time">~</template>
                  {{ scope.row.end_time | datetime }}
                  <span style="color: red">{{
                    Date.parse(new Date()) > scope.row.end_time * 1000
                      ? "已过期"
                      : ""
                  }}</span>
                </template>
              </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="delCardList(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row style="margin-top: 20px;" v-if="form.need_sign_in == 'true'">
              <el-col :xs="24" :sm="12" :md="12">
                <el-form-item label="券预占">
                  <el-switch
                    v-model="form.card_lock"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="1"
                    :inactive-value="0"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <el-card :body-style="{padding: '20px 30px'}">
            <div slot="header" class="clearfix">
              <span>线下体验赠券设置</span>
              <el-button style="float: right;" size="small" type="primary" plain @click="openCardList(true,'2')">设置赠券</el-button>
            </div>
            <el-table
              :data="offlineCardListSelect"
            >
              <el-table-column
                prop="card_id"
                label="卡券ID"
                width="80px"
              ></el-table-column>
              <el-table-column
                prop="title"
                label="券名称"
              >
              <template slot-scope="scope">
                <div>{{ scope.row.title }}</div>
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="副标题"
                    placement="right"
                  >
                    <span class="subtitle">{{ scope.row.subtitle }}</span>
                  </el-tooltip>
                </div>
                <div v-if="scope.row.card_type == 'gift'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="兑换商品"
                    placement="right"
                  >
                    <span class="subtitle">{{ scope.row.gift }}</span>
                  </el-tooltip>
                </div>
              </template>
              </el-table-column>
              <el-table-column
                label="卡券类型"
                width="140"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="
                      scope.row.card_type === 'discount'
                        ? 'primary'
                        : scope.row.card_type === 'cash'
                        ? 'danger'
                        : 'warning'
                    "
                    size="mini"
                  >{{ scope.row.card_type | formatCardStr }}</el-tag>

                  <div v-if="scope.row.card_type === 'gift'">
                    <el-tag
                      size="mini"
                      type="warning"
                      v-if="scope.row.use_scan == 'external_offline'"
                    >线下核销</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.use_scan == 'local_system'"
                    >本地系统核销</el-tag>
                    <el-tag
                      size="mini"
                      type="danger"
                      v-if="scope.row.use_scan == 'third_system'"
                    >对接第三方核销</el-tag>
                  </div>

                  <div v-if="scope.row.receive === 'password'">
                    <el-tag size="mini" type="info">口令卡领取</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="卡券有效期"
                width="280"
              >
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <template v-if="scope.row.takeEffect">
                  {{ scope.row.takeEffect }}
                  <div v-if="scope.row.end_date > 0">
                    统一过期时间：{{ scope.row.end_date | datetime }}
                    <span style="color: red">{{
                      Date.parse(new Date()) > scope.row.end_date * 1000
                        ? "已过期"
                        : ""
                    }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ scope.row.begin_time | datetime }}
                  <template v-if="scope.row.end_time">~</template>
                  {{ scope.row.end_time | datetime }}
                  <span style="color: red">{{
                    Date.parse(new Date()) > scope.row.end_time * 1000
                      ? "已过期"
                      : ""
                  }}</span>
                </template>
              </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="delOfflineCardList(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>

          <el-card header="图文详情" :body-style="{padding: '20px 30px'}">
            <template>
              <vue-html5-editor :content="form.intro.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
              <span class="tpl_item img" @click="addImgPreview" style="">
              <i class="iconfont icon-image"></i>图片
            </span>
            </template>
            <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>
          </el-card>
        </el-col>
        <el-col :md="6">
          <el-card header="活动配置" :body-style="{padding: '20px 30px'}">
            <el-form-item label="是否开启">
              <el-switch v-model="form.is_open == 1" @change="handleSwitch('is_open', form.is_open)"></el-switch>
            </el-form-item>
            <el-form-item class="next-line" label="发布平台">
              <el-radio-group v-model="form.platform" :disabled="is_edit" size="small" @change="platformChange">
                <el-radio-button label="all">全平台</el-radio-button>
                <el-radio-button label="wxapp">微信</el-radio-button>
                <el-radio-button label="byte_mini">抖音</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="报名方式">
              <el-radio-group v-model="form.join_type" size="small" :disabled="is_edit">
                <el-radio-button label="appointment">预约</el-radio-button>
                <el-radio-button label="scene">现场</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item required label="每人可报名次数">
              <el-input v-model="form.join_limit_num" size="small" :min="1" ></el-input>
            </el-form-item>
            <el-form-item label="签到方式">
              <el-radio-group v-model="form.need_sign_in" :disabled="is_end === 2" size="small" @change="changeNeedSignIn">
                <el-radio-button label="true">后台签到</el-radio-button>
                <el-radio-button label="false">自行签到</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="next-line" label="核销方式">
              <el-radio-group v-model="form.sign_in_system" size="small" :disabled="is_end === 2" @change="changeSignInSystem">
                <el-radio-button label="local">数字签到码</el-radio-button>
                <el-radio-button label="guestbook">GuestBook</el-radio-button>
                <el-radio-button label="wecom">Wecom</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="可线下体验" >
              <el-switch v-model="form.allow_offline_join == 1" @change="handleSwitch('allow_offline_join', form.allow_offline_join)"></el-switch>
            </el-form-item>
          </el-card>
          <el-card header="报名人信息配置" :body-style="{padding: '20px 30px'}">
            <el-form-item label="姓名必填" >
              <el-switch v-model="form.must_name == 1" @change="handleSwitch('must_name', form.must_name)"></el-switch>
            </el-form-item>
            <el-form-item label="手机号必填" >
              <el-switch v-model="form.must_mobile == 1" @change="handleSwitch('must_mobile', form.must_mobile)"></el-switch>
            </el-form-item>
            <el-form-item label="预约码" >
              <el-switch v-model="form.open_reserve_code == 1" @change="handleSwitch('open_reserve_code', form.open_reserve_code)"></el-switch>
            </el-form-item>
            <el-form-item label="防黄牛" v-if="form.join_type === 'appointment' && form.must_mobile === 1">
              <el-switch v-model="form.sign_up_check == 1" @change="handleSwitch('sign_up_check', form.sign_up_check)"></el-switch>
            </el-form-item>
          </el-card>

          <el-card header="预约码设置" v-if="form.open_reserve_code == 1"  :body-style="{padding: '20px 30px'}">
            <el-form-item label="预约码名称" >
              <el-input v-model="form.reserve_code_name" size="small" :min="1" ></el-input>
            </el-form-item>
            <el-form-item label="预约码说明" >
              <el-input type="textarea" :rows="3" v-model="form.reserve_code_desc" size="small" :min="1" ></el-input>
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>


      <el-dialog
        title="选择优惠券"
        size="500px"
        direction="rtl"
        :visible.sync="cardListVisible"
        :before-close="openCardList"
      >
      <el-table
        :data="cardListData"
        @selection-change="seletCardList"

      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="card_id"
          label="卡券ID"
          width="80px"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="券名称"
        >
        <template slot-scope="scope">
          <div>{{ scope.row.title }}</div>
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              content="副标题"
              placement="right"
            >
              <span class="subtitle">{{ scope.row.subtitle }}</span>
            </el-tooltip>
          </div>
          <div v-if="scope.row.card_type == 'gift'">
            <el-tooltip
              class="item"
              effect="dark"
              content="兑换商品"
              placement="right"
            >
              <span class="subtitle">{{ scope.row.gift }}</span>
            </el-tooltip>
          </div>
        </template>
        </el-table-column>
        <el-table-column
          label="卡券类型"
          width="140"
        >
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.card_type === 'discount'
                  ? 'primary'
                  : scope.row.card_type === 'cash'
                  ? 'danger'
                  : 'warning'
              "
              size="mini"
            >{{ scope.row.card_type | formatCardStr }}</el-tag>

            <div v-if="scope.row.card_type === 'gift'">
              <el-tag
                size="mini"
                type="warning"
                v-if="scope.row.use_scan == 'external_offline'"
              >线下核销</el-tag>
              <el-tag
                size="mini"
                v-if="scope.row.use_scan == 'local_system'"
              >本地系统核销</el-tag>
              <el-tag
                size="mini"
                type="danger"
                v-if="scope.row.use_scan == 'third_system'"
              >对接第三方核销</el-tag>
            </div>

            <div v-if="scope.row.receive === 'password'">
              <el-tag size="mini" type="info">口令卡领取</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="卡券有效期"
          width="280"
        >
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <template v-if="scope.row.takeEffect">
            {{ scope.row.takeEffect }}
            <div v-if="scope.row.end_date > 0">
              统一过期时间：{{ scope.row.end_date | datetime }}
              <span style="color: red">{{
                Date.parse(new Date()) > scope.row.end_date * 1000
                  ? "已过期"
                  : ""
              }}</span>
            </div>
          </template>
          <template v-else>
            {{ scope.row.begin_time | datetime }}
            <template v-if="scope.row.end_time">~</template>
            {{ scope.row.end_time | datetime }}
            <span style="color: red">{{
              Date.parse(new Date()) > scope.row.end_time * 1000
                ? "已过期"
                : ""
            }}</span>
          </template>
        </template>
        </el-table-column>
      </el-table>

        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="getCardListData"
          :current-page="cardListCurrentPage"
          :total="cardListTotal"
          :page-size="cardListPageSize"
          >
        </el-pagination>
        <el-button type=primary class="center" @click="handleCardList">确认</el-button>
      </el-dialog>

      <el-drawer
        title="预约场次"
        :visible.sync="drawerVisible"
        size="500px"
        :before-close="handleDrawerClose"
        direction="rtl">
        <div style="padding: 20px">
          <el-button type="primary" plain @click="addSessionDate" style="margin-bottom: 20px">新增日期</el-button>

          <el-card v-for="(session, sessionIndex) in sessions" :key="sessionIndex">
            <el-date-picker
              v-model="sessions[sessionIndex].session_date"
              type="date"
              value-format="timestamp"
              :picker-options="pickerOptions"
              placeholder="选择日期">
            </el-date-picker>
            <el-button type="danger" plain @click="deleteSessionDate(sessionIndex)" icon="el-icon-delete" size="mini" circle></el-button>
            <p v-for="(name, sessionNameIndex) in session.session_names" style="margin-top: 20px">
              场次：<el-input class="input-b" v-model="session.session_names[sessionNameIndex].name" minlength="1"></el-input>
              <el-button type="danger" plain @click="deleteSessionName(sessionIndex, sessionNameIndex)" icon="el-icon-delete" size="mini" circle></el-button>
              <el-button v-if="sessionNameIndex === session.session_names.length - 1" type="primary" plain @click="addSessionName(sessionIndex)" icon="el-icon-plus" size="mini" circle></el-button>
            </p>
          </el-card>
          <div>
            <el-button type=primary class="center" @click="handleSessions">确认</el-button>
          </div>
        </div>
      </el-drawer>

      <el-dialog title="选择隐私协议" :visible.sync="privacyVisible">
        <el-table
          :data="privacyAgreementList"
          highlight-current-row
          @current-change="handlePrivacyAgreementChange">
          <el-table-column property="id" label="编号" width="150"></el-table-column>
          <el-table-column property="privacy_title" label="标题"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="privacyVisible = false">取 消</el-button>
          <el-button type="primary" @click="choosePrivacyAgreement">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>

    <el-footer class="global_footer section-footer with-border content-center">
      <el-button
        v-if="this.is_end !== 1"
        type=primary
        @click="submitOfflineActivity"
        :loading="submitBtnLoading"
      >保存
      </el-button>
      <el-button @click.native="handleCancel">返回</el-button>
    </el-footer>

    <DistributorSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :relDataIds="relDistributorIds"
      :get-status="setDistributorStatus"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDistributorDialogAction"
      returnType="selectRow"
    ></DistributorSelect>


    <el-dialog title="问卷列表" :visible.sync="dialogTableVisible" :show-close="false" class="elDialog">
      <el-radio-group v-model="questionnaire_radio">
        <el-radio v-for="(item,index) in problem.list" :key="index+'index'" :label="item.id">{{item.tpl_name}}</el-radio>
      </el-radio-group>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="questionnaire.pageSize"
        layout="total, prev, pager, next"
        :total="problem.total_count">
      </el-pagination>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDialog">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  createOfflineActivity,
  updateOfflineActivity,
  getOfflineActivityDetail,
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  getPrivacyAgreementList,
} from '@/api/promotions'
import {
  getCardList
} from '@/api/cardticket'
import store from '@/store'
import imgPicker from '@/components/imageselect'
import DistributorSelect from '@/components/function/distributorSelectOfflineActivity'
import videoPicker from '@/components/videoselect'
import {
  getQuestionnairetplList,
} from '@/api/wxa'
import { deepCopy } from '@/utils/deepCopy'

export default {
  inject: ['refresh'],
  components: {
    videoPicker,
    imgPicker,
    DistributorSelect,
  },
  data () {
    return {
      dataLoading: false,
      is_end: 0, // 活动是否已结束
      distributorStatusLoading: false,
      activity_id: '',
      is_edit: false,
      coverImgDialog: false,
      shareImgDialog: false,
      isGetPics: false,
      drawerVisible: false,
      loader: true,
      thumbDialog: false,
      isGetThumb: false,
      submitBtnLoading: false,
      useAllDistributor: 'distributor',
      relDistributorIds: [],
      distributorVisible: false,
      setDistributorStatus: false,
      distributor_info: [],
      privacyVisible: false,
      selectPrivacyTitle: '',
      privacyAgreementList: [],
      itemVideo: {},
      videoCoverImgDialog: false,
      join_limit_num_min: 1,
      must_mobile: 1,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date(new Date().toLocaleDateString()).getTime());
        },
      },
      form: {
        activity_title: '',
        activity_subtitle: '',
        activity_desc: '',
        cover_url: '',
        share_img_url: '',
        join_type: 'appointment',
        sign_up_start_time: 0,
        sign_up_end_time: 0,
        intro: '',
        join_limit_num: 1,
        must_mobile: 1,
        must_name: 1,
        open_reserve_code: '1',
        allow_offline_join: 0,
        reserve_code_name: '',
        reserve_code_desc: '',
        sign_up_check: 0,
        gift: '',
        is_open: 1,
        sessions: [],
        distributor_ids: [],
        privacy_agreement: 0,
        videos: '',
        video_url: '',
        video_cover_url: '',
        video_auto_play: 0,
        delete_session_ids: [],
        sign_in_system: 'local',
        need_sign_in: 'true',
        questionnaire_id: '',
        card_ids: [],
        platform: 'all',
        questionnaire_allow_skip: false,
        offline_join_card_ids: '',
        evaluation_questionnaire_id: '',
        card_lock: false,
        questionnaire_show_signup_page: false
      },
      deleteSessionIds: [],
      dateTime: '',
      sessions: [],
      bindStorePagination: { // 已绑定店铺&经销商分页数据
        page: 1,
        page_size: 20
      },
      totalStoreCount: 0,
      privacyPagination: {
        page: 1,
        pageSize: 20
      },
      privacyPaginationTotalCount: 0,
      privacyRow: {},
      dialogTableVisible: false,
      surveyName: '',
      evaluateName: '',
      questionnaire: {
        page: 1,
        pageSize: 10
      },
      questionnaire_radio: "",
      problem: {
        list: [],
        total_count: 0
      },
      cardListData: [], // 签到赠券
      cardListTotal: 1,
      cardListCurrentPage: 1,
      cardListPageSize: 10,
      cardListVisible: false,
      cardListSelect: [],
      offlineCardListSelect: [],
      visibleType: '',
      questionnaireType: ''
    }
  },
  methods: {
    platformChange () {
      // if (this.form.platform !== 'wxapp' && this.form.sign_in_system === 'wecom') {
      //   this.form.sign_in_system = 'local'
      // }
    },
    seletCardList (val) {
      if(val.length === 0){
        return
      }
      const list = this.visibleType == '1' ? this.cardListSelect : this.offlineCardListSelect
      const copy = deepCopy(val)
      const cp = deepCopy(list)
      const len = list.length

      if(len !== 0){
        // 去重
        for(let i = 0;i < copy.length;i++){
          if(cp.findIndex(el => el.card_id === copy[i].card_id) === -1){
            cp.push(copy[i])
          }
        }
        const arr = cp.map(item => item.card_id)
        if (this.visibleType == '1') {
          this.cardListSelect = cp
          this.form.card_ids = arr
        } else {
          this.offlineCardListSelect = cp
          this.form.offline_join_card_ids = arr
        }
        return
      }
      const arr = copy.map(item => item.card_id)
      if (this.visibleType == '1') {
        this.cardListSelect = copy
        this.form.card_ids = arr
      } else {
        this.offlineCardListSelect = copy
        this.form.offline_join_card_ids = arr
      }
    },
    handleCardList (){
      this.cardListVisible = false
      // 过滤？

    },
    delCardList(obj) {
      const l = this.cardListSelect.filter(item => item.card_id !== obj.card_id)
      this.cardListSelect = l
      const arr = l.map(item => item.card_id)
      this.form.card_ids = arr
    },
    delOfflineCardList(obj) {
      const l = this.offlineCardListSelect.filter(item => item.card_id !== obj.card_id)
      this.offlineCardListSelect = l
      const arr = l.map(item => item.card_id)
      this.form.offline_join_card_ids = arr
    },
    openCardList(bol,type){
      const b = bol === true ? true : false
      this.cardListVisible = b
      if (type) {
        this.visibleType = type
      }
      if(!b) {
        this.cardListData = []
        if(this.$route.query.activity_id){
          return
        }
        if (this.visibleType == '1') {
          this.cardListSelect = []
        } else {
          this.offlineCardListSelect = []
        }
      } else {
        this.getCardListData(1)
      }
    },
    getCardListData(page) {
      this.cardListCurrentPage = page
      this.cardListData = []
      const params = {
        status: '',
        card_type: this.form.platform != 'wxapp' ? 'gift' : ''   ,
        page_no: page,
        page_size: 10,
        store_self: false,
        use_scan: '',
        receive: ['condition', 'true'],
        not_exp: 1
      }
      getCardList(params).then(response => {
        if (response.data.data) {
          this.cardListData = response.data.data.list
          this.cardListTotal = response.data.data.total_count
        }
      })
    },
    changeNeedSignIn() {
      console.log(this.form.sign_in_system, this.form.need_sign_in)
      if (this.form.need_sign_in === 'false' && this.form.sign_in_system !== 'local' && this.form.sign_in_system !== 'wecom') {
        this.$message.error('核销方式必须为 数字签到码 或 Wecom')
        this.form.need_sign_in = 'true'
      }
    },
    changeSignInSystem(val, old) {
      if (this.form.need_sign_in === 'false' && this.form.sign_in_system !== 'local' && this.form.sign_in_system !== 'wecom') {
        this.$message.error('自行签到仅支持 数字签到码 或 Wecom 核销方式')
        this.form.sign_in_system = 'local'
      }
    },
    signUpDateTimeChange() {
      const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      if (dateTime) {
        this.form.sign_up_start_time = dateTime[0] === undefined ? 0 : dateTime[0]
        this.form.sign_up_end_time = dateTime[1] === undefined ? 0 : dateTime[1]
      } else {
        this.form.sign_up_start_time = this.form.sign_up_end_time = 0
      }
    },
    openSessionDrawer() {
      this.sessions = JSON.parse(JSON.stringify(this.form.sessions));
      this.deleteSessionIds = JSON.parse(JSON.stringify(this.form.delete_session_ids || []));
      this.drawerVisible = true
    },
    addSessionDate() {
      let defaultSessionData = {}
      if (this.sessions.length > 0) {
        defaultSessionData = JSON.parse(JSON.stringify(this.sessions[0]))
        defaultSessionData.session_date = ''
        defaultSessionData.session_names.forEach(v => {
          v.session_id = 0
        })
      } else {
        defaultSessionData = {
          session_date: '',
          session_names: [{'session_id': 0, 'name': ''}],
        }
      }
      this.sessions.push(defaultSessionData)
    },
    deleteSessionDate(index) {
      console.log(index, this.sessions[index])
      if (this.sessions[index].session_names.length > 0) {
        for (let i = 0; i < this.sessions[index].session_names.length; i ++) {
          this.deleteSessionIds.push(this.sessions[index].session_names[i].session_id)
        }
      }
      this.sessions.splice(index, 1)
    },
    deleteFormSessionDate(index) {
      if (this.form.sessions[index].session_names.length > 0) {
        this.form.delete_session_ids = []
        for (let i = 0; i < this.form.sessions[index].session_names.length; i ++) {
          this.form.delete_session_ids.push(this.form.sessions[index].session_names[i].session_id)
        }
      }
      this.form.sessions.splice(index, 1)
    },
    addSessionName(sessionIndex) {
      this.sessions[sessionIndex].session_names.push({'session_id': 0, 'name': ''})
    },
    deleteSessionName(sessionIndex, sessionNameIndex) {
      this.deleteSessionIds.push(this.sessions[sessionIndex].session_names[sessionNameIndex].session_id)
      this.sessions[sessionIndex].session_names.splice(sessionNameIndex, 1)
    },
    handleSwitch(key, value) {
      console.log(value)
      if (value == 0) {
        this.form[key] = 1
      } else {
        this.form[key] = 0
      }
    },
    handleSessions() {
      let _self = this
      let pass = true
      this.sessions.forEach(function (session_date) {
        console.log(session_date)
        if (session_date.session_names.length > 0) {
          session_date.session_names.forEach(function (session_name){
            if (session_name.name.trim() == '') {
              _self.$message.error('场次名称不能为空')
              pass = false
              return false;
            }
          })
        }
      })
      if (!pass) {
        return false
      }
      this.form.sessions = JSON.parse(JSON.stringify(this.sessions));
      this.form.delete_session_ids = JSON.parse(JSON.stringify(this.deleteSessionIds))
      this.sessions = []
      this.deleteSessionIds = []
      this.drawerVisible = false
    },
    handleDrawerClose() {
      this.$confirm('确认关闭？关闭后将不会保留已编辑的数据！')
        .then(_ => {
          this.drawerVisible = false
        })
        .catch(_ => {});
    },
    handleCoverImgChange () {
      this.coverImgDialog = true
      this.isGetPics = true
    },
    handleShareImgChange () {
      this.shareImgDialog = true
      this.isGetPics = true
    },
    pickCoverImg (data) {
      this.form.cover_url = data.url
      this.coverImgDialog = false
    },
    pickShareImg (data) {
      console.log(111)
      this.form.share_img_url = data.url
      this.shareImgDialog = false
    },
    handleVideoCoverImgChange () {
      this.videoCoverImgDialog = true
      this.isGetPics = true
    },
    pickVideoCoverImg (data) {
      this.form.video_cover_url = data.url
      this.videoCoverImgDialog = false
    },
    closeHomeImgDialog () {
      this.coverImgDialog = false
    },
    closeVideoImgDialog () {
      this.videoCoverImgDialog = false
    },
    closeShareImgDialog () {
      this.shareImgDialog = false
    },
    updateContent: function (data) {
      this.form.intro = data
    },
    addImgPreview: function () {
      this.thumbDialog = true
      this.isGetThumb = true
    },
    pickThumb: function (arr) {
      if (arr.length != 0) {
        arr.forEach(data => {
          if (data && data.url !== '') {
            this.thumbDialog = false
            var index = this.$refs.editor.$el.id
            var loc = this.$refs.editor
            var img = new Image()
            img.src = this.wximageurl + data.url
            if (loc.range) {
              loc.range.insertNode(img)
              var referenceNode = loc.range.endContainer
              if (referenceNode.className !== 'content') {
                loc.range.setStartAfter(referenceNode)
              } else {
                loc.range.setStart(loc.range.endContainer, loc.range.endOffset)
              }
            } else {
              loc.$refs.content.appendChild(img)
              loc.focus()
              loc.restoreSelection()
            }
            this.form.intro = loc.$refs.content.innerHTML
          }
        })
      }
    },
    closeThumbDialog: function () {
      this.thumbDialog = false
    },

    submitOfflineActivity() {
      this.submitBtnLoading = true
      let form = {
        ...JSON.parse(JSON.stringify(this.form))
      }
      form.distributor_ids = [] // 编辑活动忽略店铺
      form.use_all_distributor = this.useAllDistributor
      if (form.use_all_distributor === 'all') {
        form.distributor_ids = ''
      }


      console.log("🚀 ~ updateOfflineActivity ~ form:aaaaaaaaaaaaa", form)


      if (this.activity_id) {
        form.activity_id = this.activity_id
        updateOfflineActivity(form).then(response => {
          if (response.data.data.activity_id) {
            this.$message.success('更新成功')
            this.$router.go(-1)
          } else {
            this.$message.error('更新失败')
          }
          this.submitBtnLoading = false
        }) . catch (error => {
          // this.$message.error('更新失败')
          this.submitBtnLoading = false
        })
      } else {
        createOfflineActivity(form).then(response => {
          if (response.data.data.activity_id) {
            this.$message.success('创建成功')
            this.$router.go(-1)
          } else {
            this.$message.error('创建失败')
          }
          this.submitBtnLoading = false
        }) . catch (error => {
          // this.$message.error('创建失败')
          this.submitBtnLoading = false
        })
      }
    },
    handleCancel: function () {
      this.$router.go(-1)
    },

    shopTypeChange (val) {
      // this.form.distributor_ids = []
      this.distributor_info = []
      this.setDistributorStatus = false
      if (this.activity_id) {
        // this.setBindStoreList()
      }
    },
    async setBindStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: bindList } = await getBindOfflineActivityStoreList({
        activity_id: this.activity_id,
        ...this.bindStorePagination
      }) .catch (error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributor_info = bindList.data.list
    },
    addDistributorAction () {
      this.distributorVisible = true
      this.setDistributorStatus = true
      // this.relDistributorIds = this.form.distributor_ids
    },
    chooseDistributorAction (data) {
      this.distributorVisible = false
      if (!data || data === null || data.length <= 0) {
        this.distributor_info = []
        return
      }
      this.distributor_info = data
      this.form.distributor_ids = []
      for (let i = 0; i < data.length; i++) {
        const id = data[i].distributor_id
        this.form.distributor_ids.push(Number(id))
      }
    },
    closeDistributorDialogAction () {
      this.distributorVisible = false
    },
    deleteDistritutorRow (index) {
      this.distributor_info.splice(index, 1)
      let shopDatas = this.distributor_info
      this.form.distributor_ids = []
      for (let i = 0; i < shopDatas.length; i++) {
        if (this.form.distributor_ids.indexOf(Number(shopDatas[i].distributor_id)) < 0) {
          this.form.distributor_ids.push(Number(shopDatas[i].distributor_id))
        }
      }
      this.relDistributorIds = this.form.distributor_id
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setBindStoreList()
    },
    changeDistributorJoinStatus(row) {
      this.distributorStatusLoading = true
      if (!this.activity_id) {
        this.$message.error('编辑失败')
        return
      }
      let changeStatus = 1
      if (row.status === '1') {
        changeStatus = 2
      }
      let params = {
        status: changeStatus,
        distributor_id: row.distributor_id,
        activity_id: this.activity_id,
      }
      changeDistributorJoinStatus(params).then(response => {
        if (response.data.data.success == true) {
          this.distributorStatusLoading = false
          this.$message.success('修改成功')
          this.setBindStoreList()
        } else {
          this.$message.error('修改失败')
          this.distributorStatusLoading = false
        }
      }).catch(error => {
        // this.$message.error('修改失败')
        this.distributorStatusLoading = false
      })
    },

    getOfflineActivityDetail() {
      this.dataLoading = true
      let params = {
        'activity_id': this.activity_id
      }
      getOfflineActivityDetail(params).then(response => {
        if (response.data.data) {
          let res = response.data.data
          this.is_end = res.is_end
          this.form = res
          if (res.discount_cards) {
            this.form.card_ids = res.discount_cards.map(item => item.card_id)
          }
          if (res.offline_join_discount_cards) {
            this.form.offline_join_card_ids = res.offline_join_discount_cards.map(item => item.card_id)
          }
          this.cardListSelect = res.discount_cards
          this.offlineCardListSelect = res.offline_join_discount_cards || []
          // 调查问卷
          if (res.questionnaire_id) {
            // this.questionnaire_radio = res.questionnaire_id
            this.surveyName = res.questionnaire_detail ? res.questionnaire_detail.tpl_name : ''
          }
          //TODO评价问卷
          if (res.evaluation_questionnaire_id) {
            // this.form.evaluation_questionnaire_id = this.evaluation_questionnaire_id
            this.evaluateName = res.evaluation_questionnaire_detail ? res.evaluation_questionnaire_detail.tpl_name : ''
          }

          this.itemVideo = {media_id: res.video_url, url:res.video_url}
          this.selectPrivacyTitle = res.privacy_title
          this.dateTime = [res.sign_up_start_time, res.sign_up_end_time]
          this.useAllDistributor = res.use_all_distributor || 'all'
          if (res.distributor_ids) {
            this.relDistributorIds = res.distributor_ids
          }
          this.join_limit_num_min = res.join_limit_num
          this.dataLoading = false
        }
      }).catch (error => {
        console.log("错误: ", error)
        this.dataLoading = false
      });
    },
    getPrivacyAgreementList() {
      let params = this.privacyPagination
      getPrivacyAgreementList(params).then(response => {
        this.privacyAgreementList = response.data.data.list
        this.privacyPaginationTotalCount = response.data.data.total_count
      })
    },
    handlePrivacyAgreement() {
      this.getPrivacyAgreementList()
      this.privacyVisible = true
    },
    handlePrivacyAgreementChange(val) {
      this.privacyRow = val
    },
    choosePrivacyAgreement() {
      this.selectPrivacyTitle = this.privacyRow.privacy_title
      this.form.privacy_agreement = this.privacyRow.id
      this.privacyVisible = false
    },
    handleDeletePrivacyAgreement() {
      this.selectPrivacyTitle = ''
      this.form.privacy_agreement = 0
    },
    handleDeleteQuestionnaire(i) {
      if (i == 1) {
        this.surveyName = ''
        this.form.questionnaire_id = ''
      } else {
        this.form.evaluation_questionnaire_id = ''
        this.evaluateName = ''
      }
    },
    pickVideo (data) {
      this.form.videos = data.media_id
      this.form.video_url = data.url
    },
    deleteVideo() {
      this.itemVideo = {}
      this.form.videos = ''
      this.form.video_url = ''
    },
    onDialog(t) {
      // 标识当前点击的问卷
      this.questionnaireType = t
      // 勾选对应的问卷
      if(t == 1){
        this.questionnaire_radio = this.form.questionnaire_id
      }else{
        this.questionnaire_radio = this.form.evaluation_questionnaire_id
      }
      var params = {
        page: this.questionnaire.page,
        page_size: this.questionnaire.pageSize,
      }
      getQuestionnairetplList(params).then(res => {
        this.dialogTableVisible = true
        if (res.data.data.list.length > 0) {
          this.problem.list = res.data.data.list
          this.problem.total_count = res.data.data.total_count
        } else {
          this.problem.list = []
          this.problem.total_count = 0
        }
      })
      .catch( error => {
        this.loading = false
      })
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
    handleCurrentChange(val) {
      this.questionnaire.page = val
      this.onDialog()
    },
    confirmDialog() {
      if (this.questionnaire_radio == '') {
        return this.$message.error('请选择问卷')
      }

      let name = ''
      this.problem.list.forEach((item) => {
        if (item.id == this.questionnaire_radio) {
          name = item.tpl_name
        }
      })

      if (this.questionnaireType == 1) {
        this.form.questionnaire_id = this.questionnaire_radio
        this.surveyName = name
      } else {
        this.form.evaluation_questionnaire_id = this.questionnaire_radio
        this.evaluateName = name
      }
      this.dialogTableVisible = false
    }
  },
  mounted () {
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id
      this.is_edit = true
      this.getOfflineActivityDetail();

      // this.setBindStoreList();
    }
    this.loader = false
  },
}
</script>

<style lang="scss">
  .vue-html5-editor {
    vertical-align: top;
    display: inline-block;
  }
  .vue-html5-editor {
    width: 80%;
  }
  .elDialog .el-radio-group{
    display: flex;
    flex-direction: column;
  }
  .elDialog .el-radio{
    margin-bottom: 10px
  }
  .next-line .el-form-item__content {
    margin-left: 0 !important;
    .el-radio-group {
      display: flex;
      width: 100%;
      .el-radio-button {
        flex: 1;
        .el-radio-button__inner {
          width: 100%;
        }
      }
    }
  }
  .with-footer {
    padding-bottom: 60px;
  }
  .global_footer{
    position:fixed;
    right:0;
    bottom:0;
    width:calc(100% - 250px);
    background: #fff;
    padding: 12px 24px;
    line-height: 44px;
    border-top: 1px solid #f0f0f0;
    transition: width .3s cubic-bezier(.645,.045,.355,1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1200;
  }
</style>
