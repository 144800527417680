<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1 && $route.path.indexOf('_editor') === -1 ">
      <el-row class="content-bottom-padded" :gutter="20">
        <el-col :span="4">
          开启体测： <el-switch v-model="setting.status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-col>
      </el-row>
      <el-row class="content-bottom-padded" :gutter="20" v-if="setting.status == 1">
        <el-col :span="4"  v-if="setting.status == 1 && setting.temp_id != 0">
           <div>当前体测模板：{{setting.temp_name}}</div>
        </el-col>
        <el-col :span="4">
           <el-button round @click="batchChooseFormwork">选择体测模板</el-button>
        </el-col>
      </el-row>
      <el-card shadow="never" v-if="setting.status == 1 && setting.temp_id != 0">
        <el-table :data="ItemsList" :height="wheight-280" v-loading="loading" >
          <el-table-column prop="mobile" label="会员手机号" width="140"></el-table-column>
          <el-table-column prop="username" label="姓名" width="120"></el-table-column>
          <el-table-column v-for="(col, index) in colsTitle" :key="index" :prop="col.prop" :label="col.label" width="100">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i class="iconfont icon-search-plus"  @click="goToDetail(scope.$index, scope.row)">详情</i>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="params.page"
            :page-sizes="[10,20,50]"
            :total="total_count"
            :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-card>
      <el-dialog title="选择体侧模板" class="right-dialog" :visible.sync="FormworkVisible">
        <template>
          <el-form ref="formwork" :model="formwork">
            <el-form-item>
              <el-radio-group v-model="formwork.resource">
                <el-radio :label="item.id" v-for="(item,index) in FormworkList" :key="index">{{item.tem_name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="onSubmitFormwork">确定添加</el-button>
            </el-form-item>
          </el-form>
        </template>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Message } from 'element-ui'
  import { saveSetting, getSettingList, getSettingInfo,
   updateSetting, deleteSetting, restoreSetting,
    getTemplateList, physicalFirst, physicalNormal, allUserList } from '../../../api/selfhelpform'
  export default {
    provide() {
      return {
        refresh: this.getDataList
      }
    },
    data () {
      return {
        loading: false,
        formwork: {
          resource: ''
        },
        setting: {
          status: 0,
          temp_id: 1,
          temp_name: null
        },
        total_count: 0,
        colsTitle: [],
        ItemsList: [],
        // ItemsList: [{
        //   mobile: '1391111111',
        //   user_name: 'ceshi',
        //   key_index: [
        //     {score: 99, name: 'ddd'},
        //     {score: 99, name: 'ddd'},
        //     {score: 99, name: 'ddd'},
        //     {score: 99, name: 'ddd'},
        //     {score: 99, name: 'ddd'},
        //   ]
        // }],
        params: {
          page: 1,
          pageSize: 10,
          tem_name: '',
          tem_type: 'basic_entry',
          is_valid: 1,
        },
        list_parmas: {
          page: 1,
          pageSize: 10
        },
        FormworkVisible: false,
        FormworkList: []
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      physicalFirst() {
        this.$nextTick(() => {
          physicalFirst().then(response => {
            if(response.data.data.status == 1) {
              this.physicalNormal({
                status: Number(response.data.data.status),
                temp_id: response.data.data.temp_id,
                temp_name: response.data.data.temp_name
              })
            }
            this.setting.status = Boolean(response.data.data.status)
            this.setting.temp_id = response.data.data.temp_id
            this.setting.temp_name = response.data.data.temp_name
          })
        })
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getDataList()
      },
      handleSizeChange(pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getDataList()
      },
      // 模板列表
      getFormworkList() {
        this.loading = true
        getTemplateList(this.params).then(response => {
          this.FormworkList = response.data.data.list
          this.total_count = response.data.data.total_count
          this.loading = false
        })
      },
      // 拉取列表数据
      batchChooseFormwork() {
        this.getFormworkList()
        this.FormworkVisible = true
      },
      onSubmitFormwork() {
        this.physicalNormal({
          status: Number(this.setting.status),
          temp_id: this.formwork.resource,
          temp_name: this.setting.temp_name
        })
        this.FormworkVisible = false
      },
      physicalNormal(query) {
        physicalNormal(query).then(response => {
          this.getDataList()
        })
      },
      // 加载列表数据
      getDataList(list_parmas) {
        allUserList(list_parmas).then(response => {
          this.colsTitle = response.data.data.colstitle
          this.ItemsList = response.data.data.list
        })
      },
      goToDetail(index, row) {
        // this.$router.push({ path: this.matchHidePage('detail/') + row.user_id })
        this.$router.push({
          path: this.matchHidePage('detail'),
          query: {
            user_id: row.user_id,
          }
        })
      }
    },
    mounted () {
      this.physicalFirst()
    },
    watch: {
    }
  }
</script>
