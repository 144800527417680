<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <el-row :gutter="20">
        <el-col :md="12" :lg="12">
          <shop-select v-if="$store.getters.login_type != 'distributor'" distributors  @update="storeSearch"></shop-select>
        </el-col>
        <el-col :md="8" :lg="8">
          <el-date-picker v-model="create_time" type="daterange" value-format="yyyy/MM/dd" placeholder="根据添加时间筛选" style="width: 100%;" @change="dateChange" size="mini"></el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <!--        <el-col :md="4" :lg="8">-->
        <!--          <el-select v-model="params.item_type" @change="itemTypeChange" placeholder="商品类型" clearable style="width: 100%;" size="mini">-->
        <!--            <el-option label="全部" value="0"></el-option>-->
        <!--            <el-option label="服务类商品" value="service"></el-option>-->
        <!--            <el-option label="实体类商品" value="normal"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <el-col :md="4" :lg="8">
          <el-button size="mini" type="primary" icon="plus" @click="addActivityData">添加满赠促销</el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="待开始" name="waiting"></el-tab-pane>
        <el-tab-pane label="进行中" name="ongoing"></el-tab-pane>
        <el-tab-pane label="已结束" name="end"></el-tab-pane>
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="适用会员">
                  <el-tag v-for="(item,index) in scope.row.member_grade" :key="index">{{item}}</el-tag>
                </el-form-item>
                <el-form-item label="创建时间">
                  <span>{{scope.row.created_date}}</span>
                </el-form-item>
                <el-form-item label="适用商品">
                  <div v-for="(item,index) in scope.row.items" :key="index">{{item.item_name}}</div>
                </el-form-item>
                <el-form-item label="适用店铺">
                  <span v-if="scope.row.use_shop">
                    <div v-for="(item,index) in scope.row.shops" :key="index">{{item.shop_name}}</div>
                  </span>
                  <span v-else> <div>全部店铺</div> </span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="marketing_id" width="60" label="编号"></el-table-column>
          <el-table-column prop="marketing_name" min-width="150" label="促销名称"></el-table-column>
          <el-table-column label="规则" min-width="150">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.condition_value" :key="index">
                    <span v-if="scope.row.condition_type == 'quantity'">
                    消费满{{item.full}}件,赠送(<el-button type="text" @click="viewGiftItemList(scope.row)">优惠券</el-button>)
                    </span>
                <span v-if="scope.row.condition_type == 'totalfee'">
                    消费满{{item.full}}元,赠送(<el-button type="text" @click="viewGiftItemList(scope.row)">优惠券</el-button>)
                    </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="used_platform" min-width="100" label="适用平台">
            <template slot-scope="scope">
              <span v-if="scope.row.used_platform == 0">全场可用</span>
              <span v-if="scope.row.used_platform == 1">只用于pc端</span>
              <span v-if="scope.row.used_platform == 2">小程序端</span>
              <span v-if="scope.row.used_platform == 3">h5端</span>
            </template>
          </el-table-column>
          <el-table-column prop="total_fee" min-width="150" label="有效期">
            <template slot-scope="scope">
              <div> {{scope.row.start_date}}</div><div>~</div><div>{{scope.row.end_date}}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="70" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 'ongoing'">进行中</span>
              <span v-if="scope.row.status == 'waiting'">未开始</span>
              <span v-if="scope.row.status == 'end'">已结束</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <div class="operating-icons">
                <el-button v-if="scope.row.use_bound != 0" type="text" @click="viewItemList(scope.row.marketing_id)">查看商品</el-button>
                <el-button v-else type="text" @click="viewItemList('all', scope.row.item_type)">全部商品</el-button>
                <el-button type="text" v-if="scope.row.status !== 'end'" @click="updateStatusCommunityAction(scope.row)">终止</el-button>
                <el-button  v-if="scope.row.status == 'end' || scope.row.status == 'ongoing'" type="text" @click="viewDetail(scope.row)">查看详情</el-button>
                <i v-if="scope.row.status == 'waiting'" class="iconfont icon-edit1" @click="editActivityAction(scope.$index, scope.row)"></i>
                <i v-if="scope.row.status == 'waiting'"  class="iconfont icon-trash-alt" @click="deleteActivityAction(scope.row)"></i>
                <el-button v-if="scope.row.use_shop == 1" type="text" @click="showDistributors(scope.row)">查看店铺</el-button>
                <el-button v-if="scope.row.use_shop == 2" type="text" @click="showDealers(scope.row)">查看经销商</el-button>

                <ImportDistributor
                  v-if="scope.row.use_shop === 1"
                  buttonName="上传适用店铺"
                  buttonType="text"
                  fileName="上传适用店铺模版"
                  fileType="full_gift_upload_distributor"
                  :relId="scope.row.marketing_id"
                ></ImportDistributor>
                <ImportDistributor
                  v-if="scope.row.use_shop === 2"
                  buttonName="上传适用经销商"
                  buttonType="text"
                  fileName="上传适用经销商模版"
                  fileType="full_gift_upload_dealer"
                  :relId="scope.row.marketing_id"
                ></ImportDistributor>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-padded content-center">
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>

      <el-dialog title="活动商品列表" :visible.sync="activityItemDialog" :before-close="handleCancel" width="70%">
        <template>
          <el-pagination
            background
            layout="total"
            @current-change="handleGoodsCurrentChange"
            @size-change="handleGoodsSizeChange"
            :current-page.sync="activityItemParams.page"
            :page-sizes="[10,20,50]"
            :total="activityItemTotalCount"
            :page-size="activityItemParams.pageSize">
          </el-pagination>
          <el-table :data="activityItemListsData" :height="wheight-190" v-loading="ItemLoading">
            <el-table-column prop="item_id" label="id" width="60"></el-table-column>
            <el-table-column prop="pics[0]" label="图片" width="80">
              <template slot-scope="scope">
                <img :src="wximageurl + scope.row.pics[0]" width="50" height="50"/>
              </template>
            </el-table-column>
            <el-table-column prop="item_name" label="名称">
              <template slot-scope="scope">
                <el-col>{{ scope.row.item_name }}</el-col>
                <el-col v-if="scope.row.gift_num"> x {{ scope.row.gift_num }}</el-col>
              </template>
            </el-table-column>
            <el-table-column prop="item_spec_desc" label="规格">
              <template slot-scope="scope">
                <el-col>{{ scope.row.item_spec_desc }}</el-col>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="价格" width="100">
              <template slot-scope="scope">
                {{ cursymbol }}{{ scope.row.price / 100 }}
              </template>
            </el-table-column>
            <el-table-column prop="store" label="库存" width="70"></el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleGoodsCurrentChange"
            @size-change="handleGoodsSizeChange"
            :current-page.sync="activityItemParams.page"
            :page-sizes="[10,20,50]"
            :total="activityItemTotalCount"
            :page-size="activityItemParams.pageSize">
          </el-pagination>
        </template>
      </el-dialog>
      <el-dialog title=" 赠品" :visible.sync="activityGiftDialog" :before-close="handleCancel" width="70%">
        <template>
          <el-table :data="activityItemListsCouponData" :height="wheight-190" v-loading="ItemLoading">
            <el-table-column prop="item_id" label="卡券id" width="60"></el-table-column>
            <el-table-column prop="card_type" label="卡券类型">
              <template slot-scope="scope">
                <el-tag
                  :type="
                  scope.row.card_type === 'discount'
                    ? 'primary'
                    : scope.row.card_type === 'cash'
                    ? 'danger'
                    : 'warning'
                "
                  size="mini"
                >{{ scope.row.card_type | formatCardStr }}</el-tag
                >

                <div v-if="scope.row.card_type === 'gift'">
                  <el-tag
                    size="mini"
                    type="warning"
                    v-if="scope.row.use_scan == 'external_offline'"
                  >线下核销</el-tag
                  >
                  <el-tag size="mini" v-if="scope.row.use_scan == 'local_system'"
                  >本地系统核销</el-tag
                  >
                  <el-tag
                    size="mini"
                    type="danger"
                    v-if="scope.row.use_scan == 'third_system'"
                  >对接第三方核销</el-tag
                  >

                </div>
              </template>

            </el-table-column>
            <el-table-column prop="item_name" label="卡券标题">
            </el-table-column>
            <el-table-column  label="卡券有效期" width="100">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <template v-if="scope.row.takeEffect">
                  {{ scope.row.takeEffect }}
                  <div v-if="scope.row.end_date > 0">
                    统一过期时间：{{ scope.row.end_date | datetime }}
                    <span style="color: red">{{
                        Date.parse(new Date()) > scope.row.end_date * 1000
                          ? "已过期"
                          : ""
                      }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ scope.row.begin_date | datetime }}
                  <template v-if="scope.row.end_date">~</template>
                  {{ scope.row.end_date | datetime }}
                  <span style="color: red">{{
                      Date.parse(new Date()) > scope.row.end_date * 1000
                        ? "已过期"
                        : ""
                    }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="get_num" label="领取量" width="70"></el-table-column>
          </el-table>
        </template>
      </el-dialog>

      <el-dialog
        v-loading="distributorLoading"
        :before-close="beforeCloseDistributorDialog"
        title="店铺"
        :visible.sync="distributorDialogVisible"
        width="60%">
        <div>
          <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
          <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" @click="getBindStoreList" size="medium"></el-button>
        </div>

        <el-table
          height="600"
          v-if="distributorList.length > 0"
          :data="distributorList"
          style="line-height: normal"
        >
          <template>
            <el-table-column
              label="ID"
              prop="distributor_id"
              width="90"
            ></el-table-column>
            <el-table-column
              label="AppleID"
              prop="shop_code"
              width="120"
            ></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              show-overflow-tooltip
            ></el-table-column>
          </template>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteDistributor(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindStoreCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :total='totalStoreCount'
            :page-size="bindStorePagination.page_size">
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="distributorDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>

      <el-dialog
        v-loading="distributorLoading"
        :before-close="beforeCloseDistributorDialog"
        title="经销商"
        :visible.sync="dealerDialogVisible"
        width="60%">
        <!--        <div>
                  <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
                  <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
                  <el-button type="primary" icon="el-icon-search" @click="getBindStoreList" size="medium"></el-button>
                </div>-->

        <el-table
          height="600"
          v-if="dealerList.length > 0"
          :data="dealerList"
          style="line-height: normal"
        >
          <template>
            <el-table-column
              label="ID"
              prop="dealer_id"
              width="90"
            ></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column
              prop="dealer_hqid"
              label="经销商HQID"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="经销商HQID" prop="distributor_hqid"></el-table-column>
            <el-table-column label="经销商VAD" prop="distributor_vad"></el-table-column>
          </template>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteDistributor(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindDealerCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :total='totalStoreCount'
            :page-size="bindStorePagination.page_size">
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="distributorDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getMarketingActivityList,
  getMarketingActivityItemList,
  removeMarketingActivity,
  getMarketingDistributorList,
  deleteMarketingDistributor,
} from '../../../../api/promotions'
import shopSelect from '@/components/shopSelect'
import ImportDistributor from '@/components/importDistributor'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    shopSelect,
    ImportDistributor
  },
  data () {
    return {
      distributorLoading: false,
      distributorDialogVisible: false,
      bindStorePagination: {
        dealer_name: '',
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        page_size: 10,
      },
      distributorList: [],
      dealerList: [],
      totalStoreCount: 0,
      dealerDialogVisible: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 20,
        marketing_type: 'full_gift',
        item_type: '',
        status: '',
        start_time: '',
        end_time: '',
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    deleteDistributor(row){
      const params = {
        marketing_id: this.marketing_id,
      }
      if (row.hasOwnProperty('distributor_id')) { // 门店
        params.id = row.distributor_id
      } else { // 经销商
        params.id = row.dealer_id
      }
      deleteMarketingDistributor(params) .catch (error => {
        this.distributorLoading = false
      }).then(res => {
        if (row.hasOwnProperty('distributor_id')) { // 门店
          this.getBindStoreList()
        } else { // 经销商
          this.getBindDealerList()
        }
      })
    },
    beforeCloseDistributorDialog(){
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorList = []
      this.dealerList = []
      this.distributorDialogVisible = false
      this.dealerDialogVisible = false
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.getBindStoreList()
    },
    bindDealerCurrentChange(val) {
      this.bindStorePagination.page = val
      this.getBindDealerList()
    },
    async getBindStoreList () {
      this.distributorLoading = true
      const { data: bindList } = await getMarketingDistributorList({
        marketing_id: this.marketing_id,
        ...this.bindStorePagination
      }) .catch (error => {
        this.distributorLoading = false
      })
      this.distributorLoading = false
      this.totalStoreCount = bindList.data.shop_ids.length
      this.distributorList = bindList.data.storeLists
    },
    async getBindDealerList () {
      this.distributorLoading = true
      const { data: bindList } = await getMarketingDistributorList({
        marketing_id: this.marketing_id,
        ...this.bindStorePagination
      }) .catch (error => {
        this.distributorLoading = false
      })
      this.distributorLoading = false
      this.totalStoreCount = bindList.data.shop_ids.length
      this.dealerList = bindList.data.dealersLists
    },
    showDistributors(row) {
      this.marketing_id = row.marketing_id
      this.distributorDialogVisible = true
      this.getBindStoreList()
    },
    showDealers(row) {
      this.marketing_id = row.marketing_id
      this.dealerDialogVisible = true
      this.getBindDealerList()
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getFullGiftLists()
    },
    storeSearch (val) {
      val && val.shop_id
      this.params.store_id = val.shop_id
      this.params.page = 1
      this.getFullGiftLists()
    },
    itemTypeChange () {
      this.params.page = 1
      this.getFullGiftLists()
    },
    addActivityData () {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editActivityAction (index, row) {
      this.$router.push({path: this.matchHidePage('editor/') + row.marketing_id})
    },
    deleteActivityAction (row) {
      var msg = '你确定要删除该活动吗?'
      this.$confirm(msg, '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            removeMarketingActivity({marketing_id: row.marketing_id}).then(res => {
              this.getFullGiftLists()
              this.$message({
                message: '删除活动成功',
                type: 'success',
                duration: 5 * 1000
              })
            })
          }
          done()
        }
      })
    },
    viewDetail(row) {
      this.$router.push({path: this.matchHidePage('editor/') + row.marketing_id, query: {isnodata: true}})
    },
    viewItemList (id, itemType) {
      if (id == 'all') {
        if (itemType == 'normal') {
          this.$router.push({path: this.matchInternalRoute('goodsphysical')})
        } else {
          this.$router.push({path: this.matchInternalRoute('servicegoods')})
        }
      } else {
        this.ItemLoading = true
        this.activityItemDialog = true
        this.activityItemParams.marketing_id = id
        getMarketingActivityItemList(this.activityItemParams).then(res => {
          if (res != undefined && res.data.data && res.data.data.total_count >0) {
            this.activityItemListsData = res.data.data.list
            this.activityItemTotalCount = res.data.data.total_count
          }
          this.ItemLoading = false
        })
      }
    },
    handleGoodsCurrentChange(page_num){
      this.ItemLoading = true
      this.activityItemDialog = true
      this.activityItemParams.page = page_num
      getMarketingActivityItemList(this.activityItemParams).then(res => {
        if (res != undefined && res.data.data && res.data.data.total_count > 0) {
          this.activityItemListsData = res.data.data.list
          this.activityItemTotalCount = res.data.data.total_count
        }
        this.ItemLoading = false
      })
    },
    handleGoodsSizeChange (pageSize) {
      this.ItemLoading = true
      this.activityItemDialog = true
      this.activityItemParams.page = 1
      this.activityItemParams.pageSize = pageSize
      getMarketingActivityItemList(this.activityItemParams).then(res => {
        if (res != undefined && res.data.data && res.data.data.total_count > 0) {
          this.activityItemListsData = res.data.data.list
          this.activityItemTotalCount = res.data.data.total_count
        }
        this.ItemLoading = false
      })
    },
    handleCancel () {
      this.activityItemDialog = false
      this.activityGiftDialog = false
    },
    dateChange (val) {
      console.log(val);
      if (val) {
        this.params.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.params.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.params.start_time = ''
        this.params.end_time = ''
      }
      this.params.page = 1
      this.getFullGiftLists()
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getFullGiftLists()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getFullGiftLists()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getFullGiftLists() {
      this.loading = true
      var filter = this.params
      getMarketingActivityList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      })
    },
    viewGiftItemList(row) {
      this.activityGiftDialog = true
      this.activityItemListsCouponData = row.giftscoupon
    },

    updateStatusCommunityAction (row) {
      var msg = '此操作将永久终止该活动, 是否继续?'
      this.$confirm(msg, '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            removeMarketingActivity({marketing_id: row.marketing_id, isEnd: true}).then(response => {
              this.getFullGiftLists()
              this.$message({
                message: '修改活动状态成功',
                type: 'success',
                duration: 5 * 1000
              })
            })
          }
          done()
        }
      })
    },
  },
  mounted () {
    this.getFullGiftLists()
  }
}
</script>
