<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row>
        <el-input
          class="input-b"
          placeholder="素材名称"
          v-model="params.material_name"
          @change="getOfflineActivityList">
        </el-input>
        <el-button v-if="login_type !== 'admin'" type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">导出素材</el-button>
        <span style="padding: 0 20px;">同步创建视频内容 <el-switch v-model="auto_sync" @change="changeSyncStatus"></el-switch></span>
        <span style="padding: 0 20px;">同步创建后自动上架 <el-switch v-model="auto_up" @change = "changeSyncUp"></el-switch></span>
        <span>同步创建后自动推送 <el-switch v-model="auto_push" @change = "changeSyncPush"></el-switch></span>
      </el-row>

      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="上架" name="indue"></el-tab-pane>
        <el-tab-pane label="下架" name="outdate"></el-tab-pane>


        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading"
                  element-loading-text="数据加载中">
          <el-table-column prop="id" width="80" label="id"></el-table-column>
          <el-table-column prop="material_code" label="素材编码"></el-table-column>

          <el-table-column label="视频封面图" width="150">
            <template slot-scope="scope">
              <el-image v-if="scope.row.cover_img"
                style="width: 70px; height: 70px"
                :src="scope.row.cover_img"
                fit="cover">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="material_name" label="素材名称"></el-table-column>
          <el-table-column prop="desc" label="描述"></el-table-column>
            <el-table-column label="素材内容" width="200">
              <template slot-scope="scope">
                <video class="video-html" :src="scope.row.material_url" controls="controls"  controlslist="nodownload">
                  您的浏览器不支持 video 标签。
                </video>
              </template>
            </el-table-column>
          <el-table-column prop="material_version" width="120" label="素材版本"></el-table-column>
          <el-table-column prop="status_name" width="120" label="状态"></el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>

      </el-tabs>

    </div>


  <router-view></router-view>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  syncLingzhiOfflineActivityDistributors,
  getPromoShopList
} from '@/api/promotions'

import {getAfterSaleMaterialList,updateSyncStatus,getSyncStatus} from '@/api/aftersale_video'

import ImportDialog from '@/components/importDialog'
import {deleteShop, updateList} from "../../../../api/promotions";

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data() {
    return {
      auto_push: false,
      auto_sync: false,
      auto_up: false,
      exportloading: false,
      ShopLoading: false,
      dialogTitle: '门店',
      dialogLoading: false,
      is_activity_end: false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        page_size: 5,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 10,
        material_name: '',
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    changeSyncStatus(){
      if(this.auto_sync == false){
        this.auto_up = false
        this.auto_push = false
        updateSyncStatus({"type":"AUTO_UP","value":this.auto_up}).then(response => {
          console.log(response)
        }).catch(error => {
        })
      }

      updateSyncStatus({"type":"SYNC","value":this.auto_sync}).then(response => {

        console.log(response)
      }).catch(error => {
      })
      console.log('auto_sync',this.auto_sync);

      updateSyncStatus({"type":"KF_SYNC","value":this.auto_push}).then(response => {
        console.log(response)
      }).catch(error => {
      })
      console.log('auto_sync',this.auto_up);
    },

    changeSyncUp(){
      updateSyncStatus({"type":"AUTO_UP","value":this.auto_up}).then(response => {
        console.log(response)
      }).catch(error => {
      })
      console.log('auto_sync',this.auto_up);
    },
    changeSyncPush(){
      updateSyncStatus({"type":"KF_SYNC","value":this.auto_push}).then(response => {
        console.log(response)
      }).catch(error => {
      })
      console.log('auto_sync',this.auto_up);
    },
    copyClipboard(data, txt = "") {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$notify({
        title: "提示",
        message: `素材URL复制到剪切板成功`,
        type: "success",
        duration: 1000
      });
      oInput.remove();
    },
    handleCopy(data) {
      this.copyClipboard(data,'素材URL')
    },
    deleteShopData(row) {
      this.ShopLoading = true;
      deleteShop(row).then(response => {
        this.ShopLoading = false;
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 2 * 1000,
          onClose: () => {

            this.setCouponStoreList()
          }
        })
      })
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getOfflineActivityList()
    },
    addActivityData() {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editActivityAction(index, row) {
      this.$router.push({path: this.matchHidePage('editor'), query: {activity_id: row.id}})
    },
    seeActivityAction(index, row) {
      this.$router.push({path: this.matchHidePage('detail'), query: {activity_id: row.id}})
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.getOfflineActivityList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    handleIsShowChange(row) {
      updateList(row).then(response => {
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 2 * 1000,
          onClose: () => {
            this.getOfflineActivityList()
          }
        })
      })
    },
    getOfflineActivityList() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getAfterSaleMaterialList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },

    changeDistributorJoinStatus(row) {
      this.distributorStatusLoading = true
      if (!this.activity_id) {
        this.$message.error('编辑失败')
        return
      }
      let changeStatus = 1
      if (row.status === '1') {
        changeStatus = 2
      }
      let params = {
        status: changeStatus,
        distributor_id: row.distributor_id,
        activity_id: this.activity_id,
      }
      changeDistributorJoinStatus(params).then(response => {
        if (response.data.data.success == true) {
          this.distributorStatusLoading = false
          this.$message.success('修改成功')
          this.setBindStoreList()
        } else {
          this.$message.error('修改失败')
          this.distributorStatusLoading = false
        }
      }).catch(error => {
        // this.$message.error('修改失败')
        this.distributorStatusLoading = false
      })
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setBindStoreList()
    },
    async setBindStoreList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getPromoShopList({
        activity_id: this.activity_id,
        ...this.bindStorePagination
      }).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
    },

    async setCouponStoreList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getPromoShopList({
        "activity_id": this.activity_id,
        "page": this.bindStorePagination
      }).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
      this.dialogLoading = false
    },
    beforeCloseDistributorDialog() {
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
    },
    exportData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      let filter = {
        ...this.params,
        status: this.activeName,
        is_export: 1
      }
      getAfterSaleMaterialList(filter).then(response => {
        loading.close()
        if (response.data.data.status) {
          this.$message.success('已加入执行队列,请在设置-导出列表-售后视频素材查看')
        }
      }).catch(error => {
        loading.close()
      })
    },
    syncLingzhi() {
      let params = {
        activity_id: this.activity_id,
      }
      syncLingzhiOfflineActivityDistributors(params).then(response => {
        if (response.data.data.success) {
          this.$message.success('操作成功')
          this.distributorDialogVisible = false
        }
      }).catch(error => {
      })
    },
  },
  mounted() {
    //增加获取同步接口
    getSyncStatus().then(response => {
      if(response.data.data.sync_status == 1){
        this.auto_sync = true
      }
      if(response.data.data.up_status == 1){
        this.auto_up = true
      }
      if(response.data.data.kf_status == 1){
        this.auto_push = true
      }
    }).catch(error => {
    })
    this.getOfflineActivityList()
  },
  watch: {
    '$route'() {
      this.getOfflineActivityList()
    }
  },
}
</script>

<style scoped lang="scss">
.video_pick_panel {
  .video_pick {
    margin-right: -1%;
    margin-top: 10px;

    .video_item {
      margin-bottom: 20px;
      margin-right: 1%;
      border: 1px solid #e7e7eb;
      width: 19%;
    }

    .video_item_bd {
      position: relative;
    }



    .check_content {
      width: 100%;
      padding: 0 5px;
    }

    .video_name {
      height: 34px;
      line-height: 34px;
      overflow: hidden;
      display: inline-block;
      width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.video-html {
  width: 160px !important;
  height: 90px !important;
}
.video_pick {
  margin-right: -45px;

  .video_item {
    float: left;
    text-align: center;

    &:hover {
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    }
  }
}

.msg_card {
  background-color: #f4f5f9;
  border-top: 1px solid #e7e7eb;
  width: 100%;

  .opr_item {
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    color: #999;
    font-size: 16px;

    &:hover {
      color: #333;
    }
  }
}
</style>
