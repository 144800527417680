<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <el-row v-if="login_type != 'admin' " :gutter="20">
        <el-col :span="4">
          <el-button type="primary" icon="plus" @click="openDialog()"
            >添加页面</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="list" :height="wheight - 140" v-loading="loading">
        <el-table-column prop="id" label="页面id"></el-table-column>
        <el-table-column prop="page_name" label="页面名称"></el-table-column>
        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.is_open == '0'">禁用</el-tag>
            <el-tag type="warning" v-else>启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <a href="javascript:void(0)" @click="delPage(scope.row.id)">删除</a>
            <a href="javascript:void(0)" @click="openDialog(scope.row)">编辑</a>
            <el-button
              type="primary"
              plain
              round
              size="mini"
              @click="temDialog(scope.row.id)"
              >页面装修</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="total_count > params.pageSize"
        class="content-padded content-center"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          :total="total_count"
          :page-size="params.pageSize"
        >
        </el-pagination>
      </div>
      <el-dialog
        :title="dialogTitle"
        :visible.sync="page_dialog"
        :close-on-click-modal="false"
        :before-close="handleCancel"
      >
        <el-form v-model="pageForm" label-width="200px">
          <el-form-item label="页面名称">
            <el-input
              v-model="pageForm.page_name"
              placeholder="页面名称"
              style="width: 55%"
            ></el-input>
          </el-form-item>
          <el-form-item label="页面描述">
            <el-input
              v-model="pageForm.page_description"
              placeholder="页面描述"
              style="width: 55%"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-switch v-model="pageForm.is_open"> </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer content-center">
          <el-button type="primary" @click="savePage">确认保存</el-button>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="template_dialog"
        width="80%"
        title="编辑页面"
        fullscreen
        lock-scroll
      >
        <shopDecoration
          :id="pageForm.id"
          @saved="closeDialog"
          usage="page"
          :template_name="template_name"
        />
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getCustomPageList, createCustomPage, delCustomPage, editCustomPage } from '@/api/wxa'
import shopDecoration from './custompage/default'

export default {
  components: { shopDecoration },
  data () {
    return {
      template_name: 'pc',
      template_dialog: false,
      page_dialog: false,
      total_count: 0,
      dialogTitle: '新增页面',
      loading: false,
      params: {
        page: 1,
        pageSize: 20
      },
      pageForm: {
        id: '',
        page_name: '',
        page_description: '',
        is_open: true
      },
      list: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
      , 'login_type'
      // 'template_name'
    ])
  },
  methods: {
    temDialog (id, type) {
      this.pageForm.id = id
      this.template_dialog = true
    },
    closeDialog () {
      this.template_dialog = false
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.fetchPageList()
    },
    delPage (id) {
      this.$confirm('确认删除当前页面吗？').then(_ => {
        delCustomPage(id).then(res => {
          this.$message({ type: 'success', message: '操作成功！' })
          this.fetchPageList()
        })
      })
    },
    openDialog (detail = null) {
      this.page_dialog = true
      if (detail) {
        this.pageForm = detail
        if (detail.is_open == 1) {
          this.pageForm.is_open = true
        }
        this.dialogTitle = '编辑页面'
      } else {
        this.dialogTitle = '新增页面'
        this.pageForm = { id: '', page_name: '', page_description: '', is_open: true }
      }
    },
    savePage () {
      let { page_name, page_description, is_open, id } = this.pageForm
      const params = { page_name, page_description, is_open, template_name: this.template_name }
      if (this.dialogTitle == '编辑页面') {
        editCustomPage(id, params).then(res => {
          this.page_dialog = false
          this.fetchPageList()
          this.$message({
            type: 'success',
            message: '保存页面成功'
          })
        })
      }
      if (this.dialogTitle == '新增页面') {
        createCustomPage(params).then(res => {
          this.page_dialog = false
          this.fetchPageList()
          this.$message({
            type: 'success',
            message: '保存页面成功'
          })
        })
      }
    },
    fetchPageList () {
      this.loading = true
      Object.assign(this.params, { template_name: this.template_name })
      getCustomPageList(this.params).then(response => {
        if (response.data.data.list) {
          this.list = response.data.data.list
          this.total_count = response.data.data.total_count
        }
        this.loading = false
      })
    },
    handleCancel () {
      this.page_dialog = false
    }
  },
  mounted () {
    this.fetchPageList()
  }
}
</script>
