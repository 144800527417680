<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row>
        <el-input
          class="input-b"
          placeholder="售后标题"
          v-model="params.title"
          @change="getOfflineActivityList">
        </el-input>
        <ImportDialog
          buttonName="导入视频" style="margin-left: 10px"
          fileName="视频导入模板"
          fileType="material_video_upload">
        </ImportDialog>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-circle-plus"
          @click="addActivityData"
        >创建售后视频</el-button>
      </el-row>

      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="可用" name="indue"></el-tab-pane>
        <el-tab-pane label="已失效" name="outdate"></el-tab-pane>


        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading"
                  element-loading-text="数据加载中">
          <el-table-column prop="id" width="60" label="id"></el-table-column>
          <el-table-column prop="title" label="售后标题"></el-table-column>

          <el-table-column prop="material_desc" label="描述"></el-table-column>
          <el-table-column label="关联素材" width="200">
            <template slot-scope="scope">
              <video class="video-html" :src="scope.row.original_material.material_url" controls="controls"  controlslist="nodownload">
                您的浏览器不支持 video 标签。
              </video>
            </template>
          </el-table-column>

          <el-table-column prop="status" label="上架状态" width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                active-value="1"
                inactive-value="0"
                @change="handleIsShowChange(scope.row)"></el-switch>
              <!-- {{scope.row.activity_subtitle  }}
              {{typeof(scope.row.activity_subtitle)  }} -->
            </template>
          </el-table-column>
          <el-table-column prop="last_updated_time" width="200" label="最后更新时间"></el-table-column>

          <el-table-column label="操作" width="180">

            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="handleCopy(scope.row.id)">复制小程序链接</el-button>
                &nbsp;&nbsp;<el-button type="text" @click="PreferentialEdit(scope.$index, scope.row)">编辑</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>

      </el-tabs>

    </div>


    <router-view></router-view>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  syncLingzhiOfflineActivityDistributors,
  getPromoShopList
} from '@/api/promotions'

import {getAfterSaleMaterialList} from '@/api/aftersale_video'

import ImportDialog from '@/components/importDialog'
import {deleteShop, updateList} from "../../../../api/promotions";
import {editRealAfterMaterialList, getRealAfterMaterialList} from "../../../../api/aftersale_video";

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data() {
    return {
      ShopLoading: false,
      dialogTitle: '门店',
      dialogLoading: false,
      is_activity_end: false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        page_size: 5,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 20,
        title: '',
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    PreferentialEdit (index, row) {
      console.log(row)
      this.$router.push({path: this.matchHidePage('editor?id=') + row.id})
    },
    copyClipboard(data, txt = "") {
      let id = data;
      let oInput = document.createElement("input");
      oInput.value = 'video/after-sales/index?id='+id;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$notify({
        title: "提示",
        message: `素材URL复制到剪切板成功`,
        type: "success",
        duration: 1000
      });
      oInput.remove();
    },
    handleCopy(data) {
      this.copyClipboard(data,'素材URL')
    },
    deleteShopData(row) {
      this.ShopLoading = true;
      deleteShop(row).then(response => {
        this.ShopLoading = false;
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 2 * 1000,
          onClose: () => {

            this.setCouponStoreList()
          }
        })
      })
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getOfflineActivityList()
    },
    addActivityData() {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editActivityAction(index, row) {
      this.$router.push({path: this.matchHidePage('editor'), query: {activity_id: row.id}})
    },
    seeActivityAction(index, row) {
      this.$router.push({path: this.matchHidePage('detail'), query: {activity_id: row.id}})
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.getOfflineActivityList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    handleIsShowChange(row) {
      editRealAfterMaterialList(row.id,{"status":row.status}).then(response => {
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 2 * 1000,
          onClose: () => {
            this.getOfflineActivityList()
          }
        })
      }).catch(error => {
        if(row.status == 1){
          row.status= 0
        }
        if(row.status == 0){
          row.status= 1
        }
      })
    },
    getOfflineActivityList() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName,
        limit: this.params.pageSize,
      }
      getRealAfterMaterialList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },

    changeDistributorJoinStatus(row) {
      this.distributorStatusLoading = true
      if (!this.activity_id) {
        this.$message.error('编辑失败')
        return
      }
      let changeStatus = 1
      if (row.status === '1') {
        changeStatus = 2
      }
      let params = {
        status: changeStatus,
        distributor_id: row.distributor_id,
        activity_id: this.activity_id,
      }
      changeDistributorJoinStatus(params).then(response => {
        if (response.data.data.success == true) {
          this.distributorStatusLoading = false
          this.$message.success('修改成功')
          this.setBindStoreList()
        } else {
          this.$message.error('修改失败')
          this.distributorStatusLoading = false
        }
      }).catch(error => {
        // this.$message.error('修改失败')
        this.distributorStatusLoading = false
      })
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setBindStoreList()
    },
    async setBindStoreList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getPromoShopList({
        activity_id: this.activity_id,
        ...this.bindStorePagination
      }).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
    },

    async setCouponStoreList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getPromoShopList({
        "activity_id": this.activity_id,
        "page": this.bindStorePagination
      }).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
      this.dialogLoading = false
    },
    beforeCloseDistributorDialog() {
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
    },
    exportData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = {
        activity_id: this.activity_id,
        ...this.bindStorePagination,
        is_export: 1
      }
      getBindOfflineActivityStoreList(params).then(response => {
        loading.close()
        if (response.data.data.success) {
          this.$message.success('导出成功')
        }
      }).catch(error => {
        loading.close()
      })
    },
    syncLingzhi() {
      let params = {
        activity_id: this.activity_id,
      }
      syncLingzhiOfflineActivityDistributors(params).then(response => {
        if (response.data.data.success) {
          this.$message.success('操作成功')
          this.distributorDialogVisible = false
        }
      }).catch(error => {
      })
    },
  },
  mounted() {
    this.getOfflineActivityList()
  },
  watch: {
    '$route'() {
      this.getOfflineActivityList()
    }
  },
}
</script>

<style scoped lang="scss">
.video_pick_panel {
  .video_pick {
    margin-right: -1%;
    margin-top: 10px;

    .video_item {
      margin-bottom: 20px;
      margin-right: 1%;
      border: 1px solid #e7e7eb;
      width: 19%;
    }

    .video_item_bd {
      position: relative;
    }

    .check_content {
      width: 100%;
      padding: 0 5px;
    }

    .video_name {
      height: 34px;
      line-height: 34px;
      overflow: hidden;
      display: inline-block;
      width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.video-html {
  width: 160px !important;
  height: 90px !important;
}
.video_pick {
  margin-right: -45px;

  .video_item {
    float: left;
    text-align: center;

    &:hover {
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    }
  }
}

.msg_card {
  background-color: #f4f5f9;
  border-top: 1px solid #e7e7eb;
  width: 100%;

  .opr_item {
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    color: #999;
    font-size: 16px;

    &:hover {
      color: #333;
    }
  }
}
</style>
