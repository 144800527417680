<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row>
        <el-input class="input-b" placeholder="活动名称" v-model="params.activity_title" @change="searchList">
        </el-input>
        <el-select class="input-m mt10" placeholder="使用平台" v-model="params.platform" clearable @change="searchList">
          <el-option label="全平台" :value="'all'"></el-option>
          <el-option label="微信小程序" :value="'wxapp'"></el-option>
          <el-option label="抖音小程序" :value="'byte_mini'"></el-option>
        </el-select>
        <el-button v-if="login_type === 'disti'" type="primary" icon="plus" @click="addActivityData">
          添加
        </el-button>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="未开始" name="not_start"></el-tab-pane>
        <el-tab-pane label="进行中" name="going"></el-tab-pane>
        <el-tab-pane label="已结束" name="stopped"></el-tab-pane>
        <el-table :data="list" style="width: 100%" :height="wheight - 270" v-loading="loading"
          element-loading-text="数据加载中">
          <el-table-column prop="activity_id" width="60" label="编号"></el-table-column>
          <el-table-column prop="activity_title" min-width="150" label="活动标题"></el-table-column>
          <el-table-column width="200" label="报名信息">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.must_name == 1" type="primary" size="mini">姓名</el-tag>
              <el-tag v-if="scope.row.must_mobile == 1" type="primary" size="mini">手机号</el-tag>
              <el-tag v-if="scope.row.open_reserve_code == 1" type="primary" size="mini">预约码</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="150" label="使用平台">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.platform === 'all'" type="primary" size="mini">全平台</el-tag>
              <el-tag v-if="scope.row.platform === 'wxapp'" type="primary" size="mini">微信小程序</el-tag>
              <el-tag v-if="scope.row.platform === 'byte_mini'" type="primary" size="mini">抖音小程序</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="activity_subtitle" min-width="150" label="活动副标题"></el-table-column>
          <el-table-column prop="total_fee" min-width="50" label="活动类型">
            <template slot-scope="scope">
              <div>
                <el-tag v-if="scope.row.join_type === 'appointment'" type="primary" size="mini">预约报名</el-tag>
                <el-tag v-else type="success" size="mini">现场报名</el-tag>
              </div>
              <div>
                <el-tag v-if="scope.row.sign_in_system === 'local'" size="mini" type="info">数字签到码</el-tag>
                <el-tag v-else-if="scope.row.sign_in_system === 'guestbook'" size="mini" type="info">GuestBook</el-tag>
                <el-tag v-else-if="scope.row.sign_in_system === 'wecom'" size="mini" type="info">Wecom</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="total_fee" min-width="50" label="活动状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_end == 1 && scope.row.is_open == '1'" type="warning" size="mini">已结束</el-tag>
              <el-tag v-else-if="scope.row.is_open == '1'" type="success" size="mini">已开启</el-tag>
              <el-tag v-else type="danger" size="mini">已关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="total_fee" min-width="100" label="报名时间">
            <template slot-scope="scope">
              <div>
                <template>
                  {{ scope.row.sign_up_start_time | datetime("yyyy-MM-dd hh:mm:ss") }} - <br>
                  {{ scope.row.sign_up_end_time | datetime("yyyy-MM-dd hh:mm:ss") }}
                  <div style="color: red" v-if="scope.row.is_end == 1">已结束</div>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <div>
                <el-button v-if="login_type === 'disti'" type="text"
                  @click="editActivityAction(scope.$index, scope.row)">
                  编辑
                </el-button>
                <el-button type="text" @click="seeActivityAction(scope.$index, scope.row)">
                  查看
                </el-button>
                <ImportDialog v-if="login_type === 'disti'" buttonName="上传店铺" buttonType="text" fileName="上传活动店铺模版"
                  fileType="offline_activity_distributors" :relId="scope.row.activity_id"></ImportDialog>
                <el-button type="text" @click="showDistributors(scope.row)">
                  查看店铺
                </el-button>
                <ImportDialog v-if="scope.row.open_reserve_code == 1" buttonName="导入预约码" buttonType="text"
                  fileName="导入预约码" fileType="offline_activity_reserve_code" :relId="scope.row.activity_id">
                </ImportDialog>
                <el-button v-if="scope.row.open_reserve_code == 1" type="text"
                  @click="closeReservationDialog(scope.row)">
                  查看预约码
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination layout="total, prev, pager, next" @current-change="handleCurrentChange"
            :current-page.sync="params.page" :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-dialog :before-close="beforeCloseDistributorDialog" title="店铺" :visible.sync="distributorDialogVisible"
      width="60%">
      <div>
        <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
        <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name"
          clearable></el-input>
        <el-select v-model="bindStorePagination.status" placeholder="参与状态" clearable>
          <el-option label="正常参与" value="1">
          </el-option>
          <el-option label="停止参与" value="2">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="setBindStoreList"></el-button>
        <el-button type="primary" @click="exportData">导出</el-button>
        <el-popover placement="top-start" width="200" trigger="hover" content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
        <el-button type="primary" @click="syncLingzhi">同步企微</el-button>
      </div>

      <el-table height="600" v-if="distributorList.length > 0" :data="distributorList" style="line-height: normal">
        <template>
          <el-table-column label="ID" prop="distributor_id" width="90"></el-table-column>
          <el-table-column label="AppleID" prop="shop_code" width="120"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
          <el-table-column label="状态" prop="status" width="90">
            <template slot-scope="scope">
              <div>
                <el-tag v-if="is_activity_end === true" type="info" size="mini">结束参与</el-tag>
                <el-tag v-if="is_activity_end === false && scope.row.status == 1" type="success"
                  size="mini">正在参与</el-tag>
                <el-tag v-if="is_activity_end === false && scope.row.status == 2" type="danger"
                  size="mini">停止参与</el-tag>
              </div>
              <div>
                <el-tag v-if="scope.row.push_lz_status == 1" type="success" size="mini">同步企微成功</el-tag>
                <el-tag v-if="scope.row.push_lz_status == 2" type="danger" size="mini">同步企微失败</el-tag>
              </div>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <!--            <i
              class="iconfont icon-trash-alt"
              @click="deleteDistritutorRow(scope.$index, form.items)"
            ></i>-->
            <span v-if="login_type === 'disti'">

              <el-button :loading="distributorStatusLoading" v-if="is_activity_end === true" type="info" size="mini">
                结束参与
              </el-button>
              <el-button :loading="distributorStatusLoading"
                v-if="is_activity_end === false && scope.row.status === '1'" type="danger" size="mini"
                @click="changeDistributorJoinStatus(scope.row)">
                停止参与
              </el-button>
              <el-button :loading="distributorStatusLoading"
                v-else-if="is_activity_end === false && scope.row.status === '2'" type="success" size="mini"
                @click="changeDistributorJoinStatus(scope.row)">
                继续参与
              </el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div class="content-padded content-center">
        <el-pagination layout="prev, pager, next" @current-change="bindStoreCurrentChange"
          :current-page.sync="bindStorePagination.page" :total='totalStoreCount'
          :page-size="bindStorePagination.page_size">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="distributorDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :before-close="closeReservationDialog" title="查看预约码" :visible.sync="reservationDialogVisible"
      width="60%">
      <div>
        <el-input placeholder="请输入预约码" class="input-b" v-model="reservationForm.reserve_code"
          clearable @change="bindReserveCodeChange(1)"></el-input>
        <el-button type="primary" @click="batchCleanReserve()">批量删除</el-button>
        <el-button type="primary" @click="cleanReserve()">清除未使用</el-button>
      </div>
      <el-table ref="multipleTable" :data="reservationList" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange" row-key="id">
        <el-table-column type="selection" :reserve-selection="true" width="55">
        </el-table-column>
        <el-table-column prop="code" label="预约码">
        </el-table-column>
        <el-table-column prop="name" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status == '0' ? '未使用' : '' }}
            {{ scope.row.status == '1' ? '已锁定' : '' }}
            {{ scope.row.status == '2' ? '已使用' : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button v-if="scope.row.status == '0'" type="text" @click="deleteCleanReserve(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" @current-change="bindReserveCodeChange"
        :current-page.sync="reservationForm.page" :total='reservationTotal'
        :page-size="reservationForm.page_size">
      </el-pagination>

    </el-dialog>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getOfflineActivityList,
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  syncLingzhiOfflineActivityDistributors,
  getReserveCodeList,
  getReserveCodeClean 
} from '@/api/promotions'
import ImportDialog from '@/components/importDialog'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data() {
    return {
      is_activity_end: false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        page_size: 20,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 20,
        activity_title: '',
        platform: ''
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
      reservationDialogVisible: false,
      reservationForm: {
        reserve_code: '',
        page: 1,
        page_size: 10,
      },
      reservationList: [],
      reservationTotal: 0,
      selectionList: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getOfflineActivityList()
    },
    addActivityData() {
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    editActivityAction(index, row) {
      this.$router.push({ path: this.matchHidePage('editor'), query: { activity_id: row.activity_id } })
    },
    seeActivityAction(index, row) {
      this.$router.push({ path: this.matchHidePage('detail'), query: { activity_id: row.activity_id } })
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.getOfflineActivityList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    searchList() {
      this.params.page = 1
      this.getOfflineActivityList()
    },
    getOfflineActivityList() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getOfflineActivityList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },

    changeDistributorJoinStatus(row) {
      this.distributorStatusLoading = true
      if (!this.activity_id) {
        this.$message.error('编辑失败')
        return
      }
      let changeStatus = 1
      if (row.status === '1') {
        changeStatus = 2
      }
      let params = {
        status: changeStatus,
        distributor_id: row.distributor_id,
        activity_id: this.activity_id,
      }
      changeDistributorJoinStatus(params).then(response => {
        if (response.data.data.success == true) {
          this.distributorStatusLoading = false
          this.$message.success('修改成功')
          this.setBindStoreList()
        } else {
          this.$message.error('修改失败')
          this.distributorStatusLoading = false
        }
      }).catch(error => {
        // this.$message.error('修改失败')
        this.distributorStatusLoading = false
      })
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setBindStoreList()
    },
    async setBindStoreList() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: bindList } = await getBindOfflineActivityStoreList({
        activity_id: this.activity_id,
        ...this.bindStorePagination
      }).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
    },
    showDistributors(row) {

      if (row.sign_up_end_time * 1000 <= new Date().getTime()) {
        this.is_activity_end = true
      } else {
        this.is_activity_end = false
      }
      console.log(this.is_activity_end);
      console.log(new Date().getTime());
      console.log(row.sign_up_end_time * 1000);
      this.activity_id = row.activity_id
      this.distributorDialogVisible = true
      this.setBindStoreList()
    },
    beforeCloseDistributorDialog() {
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
    },
    exportData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = {
        activity_id: this.activity_id,
        ...this.bindStorePagination,
        is_export: 1
      }
      getBindOfflineActivityStoreList(params).then(response => {
        loading.close()
        if (response.data.data.success) {
          this.$message.success('导出成功')
        }
      }).catch(error => {
        loading.close()
      })
    },
    syncLingzhi() {
      let params = {
        activity_id: this.activity_id,
      }
      syncLingzhiOfflineActivityDistributors(params).then(response => {
        if (response.data.data.success) {
          this.$message.success('操作成功')
          this.distributorDialogVisible = false
        }
      }).catch(error => {
      })
    },
    closeReservationDialog(row) {
      this.activity_id = row.activity_id
      this.reservationDialogVisible = !this.reservationDialogVisible
      if (this.reservationDialogVisible) {
        this.reserveCodeList()
      }
    },
    reserveCodeList(id) {
      let params = {
        ...this.reservationForm,
        activity_id: this.activity_id
      }
      getReserveCodeList(params).then(response => {
        const { list, total_count } = response.data.data
        this.reservationList = list
        this.reservationTotal = total_count
      })
    },
    bindReserveCodeChange(val) {
      this.reservationForm.page = val 
      this.reserveCodeList()
    },
    handleSelectionChange(arr) {
      console.log("🚀 ~ handleSelectionChange ~ arr:", arr)
      if( arr.length === 0) return
      this.selectionList = arr
    },
    batchCleanReserve() {
      if (this.selectionList.length === 0) { 
        return this.$message.error('请选择要删除的预约码')
      }
      let params = {
        activity_id: this.activity_id,
        id: this.selectionList.map(item => item.id).join(',')
      }
      this.reserveCodeClean(params)
    },
    deleteCleanReserve(row) {
      let params = {
        activity_id: this.activity_id,
        id: row.id
      }
      this.reserveCodeClean(params)
    },
    cleanReserve() {
      this.reserveCodeClean({activity_id: this.activity_id, id: 'all'})
    },
    reserveCodeClean(params) {
      getReserveCodeClean(params).then(response => {
        this.reservationForm.page = 1
        this.reserveCodeList()
      })
    }
  },
  mounted() {
    this.getOfflineActivityList()
  },
  watch: {
    '$route'() {
      this.getOfflineActivityList()
    }
  },
}
</script>
