<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <!--      <el-tab-pane label="注册项配置" name="first">-->
      <!--        <el-card class="box-card">-->
      <!--          <div slot="header" class="clearfix">-->
      <!--            <span>会员信息录入项配置</span>-->
      <!--            <el-switch style="float: right; padding: 3px 0" v-model="settingStatus" active-color="#13ce66" inactive-text="是否开启注册录入" inactive-color="#efefef"></el-switch> &nbsp;&nbsp;-->
      <!--          </div>-->
      <!--          <div class="text item">-->
      <!--            <el-form ref="form" :model="form" label-position="left" label-width="150px">-->
      <!--              <div class="section-body">-->
      <!--                <el-form-item v-for="(item, key) in form" :key="key" :label="item.name">-->
      <!--                  <el-switch v-model="item.is_open" active-color="#13ce66" inactive-text="是否开启" inactive-color="#efefef" @change="changeOpen(key, item)"></el-switch> &nbsp;&nbsp;-->
      <!--                  <el-switch v-model="item.is_required" active-color="#13ce66" inactive-text="是否必填" inactive-color="#efefef" @change="changeRequired(key, item)"></el-switch>-->
      <!--                </el-form-item>-->
      <!--              </div>-->
      <!--              <div class="section-footer with-border content-center">-->
      <!--                <el-button type="primary" @click="save">保存</el-button>-->
      <!--              </div>-->
      <!--            </el-form>-->
      <!--          </div>-->
      <!--        </el-card>-->
      <!--      </el-tab-pane>-->
      <el-tab-pane label="使用条款" name="second">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>使用条款</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                :rles="rules"
                label-width="80px"
              >
                <el-form-item label="使用条款" prop="content">
                  <quillEditor
                    :options="editorOption"
                    v-model="form_content.content"
                  ></quillEditor>
                </el-form-item>
                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContent"
                    >保存</el-button
                  >
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
      <el-tab-pane label="隐私政策" name="third">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>隐私政策</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                :rles="rules"
                label-width="80px"
              >
                <el-form-item label="隐私政策" prop="privacy">
                  <quillEditor v-model="form_content.privacy"></quillEditor>
                </el-form-item>
                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContent"
                    >保存</el-button
                  >
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
      <el-tab-pane label="关于我们" name="about">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>关于Apple授权专营店</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                :rles="rules"
                label-width="80px"
              >
                <el-form-item v-if="form_content.type !== 'selling'" label="页面选择">
                  <div class="goods-select" @click="handleGoodsChange">
                    <div class="link-content" v-if="form_content.about && form_content.about.id">
                      <template v-if="form_content.about.linkPage === 'goods'">商品：</template>
                      <template v-if="form_content.about.linkPage === 'category'">分类：</template>
                      <template v-if="form_content.about.linkPage === 'article'">文章：</template>
                      <template v-if="form_content.about.linkPage === 'planting'">软文：</template>
                      <template v-if="form_content.about.linkPage === 'link'">页面：</template>
                      <template v-if="form_content.about.linkPage === 'marketing'">营销：</template>
                      {{form_content.about.title}}
                    </div>
                    <div v-else class="content-center"><i class="iconfont icon-link" @click="handleGoodsChange"></i>设置路径</div>
                  </div>
                </el-form-item>
                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContent"
                  >保存</el-button>
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>

    <linkSetter
      :links="linksArr"
      :defaultType="linksArr[0]"
      :visible="linksVisible"
      @setLink="setLink"
      @closeDialog="closeDialog"
      :show_article="false"
      :show_planting="false"
      :show_page="false"
      :show_marketing="false"
      :show_store="false">
    </linkSetter>
  </div>
</template>
<script>
import { setMemberRegisterSetting, getMemberRegisterSetting, setMemberRegisterContentSetting } from '../../../api/member'
/** 富文本编辑器：https://github.com/surmon-china/vue-quill-editor */
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import linkSetter from '@/components/template_links' // 添加导航连接
let fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = ['10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '36px', '38px', '40px', '45px', '50px', false]
Quill.register(fontSizeStyle, true)
import './size-set.css'
export default {
  data () {
    return {
      linksArr: ['custom_page'],
      linksVisible: false,
      activeName: 'second',
      settingStatus: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'], // 引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: 'ordered' }, { list: 'bullet' }], // 列表
              [{ script: 'sub' }, { script: 'super' }], // 上下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: fontSizeStyle.whitelist }], // 字体大小  ["small", false, "large", "huge"]
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
              [{ color: ['#000', '#333', '#666', '#929292', '#007AFF'] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ align: [] }], // 对齐方式
              ['image'], ['clean'] // 上传图片、上传视频 //清除字体样式
            ]
          }
        }
      },
      form: {
        username: {
          name: '姓名',
          is_open: false,
          element_type: 'input',
          is_required: false
        },
        sex: {
          name: '性别',
          is_open: false,
          element_type: 'select',
          is_required: false
        },
        birthday: {
          name: '出生年份',
          is_open: false,
          element_type: 'select',
          is_required: false
        },
        address: {
          name: '家庭地址',
          is_open: false,
          element_type: 'input',
          is_required: false
        },
        email: {
          name: 'email',
          is_open: false,
          element_type: 'input',
          is_required: false
        },
        industry: {
          name: '行业',
          is_open: false,
          element_type: 'select',
          is_required: false,
          items: ['金融/银行/投资', '计算机/互联网', '媒体/出版/影视/文化', '政府/公共事业', '房地产/建材/工程', '咨询/法律', '加工制造', '教育培训', '医疗保健', '运输/物流/交通', '零售/贸易', '旅游/度假', '其他']
        },
        income: {
          name: '年收入',
          is_open: false,
          is_required: false,
          element_type: 'select',
          items: ['5万以下', '5万 ~ 15万', '15万 ~ 30万', '30万以上', '其他']
        },
        edu_background: {
          name: '学历',
          is_open: false,
          element_type: 'select',
          is_required: false,
          items: ['硕士及以上', '本科', '大专', '高中/中专及以下', '其他']
        },
        habbit: {
          name: '爱好',
          is_open: false,
          is_required: false,
          element_type: 'checkbox',
          items: [
            { name: '游戏', ischecked: false },
            { name: '阅读', ischecked: false },
            { name: '音乐', ischecked: false },
            { name: '运动', ischecked: false },
            { name: '动漫', ischecked: false },
            { name: '旅游', ischecked: false },
            { name: '家居', ischecked: false },
            { name: '曲艺', ischecked: false },
            { name: '宠物', ischecked: false },
            { name: '美食', ischecked: false },
            { name: '娱乐', ischecked: false },
            { name: '电影/电视', ischecked: false },
            { name: '健康养生', ischecked: false },
            { name: '数码', ischecked: false },
            { name: '其他', ischecked: false }
          ]
        }
      },
      form_content: {
        content: '注册协议',
        privacy: '隐私政策'
      },
      rules: {
        content: [
          { required: true, message: '请输入充值协议', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleGoodsChange() {
      this.linksVisible = true
    },
    setLink (data, type) {
      console.log(data, type, 177)
      let obj = Object.assign(data, {'linkPage': type})
      this.form_content.about = obj
    },
    closeDialog () {
      this.linksVisible = false
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    changeOpen (key, item) {
      if (item.is_open === false) {
        this.form[key].is_required = false
      }
      if (item.is_open === true) {
        this.settingStatus = true
      }
    },
    changeRequired (key, item) {
      if (item.is_required === true) {
        this.form[key].is_open = true
      }
    },
    save () {
      let params = {
        registerSettingStatus: this.settingStatus,
        setting: this.form
      }
      setMemberRegisterSetting(params).then(response => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      })
    },
    saveContent () {
      this.$refs['form_content'].validate((valid) => {
        if (valid) {
          setMemberRegisterContentSetting(this.form_content).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
        }
      })
    }
  },
  components: {
    quillEditor,
    linkSetter,
  },
  mounted () {
    getMemberRegisterSetting().then(response => {
      this.form_content.content = response.data.data.content_agreement
      this.form_content.privacy = response.data.data.privacy
      this.form_content.about = response.data.data.about
      delete response.data.data.content_agreement
      this.settingStatus = response.data.data.registerSettingStatus
      let setting = response.data.data.setting
      if (setting.username) {
        this.form = setting
        this.form.username.element_type = 'input'
        this.form.sex.element_type = 'select'
        this.form.birthday.element_type = 'select'
        this.form.industry.element_type = 'select'
        this.form.income.element_type = 'select'
        this.form.edu_background.element_type = 'select'
        this.form.habbit.element_type = 'checkbox'
        this.form.address.element_type = 'input'
        this.form.email.element_type = 'input'
      }
    })
  }
}
</script>
<style scoped type="text/css">
.setting-header {
  background: #ecf5ff;
}
</style>
