<template>
  <div>
    <el-form ref="form" :model="form" class="box-set" label-width="120px" v-loading="loading">
      <el-card header="设置规则" shadow="naver">
        <el-form-item label="优惠信息" prop="limit_name">
          <el-col :span="20">
            <el-input v-model="form.discount_msg" :maxlength=30 placeholder="优惠信息"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="优惠信息优先级" prop="limit_name">
          <el-col :span="20">
            <el-input v-model="form.sort" :maxlength=30 placeholder="优先级"></el-input>
          </el-col>
        </el-form-item>


        <el-form-item v-if="is_edit === true" label="关联门店">
          <el-radio-group disabled="true" v-model="form.shop_type">
            <el-radio label="1">全部门店</el-radio>
            <el-radio label="2">指定门店</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="is_edit === false" label="关联门店">
          <el-radio-group v-model="form.shop_type">
            <el-radio label="1">全部门店</el-radio>
            <el-radio label="2">指定门店</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="form.shop_type == 2 && is_edit === true" label="门店导入规则">
          <el-radio-group  disabled="true" v-model="form.shop_type_import">
            <el-radio label="1">经销商</el-radio>
            <el-radio label="2">rtm</el-radio>
            <el-radio label="3">分销商</el-radio>
            <el-radio label="4">门店</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="form.shop_type == 2 && is_edit === false" label="门店导入规则">
          <el-radio-group v-model="form.shop_type_import">
            <el-radio label="1">经销商</el-radio>
            <el-radio label="2">rtm</el-radio>
            <el-radio label="3">分销商</el-radio>
            <el-radio label="4">门店</el-radio>

          </el-radio-group>
        </el-form-item>

        <el-form-item label="展示有效期">
          <el-date-picker
            v-model="dateTime"
            type="datetimerange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            @change="signUpDateTimeChange"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
      </el-card>



      <el-card header="选择商品" shadow="naver">
        <el-form-item label="适用商品">
          <el-radio-group v-model="form.use_bound" @change="itemTypeChange">
            <el-radio label="goods">指定商品适用</el-radio>
            <!--          <el-radio label="category">指定分类适用</el-radio>-->
            <!--          <el-radio label="tag">指定商品标签适用</el-radio>-->
            <!--          <el-radio label="brand">指定品牌适用</el-radio>-->
          </el-radio-group>
        </el-form-item>

        <div v-if="!zdItemHidden" style="position: relative">
          <SkuSelector  @change="getItems" :data="relItems" :selectSku="isSelectSku"></SkuSelector>
          <!--        <div style="position: absolute; bottom: 0px; left: 112px">-->
          <!--          <el-upload style="display: inline-block; height: 0" action="" :on-change="uploadHandleChange" :auto-upload="false" :show-file-list="false">-->
          <!--            <el-button type="primary">批量上传</el-button>-->
          <!--          </el-upload>-->
          <!--          <el-button style="margin-left: 10px" type="primary" @click="uploadHandleTemplate()">下载模板</el-button>-->
          <!--        </div>-->
        </div>
      </el-card>
      <el-card header="设置商品" shadow="naver">
        <el-table v-if="form.items.length>0" :data="form.items" style="line-height: normal">
          <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
          <el-table-column label="名称" prop="item_name"></el-table-column>
          <el-table-column label="规格" >全规格</el-table-column>
          <el-table-column label="商品编码" prop="item_bn"></el-table-column>
        </el-table>
      </el-card>
    </el-form>
    <div class="content-center" style="margin:20px 0">
      <!--      v-if="hasSaveButton"-->
      <el-button type="primary"  @click="submitActivityAction()">保存</el-button>
      <el-button @click.native="handleCancel">返回</el-button>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import SkuSelector from '@/components/function/skuSelector'
import {getGradeList} from '../../../../api/membercard'
import {listVipGrade} from '../../../../api/cardticket'
import {
  createLimitPromotions, getLimitPromotionsInfo, updateLimitPromotions, seckillActivityGetItemsList,
  addShowPart, editShowPart, getShowPartList, getPromoDetail
} from '../../../../api/promotions'
import { getItemsList, getCategory, getTagList, getGoodsAttr } from '@/api/goods'
import {handleUploadFile, exportUploadTemplate} from '../../../../api/common'

export default {
  components: {
    SkuSelector,
    Treeselect
  },
  data() {
    return {
      is_edit:false,
      dateTime: '',
      loading: false,
      relItems: [],
      activity_date: '',
      validGrade: [],
      vipGrade: [],
      memberGrade: [],
      rule: {
        day: 0,
        limit: 1,
      },
      limit_num_type: 'sku',
      isSelectSku: false,
      form: {
        discount_msg: '',
        shop_type: '1',
        items: [],
        sort: 0,
        use_bound: 'goods',
        shop_type_import: '1',
        start_time:'',
        end_time:''

      },
      relMain: [],
      setMainStatus: false,
      mainVisible: false,
      hasSaveButton: true,
      zdItemHidden: false,
      categoryHidden: true,
      categoryList: [],
      tagHidden: true,
      tag: {
        list: [],
        form: {
          tag_ids: [],
        },
        currentTags: [],
        tags: []
      },
      brandHidden: true,
      brand: {
        list: [],
        form: {
          brand_ids: [],
        },
        currentBrands: [],
        brands: []
      },
      ItemsList: [],
      invalidItemsList: [],
      params: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        templates_id: '',
        keywords: '',
        category: 0,
        is_warning: false,
        tag_id: '',
        is_gift: 'all',
        type: 0,
        is_sku: 'true',
        item_id: '',
      },
    }
  },
  methods: {
    signUpDateTimeChange() {
      const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      if (dateTime) {
        this.form.start_time = dateTime[0] === undefined ? 0 : dateTime[0]
        this.form.end_time = dateTime[1] === undefined ? 0 : dateTime[1]
      } else {
        this.form.start_time = this.form.end_time = 0
      }
    },
    submitActivityAction() {
      const that = this
      const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      let startTime = ''
      let endTime = ''
      if (dateTime) {
        startTime = dateTime[0] === undefined ? 0 : dateTime[0]
        endTime = dateTime[1] === undefined ? 0 : dateTime[1]
      } else {
        startTime = endTime = 0
      }
      let formData = JSON.parse(JSON.stringify(this.form))
      if (this.$route.query.activity_id) {
        formData.start_time = startTime
        formData.end_time = endTime
        editShowPart(this.$route.query.activity_id, formData).then(response => {
          this.loading = false
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()
      } else {
        addShowPart(formData).then(response => {
          this.loading = false
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()

    }},
    getItems(data) {
      this.ItemsList = []
      let items = []
      data.forEach(item => {
        var itemData = {
          'item_id': item.item_id,
          'goods_id': item.goods_id,
          'default_item_id': item.default_item_id,
          'item_name': item.item_name,
          'item_bn': item.item_bn,
          'item_spec_desc': item.item_spec_desc,
          'limit_num_type': this.limit_num_type,
          'limit_num': item.limit_num ? item.limit_num : 0
        }
        if (item.is_default == '1') {
          itemData.item_spec_desc = '全规格'
          items.push(itemData)
        }
        console.log('david:', items)

        this.ItemsList.push(item);
      })
      this.form.items = items
    },
    getLimitPromotionsInfo(id) {
      this.loading = true
      console.log('david++++',id)
      getPromoDetail({"activity_id":id}).then(response => {
        let res = response.data.data
        let data = {
          discount_msg: res.discount_msg,
          shop_type: res.shop_type.toString(),
          shop_type_import: res.shop_type_import.toString(),
          items: res.items,
          sort:res.sort
        }
        this.relItems = res.items
        this.dateTime = [res.start_time*1000, res.end_time*1000]

        Object.assign(this.form, data)

        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    handleCancel() {
      this.$router.back(-1)
    },
    getListVipGrade() {
      listVipGrade().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          this.vipGrade = response.data.data
        }
      })
    },
    getGradeList() {
      getGradeList().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          var result = response.data.data
          if (result) {
            this.memberGrade = result
          }
        }
      })
    },
    itemTypeChange: function (val) {
      this.params.main_cat_id = ''
      this.params.tag_id = ''
      this.params.brand_id = ''
      this.zdItemHidden = true
      this.categoryHidden = true
      this.tagHidden = true
      this.brandHidden = true
      this.form.items = []
      this.invalidItemsList = []
      this.form.rel_item_ids = []
      this.form.itemTreeLists = []
      this.form.item_category = []
      this.form.item_category = []
      this.tag.currentTags = []
      if (val === "goods") {
        this.zdItemHidden = false
      } else if (val === "category") {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.categoryHidden = false
        this.form.item_category = []
      } else if (val === "tag") {
        this.tagHidden = false
        this.tag.currentTags = []
        this.showTags()
      } else if (val === "brand") {
        this.brandHidden = false
        this.brand.currentBrands = []
        this.showBrands()
      }
    },
    fetchMainCate: function () {
      getCategory({is_main_category: true}).then(response => {
        this.categoryList = response.data.data
      })
    },
    addItemTag: function () {
      this.tag.currentTags = []
      if (this.item_id.length) {
        this.showTags()
        this.tag.form.item_ids = this.item_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个商品!'
        })
      }
    },
    showTags: function () {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
    },
    tagRemove: function (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
      this.form.tag_ids = []
      let tagItems = []
      let tagInvalidItems = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
        let items = []

        this.ItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) items.push(i)
        })
        tagItems = items

        let invalidItems = []
        this.invalidItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) invalidItems.push(i)
        })
        tagInvalidItems = invalidItems
      })
      this.ItemsList = tagItems
      this.invalidItemsList = tagInvalidItems
      this.getItems(this.ItemsList)
    },
    tagAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
      this.params.tag_id = item.tag_id
      this.getGoodsList()
    },
    getAllTagLists: function () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
      })
    },
    // 获取品牌列表
    getBrandList: function (searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({attribute_name: item.attribute_name, attribute_id: item.attribute_id})
        }
        this.brand.list = list
      })
    },
    showBrands: function () {
      this.brand.brands = [...this.brand.list]
      this.brand.brands.forEach((item, index) => {
        let isInArr = this.brand.currentBrands.findIndex((n) => n.attribute_id == item.attribute_id)
        if (isInArr != -1) this.brand.brands.splice(index, 1)
      })
    },
    brandAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.brand.currentBrands.findIndex(n => n.attribute_id == item.attribute_id)
      if (isInArr == -1) {
        this.brand.currentBrands.push(item)
        this.brand.brands.splice(index, 1)
      }
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
      this.params.brand_id = item.attribute_id
      this.getGoodsList()
    },
    brandRemove: function (index) {
      let items = []
      this.ItemsList.forEach(item => {
        if (this.brand.currentBrands[index].attribute_id != item.brand_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (this.brand.currentBrands[index].attribute_id != i.brand_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)

      this.brand.brands.unshift(this.brand.currentBrands[index])
      this.brand.currentBrands.splice(index, 1)
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    getGoodsList: function () {
      let params = JSON.parse(JSON.stringify(this.params))
      if (this.$route.params.limit_id) {
        params.activity_id = this.$route.params.limit_id
      }
      params.seckill_type = this.form.seckill_type
      params.activity_release_time = params.activity_start_time = this.activity_date[0]
      params.activity_end_time = this.activity_date[1]
      if (params.is_gift == 'all') {
        this.$delete(params, 'is_gift')
      }
      seckillActivityGetItemsList(params).then(response => {
        let itemList = response.data.data.list.validItems.concat(this.ItemsList)
        let invalidItemList = response.data.data.list.invalidItems.concat(this.invalidItemsList)
        let newItemList = []
        let invalidItems = []
        let obj = {}
        let obj1 = {}
        itemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj[item.item_id]) {
            newItemList.push(item)
            obj[item.item_id] = true
          }
        })
        invalidItemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj1[item.item_id]) {
            invalidItems.push(item)
            obj1[item.item_id] = true
          }
        })

        this.ItemsList = newItemList
        this.invalidItemsList = invalidItems
        this.getItems(this.ItemsList)
      })
    },
    categorySelect: function (node, instanceId) {
      this.params.main_cat_id = node.category_id
      this.getGoodsList()
    },
    categoryDeselect: function (node, instanceId) {
      let items;
      items = [];
      this.ItemsList.forEach((item, index) => {
        if (node.category_id != item.item_main_cat_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (node.category_id != i.item_main_cat_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)
    },
    generateSku() {
      let noSkuItem;
      noSkuItem = [];
      let response = []
      let goodsList = JSON.parse(JSON.stringify(this.relItems))
      goodsList.forEach(item => {
        if (!item.nospec && item.spec_items.length === 0) {
          noSkuItem.push(item.default_item_id)
        }
      })
      if (noSkuItem.length > 0) {
        let param = this.params
        param.item_id = noSkuItem
        getItemsList(this.params).then(res => {
          goodsList.forEach(item => {
            if (!item.nospec) {
              res.data.data.list.forEach(sku => {
                if (item.item_id === sku.default_item_id) {
                  item.spec_items.push(sku)
                }
              })
            }
          })
          goodsList.forEach(item => {
            if (!item.nospec) {
              response = [...response, ...item.spec_items]
            } else {
              response = [...response, item]
            }
          })
          this.ItemsList = response
          this.getItems(response)
        })
      } else {
        goodsList.forEach(item => {
          if (!item.nospec) {
            response = [...response, ...item.spec_items]
          } else {
            response = [...response, item]
          }
        })
        this.ItemsList = response
        this.getItems(response)
      }
    },
    /**
     * 下载模板
     * */
    uploadHandleTemplate() {
      let params = {file_type: 'marketing_goods', file_name: '商品模板'}
      exportUploadTemplate(params).then(response => {
        let {data} = response.data
        if (data.file) {
          var a = document.createElement('a')
          a.href = data.file
          a.download = data.name
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    /**
     * 上传模板
     * */
    uploadHandleChange(file, fileList) {

      let params = {isUploadFile: true, file_type: 'marketing_goods', file: file.raw}
      handleUploadFile(params).then(response => {
        this.$message({
          type: 'success',
          message: '上传成功'
        })

        let {data} = response.data

        if (data.fail.length > 0) {
          let str = data.fail.map(item => {
            return item.item_bn
          })

          setTimeout(() => {
            this.$message({
              showClose: true,
              message: `以下商品编号不存在：${str}`,
              type: 'error',
              duration: 100000
            })
          }, 1500)
        }
        if (data.succ.length <= 0) return
        this.relItems = data.succ
        let list = []
        data.succ.forEach(item => {
          if (!item.nospec) {
            list.push(Object.assign(item, {spec_items: []}))
          } else {
            list.push(item)
          }
        })
      })
    },

    /**
     * 切换sku/spu
     */
    changeLimitNumType(){
      this.getItems(this.ItemsList)
      if (this.limit_num_type === 'sku' || this.limit_num_type === 'skus') {
        this.isSelectSku = true
      } else {
        this.isSelectSku = false
      }
    }
  },
  mounted() {
    this.getListVipGrade()
    this.getGradeList()
    if (this.$route.query.isshow) {
      this.hasSaveButton = false
    }
    console.log(this.$route.query.activity_id)
    // if (this.$route.params.limit_id) {
    //   this.limit_id = this.$route.params.limit_id
    //   this.getLimitPromotionsInfo(this.$route.params.limit_id)
    // }
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id
      this.is_edit = true
      this.getLimitPromotionsInfo(this.$route.query.activity_id)
    }
    // this.fetchMainCate()
    // this.getAllTagLists()
    // this.getBrandList('', true)
  }
}
</script>
