<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">

      <el-tab-pane label="使用条款" name="second">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>使用条款</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                :rles="rules"
                label-width="80px"
              >
                <!-- <el-form-item label="使用条款" prop="content">
                  <quillEditor
                    :options="editorOption"
                    v-model="form_content.content"
                  ></quillEditor>
                </el-form-item> -->


          <el-form-item label="版本" style="height: 80px">
            <el-input v-model="useterm_ver_no" placeholder="请输版本号 如ver.1.1" disabled></el-input>
            <!-- form. -->
          </el-form-item>

          <el-form-item label="内容" >
            <!-- uploader-setting -->
            <div class="" width="100" >
              <el-select v-model="useterm_pages_template_id" width="200px" placeholder="设置自定义" @focus="handleClicktemp()" @change="handleChangeuseterm()">
                <el-option
                  v-for="item in useterm_templateList"
                  :key="item.useterm_pages_template_id"
                  :label="item.useterm_template_title"
                  :value="item.useterm_pages_template_id">
                </el-option>
              </el-select>
            </div>
            <!-- uploader-setting -->
            <!--
            <div class=""  width="120px" style="padding-left: 10px" >
              <el-input placeholder="模版名称" v-model="useterm_template_title" disabled="disabled" maxlength=""></el-input>
            </div>
            -->
          </el-form-item>

                <!-- <el-form-item label="隐私政策" prop="privacy">
                  <quillEditor v-model="form_content.privacy"></quillEditor>
                </el-form-item> -->

                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContentUseterm"
                    >保存使用条款</el-button
                  >
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
      <el-tab-pane label="隐私政策" name="third">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>隐私政策</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                :rles="rules"
                label-width="80px"
              >

          <el-form-item label="版本" style="height: 80px">
            <el-input v-model="private_ver_no" placeholder="请输版本号 如ver.1.1" disabled></el-input>
            <!-- form. -->
          </el-form-item>

          <el-form-item label="内容" >
            <!-- uploader-setting -->
            <div class="" width="100" >
              <el-select v-model="private_pages_template_id" width="200px" placeholder="设置自定义" @focus="handleClicktemp()" @change="handleChangeprivate()">
                <el-option
                  v-for="item in private_templateList"
                  :key="item.private_pages_template_id"
                  :label="item.private_template_title"
                  :value="item.private_pages_template_id">
                </el-option>
              </el-select>
            </div>
            <!-- uploader-setting -->
            <!--
            <div class=""  width="120px" style="padding-left: 10px" >
              <el-input placeholder="模版名称" v-model="template_title" disabled="disabled" maxlength=""></el-input>
            </div>
            -->
          </el-form-item>

                <!-- <el-form-item label="隐私政策" prop="privacy">
                  <quillEditor v-model="form_content.privacy"></quillEditor>
                </el-form-item> -->

                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContentPrivate"
                    >保存隐私政策</el-button
                  >
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
      <el-tab-pane label="会员隐私条款" name="register_vip_private">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>会员隐私条款</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                label-width="80px"
              >
                <el-form-item label="版本" style="height: 80px">
                  <el-input v-model="vip_version" placeholder="请输版本号 如ver.1.1" disabled></el-input>
                </el-form-item>

                <el-form-item label="条款模板">
                  <div class="" width="100">
                    <el-select v-model="vip_template_id" width="200px">
                      <el-option
                        v-for="item in vip_template_list"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContentVip">保存会员隐私条款</el-button>
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>

      <el-tab-pane label="员工购优惠规则" name="ecrule">
        <div class="section-white content-padded">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>员工购优惠规则</span>
            </div>
            <div class="text item">
              <el-form
                ref="form_content"
                :model="form_content"
                :rles="rules"
                label-width="80px"
              >
                <el-form-item label="版本" style="height: 80px">
                  <el-input v-model="ec_ver_no" placeholder="请输版本号 如ver.1.1" disabled></el-input>
                  <!-- form. -->
                </el-form-item>

                <el-form-item label="内容" >
                  <!-- uploader-setting -->
                  <div class="" width="100" >
                    <el-select v-model="ec_pages_template_id" width="200px" placeholder="设置自定义" @focus="handleClicktemp()" @change="handleChangeuseterm()">
                      <el-option
                        v-for="item in ec_templateList"
                        :key="item.ec_pages_template_id"
                        :label="item.ec_template_title"
                        :value="item.ec_pages_template_id">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- uploader-setting -->
                  <!--
                  <div class=""  width="120px" style="padding-left: 10px" >
                    <el-input placeholder="模版名称" v-model="useterm_template_title" disabled="disabled" maxlength=""></el-input>
                  </div>
                  -->
                </el-form-item>

                <!-- <el-form-item label="隐私政策" prop="privacy">
                  <quillEditor v-model="form_content.privacy"></quillEditor>
                </el-form-item> -->

                <div class="section-footer with-border content-center">
                  <el-button type="primary" @click="saveContentEc"
                  >保存员工购优惠规则</el-button
                  >
                </div>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script>
import {

  setagreementinfo,
  getagreementinfo,

  setMemberRegisterDistiSetting,
  getMemberRegisterDistiSetting,
  setMemberRegisterContentDistiSetting
} from '../../../api/member'
/** 富文本编辑器：https://github.com/surmon-china/vue-quill-editor */
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
let fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = ['10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '36px', '38px', '40px', '45px', '50px', false]
Quill.register(fontSizeStyle, true)
import './size-set.css'

import {
  setPagesTemplate,
  getPagesTemplateSetInfo,
  getPagesTemplateList,

} from "@/api/template";

import {getCustomizeList} from '@/api/promotions'

import {
  getCustomPageAgreementList,
  createCustomPage,
  delCustomPage,
  editCustomPage
} from '@/api/wxa'

export default {
  data () {
    return {
      activeName: 'second',
      settingStatus: false,

      // {pages_template_id: "1",template_title:"tt1"},
      // {pages_template_id: "2",template_title:"tt2"}

      // 使用条款
      useterm_ver_no: "",
      useterm_templateList: [],
      useterm_paramscustmize: {
        page: 1,
        pageSize: 10,
        page_type: 'useterm',
        // is_valid: 'true',
        // template_name: 'disti'
      },
      useterm_pages_template_id: null,
      useterm_template_title: " ",

      // 隐私协议
      private_ver_no: "",
      private_templateList: [],
      private_paramscustmize: {
        page: 1,
        pageSize: 10,
        page_type: 'private',
        // is_valid: 'true',
        // template_name: 'disti'
      },
      private_pages_template_id: null,
      private_template_title: " ",


      //ec
      ec_ver_no: "",
      ec_templateList: [],
      ec_paramscustmize: {
        page: 1,
        pageSize: 10,
        page_type: 'ecprivate',
        // is_valid: 'true',
        // template_name: 'disti'
      },
      ec_pages_template_id: null,
      ec_template_title: " ",


      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'], // 引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: 'ordered' }, { list: 'bullet' }], // 列表
              [{ script: 'sub' }, { script: 'super' }], // 上下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: fontSizeStyle.whitelist }], // 字体大小  ["small", false, "large", "huge"]
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
              [{ color: ['#000', '#333', '#666', '#929292', '#007AFF'] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ align: [] }], // 对齐方式
              ['image'], ['clean'] // 上传图片、上传视频 //清除字体样式
            ]
          }
        }
      },
      form: {
        username: {
          name: '姓名',
          is_open: false,
          element_type: 'input',
          is_required: false
        },
        sex: {
          name: '性别',
          is_open: false,
          element_type: 'select',
          is_required: false
        },
        birthday: {
          name: '出生年份',
          is_open: false,
          element_type: 'select',
          is_required: false
        },
        address: {
          name: '家庭地址',
          is_open: false,
          element_type: 'input',
          is_required: false
        },
        email: {
          name: 'email',
          is_open: false,
          element_type: 'input',
          is_required: false
        },
        industry: {
          name: '行业',
          is_open: false,
          element_type: 'select',
          is_required: false,
          items: ['金融/银行/投资', '计算机/互联网', '媒体/出版/影视/文化', '政府/公共事业', '房地产/建材/工程', '咨询/法律', '加工制造', '教育培训', '医疗保健', '运输/物流/交通', '零售/贸易', '旅游/度假', '其他']
        },
        income: {
          name: '年收入',
          is_open: false,
          is_required: false,
          element_type: 'select',
          items: ['5万以下', '5万 ~ 15万', '15万 ~ 30万', '30万以上', '其他']
        },
        edu_background: {
          name: '学历',
          is_open: false,
          element_type: 'select',
          is_required: false,
          items: ['硕士及以上', '本科', '大专', '高中/中专及以下', '其他']
        },
        habbit: {
          name: '爱好',
          is_open: false,
          is_required: false,
          element_type: 'checkbox',
          items: [
            { name: '游戏', ischecked: false },
            { name: '阅读', ischecked: false },
            { name: '音乐', ischecked: false },
            { name: '运动', ischecked: false },
            { name: '动漫', ischecked: false },
            { name: '旅游', ischecked: false },
            { name: '家居', ischecked: false },
            { name: '曲艺', ischecked: false },
            { name: '宠物', ischecked: false },
            { name: '美食', ischecked: false },
            { name: '娱乐', ischecked: false },
            { name: '电影/电视', ischecked: false },
            { name: '健康养生', ischecked: false },
            { name: '数码', ischecked: false },
            { name: '其他', ischecked: false }
          ]
        }
      },
      form_content: {
        content: '注册协议',
        privacy: '隐私政策',
        ec: '员工购优惠规则'
      },
      rules: {
        content: [
          { required: true, message: '请输入充值协议', trigger: 'blur' }
        ]
      },
      vip_version: '',
      vip_template_id: '',
      vip_template_list: []
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    changeOpen (key, item) {
      if (item.is_open === false) {
        this.form[key].is_required = false
      }
      if (item.is_open === true) {
        this.settingStatus = true
      }
    },
    changeRequired (key, item) {
      if (item.is_required === true) {
        this.form[key].is_open = true
      }
    },
    save () {
      let params = {
        registerSettingStatus: this.settingStatus,
        setting: this.form
      }
      setMemberRegisterDistiSetting(params).then(response => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      })
    },
    saveContentPrivate() {
      console.log("saveContentPrivate-----start")
      console.log(this.private_pages_template_id,this.private_template_title, this.private_ver_no)

        let params = {
          "type" : "private",
          "status"  : 1,
          "name"    : this.private_template_title,
          "content" : this.private_pages_template_id,
          // "version" : this.private_ver_no,


        }
          setagreementinfo(params).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
      console.log("saveContentPrivate-----end")

    },
    saveContentEc() {
      console.log("saveContentEc-----start")
      console.log(this.ec_pages_template_id,this.ec_template_title, this.ec_ver_no)

        let params = {
          "type" : "ecprivate",
          "status"  : 1,
          "name"    : this.ec_template_title,
          "content" : this.ec_pages_template_id,
          // "version" : this.ec_ver_no,


        }
          setagreementinfo(params).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
      console.log("saveContentec-----end")

    },

    saveContentUseterm() {
      console.log("saveContentUseterm-----start")
      console.log(this.useterm_pages_template_id,this.useterm_template_title, this.useterm_ver_no)

        let params = {
          "type" : "useterm",
          "status"  : 1,
          "name"    : this.useterm_template_title,
          "content" : this.useterm_pages_template_id,
          // "version" : this.useterm_ver_no,

        }
          setagreementinfo(params).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
      console.log("saveContentUseterm-----end")
    },

    saveContentVip() {
      const title = this.vip_template_list.find(item => item.value === this.vip_template_id)?.label
      const params = {
        "type" : "register_vip_private",
        "status"  : 1,
        "name"    : title,
        "content" : this.vip_template_id,
      }
      setagreementinfo(params).then(response => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      })
    },


    saveContent () {
      this.$refs['form_content'].validate((valid) => {
        if (valid) {
          setMemberRegisterContentDistiSetting(this.form_content).then(response => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          })
        }
      })
    },

      handleChangeprivate () {

        console.log("handleChange.private_pages_template_id:",this.private_pages_template_id)

        let private_rec = this.private_templateList.find(item => item.private_pages_template_id === this.private_pages_template_id)
        console.log(private_rec)
        this.private_template_title = private_rec.private_template_title
        console.log("h-select:this:yinsizhengce_name:",this.private_template_title)

      },

      handleChangeuseterm () {

        console.log("handleChange.useterm_pages_template_id:",this.useterm_pages_template_id)

        let useterm_rec = this.useterm_templateList.find(item => item.useterm_pages_template_id === this.useterm_pages_template_id)
        console.log(useterm_rec)
        this.useterm_template_title = useterm_rec.useterm_template_title
        console.log("h-select:this:yinsizhengce_name-useterm:",this.useterm_template_title)

      },

      handleClicktemp () {
      },

    private_getTemplateList(status) {
      this.listLoading = true;
      let params = {
        // ...this.params,
        // page_type: 'default',//this.page_type,
        // abtest_open: 1,//this.page_type,
      };
      //useterm_paramscustmize
      getCustomPageAgreementList(this.private_paramscustmize).then(res => {
        // this.te·mplateList = res.data.data.list
        let list = [];
        res.data.data.list.map(item => {
          list.push({
            // ...item,
            private_template_title: item.page_name,
            private_pages_template_id: item.id,
          });
        });
        this.private_templateList = list;
        this.total_count = res.data.data.total_count;
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
      });
    },
    ec_getTemplateList(status) {
      this.listLoading = true;
      let params = {
        // ...this.params,
        // page_type: 'default',//this.page_type,
        // abtest_open: 1,//this.page_type,
      };
      //useterm_paramscustmize
      getCustomPageAgreementList(this.ec_paramscustmize).then(res => {
        // this.te·mplateList = res.data.data.list
        let list = [];
        res.data.data.list.map(item => {
          list.push({
            // ...item,
            ec_template_title: item.page_name,
            ec_pages_template_id: item.id,
          });
        });
        this.ec_templateList = list;
        this.total_count = res.data.data.total_count;
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
      });
    },

    useterm_getTemplateList(status) {
      this.listLoading = true;
      let params = {
        // ...this.params,
        // page_type: 'default',//this.page_type,
        // abtest_open: 1,//this.page_type,
      };
      //getCustomPageAgreementList

      getCustomPageAgreementList(this.useterm_paramscustmize).then(res => {
        let list = [];
        res.data.data.list.map(item => {
          list.push({
            // ...item,
            useterm_template_title: item.page_name,
            useterm_pages_template_id: item.id,
          });
        });
        this.useterm_templateList = list;
        this.total_count = res.data.data.total_count;
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
      });
    },

    getVipTempList() {
      this.listLoading = true;
      getCustomPageAgreementList( {
        page: 1,
        pageSize: 9999,
        page_type: 'register_vip_private',
      }).then(res => {
        this.vip_template_list = res.data.data.list.map(item => (
          {
            label: item.page_name,
            value: item.id,
          }
        ))
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
      });
    },

    getagreementinfofunc(){
      getagreementinfo().then(response => {
        console.log("getagreementinfofunc:",response.data)
        console.log("getagreementinfofunc:private:",response.data.data.private)
        console.log("getagreementinfofunc:useterm:",response.data.data.useterm)

        this.private_pages_template_id = response.data.data.private.content
        this.private_ver_no            = response.data.data.private.version

        this.useterm_pages_template_id = response.data.data.useterm.content
        this.useterm_ver_no            = response.data.data.useterm.version

        this.ec_pages_template_id = response.data.data.ecprivate.content
        this.ec_ver_no            = response.data.data.ecprivate.version

        this.vip_template_id = response.data.data.register_vip_private?.content
        this.vip_version = response.data.data.register_vip_private?.version
      })
    },


    getMemberRegisterDistiSettingfunc(){

      getMemberRegisterDistiSetting().then(response => {
        this.form_content.content = response.data.data.content_agreement
        this.form_content.privacy = response.data.data.privacy
        delete response.data.data.content_agreement
        this.settingStatus = response.data.data.registerSettingStatus
        let setting = response.data.data.setting
        if (setting.username) {
          this.form = setting
          this.form.username.element_type = 'input'
          this.form.sex.element_type = 'select'
          this.form.birthday.element_type = 'select'
          this.form.industry.element_type = 'select'
          this.form.income.element_type = 'select'
          this.form.edu_background.element_type = 'select'
          this.form.habbit.element_type = 'checkbox'
          this.form.address.element_type = 'input'
          this.form.email.element_type = 'input'
        }
      })
    },


  },
  components: {
    quillEditor
  },
  mounted () {
    this.private_getTemplateList()
    this.ec_getTemplateList()
    this.useterm_getTemplateList()
    this.getagreementinfofunc()
    this.getVipTempList()
    // this.getMemberRegisterDistiSettingfunc()

  }
}
</script>
<style scoped type="text/css">
.setting-header {
  background: #ecf5ff;
}
</style>
