<template>
  <div class="setting-view">

    <div class="appmsg_input_area">
      <div class="content-center content-bottom-padded">
          <el-radio-group v-model="form.pagetype" @change="handleTypeChange">
            <el-radio-button label="cash">AB实验</el-radio-button>
          </el-radio-group>

      </div>
      <div id="submitContent"></div>
      <el-form ref="form" :model="form" label-position="top" label-width="80px">
        <div class="content-padded">
          <el-form-item label="请选择类型">
            <el-select v-model="form.type" placeholder="请选择">
              <!-- <el-option label="AB实验" value="abindex">AB实验首页</el-option> -->
              <el-option label="AppleCare+" value="applecare">AppleCare+</el-option>
              <el-option label="Trade-in" value="tradein">Trade-in</el-option>
              <el-option label="Accy" value="accy">Accy</el-option>
              <el-option label="到手价实验" value="handprice">到手价实验</el-option>
              <el-option label="产品切换实验" value="productchange">产品切换实验</el-option>
              <el-option label="订单结算页加载" value="orderpayload">订单结算页加载</el-option>
              <el-option label="客服AB实验" value="kefuabtest">客服AB实验</el-option>
              <el-option label="默认选中配送方式" value="receipttype">默认选中配送方式</el-option>
              <el-option label="商品详情页以旧换新折抵金额" value="tradeInType">商品详情页以旧换新折抵金额</el-option>

              <!-- <el-option label="门店服务" value="service">门店服务</el-option> -->
              <!--
              <el-option label="门店活动" value="activity">门店活动</el-option>
              <el-option label="热卖" value= "selling">热卖</el-option>
              <el-option label="福利" value= "welfare">福利</el-option>
              -->
            </el-select>
          </el-form-item>
          <el-form-item label="实验名称" style="height: 80px">
            <el-select  v-model="form.activity_title" placeholder="请选择" style="width: 240px;" @change="handleChangeAbTest">
              <el-option v-for="(item,index) in abList" :key="`ab_item_${index}`" :label="item.title" :value="`${item.value}`"></el-option>
            </el-select>
            <!-- <el-input v-else v-model="form.activity_title" placeholder="请输入实验名称"></el-input> -->
          </el-form-item>
          <div class="" style="height: 100px">
            <el-form-item label="*选择时间" >
              <el-date-picker v-model="date_range" type="datetimerange" align="right" unlink-panels range-separator="至"
               start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp" @change="dateChange">
              <!-- format="yyyy-MM-dd HH:mm:ss" -->
              <!-- "yyyy-MM-dd HH:mm:ss" -->
              </el-date-picker>
            </el-form-item>
          </div>

          <!-- <el-form-item label="实验参数" style="height: 80px">
            <el-input v-model="form.activity_subtitle" placeholder="请输入实验参数"></el-input>
          </el-form-item> -->

          <!-- <el-form-item v-if="(form.type == 'abindex')" label="设置城市">
            <el-radio-group v-model="form.rel_city" size="medium" @change="changeRelCity">
              <el-radio-button label="false">不限城市</el-radio-button>
              <el-radio-button label="true">指定城市</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 15px">
              <el-button v-show="form.rel_city == 'true'" size="small" @click="handleSelectCity">选择城市</el-button>
            </div>
            <div v-if="form.rel_city === 'true' && citiesInfo.length > 0">
              已选城市：<el-tag v-for="(item, i) in citiesInfo" :key="`city-item__${i}`">{{item.label}}</el-tag>
            </div>
          </el-form-item> -->

          <!-- <el-form-item v-if="(form.type == 'abindex')" label="*设置实验组">
            <el-row>
              <div class="setting-item" v-for="(item, index) in addedItems" :key="index">
                <div class="" width="200px">
                  <el-input placeholder="实验组" disabled v-model="item.value" maxlength=""></el-input>
                </div>
                <div class="" width="250px" style="padding-left: 20px" >
                  <el-select v-model="item.pages_template_id" width="200px" placeholder="设置模板" @focus="handleClicktemp(index)" @change="handleChange(index)">
                    <el-option
                      v-for="item in templateList"
                      :key="item.pages_template_id"
                      :label="item.template_title"
                      :value="item.pages_template_id">
                    </el-option>
                  </el-select>
                </div>
                <div class=""  width="220px" style="padding-left: 10px" >
                  <el-input placeholder="模版名称" v-model="item.name" disabled="disabled" maxlength=""></el-input>
                </div>
              </div>
            </el-row>
          </el-form-item> -->

          <el-form-item label="*设置实验组">
            <el-row>
              <div class="setting-item" v-for="(item, index) in addedItems" :key="index">
                <!-- <div v-if="index > 0" class="setting-remove" @click="removeItem(index)"><i class="iconfont icon-trash-alt"></i></div> -->

                <!-- uploader-setting -->
                <div class="" width="200px">
                  <el-input placeholder="实验组" disabled v-model="item.value" maxlength=""></el-input>
                </div>
                <!-- uploader-setting -->
                <div class="" width="250px" style="padding-left: 20px" >
                  <el-select v-model="item.pages_template_id" width="200px" placeholder="设置模板">
                    <el-option
                      v-for="(item, index) in opts[form.type]"
                      :key="index"
                      :label="item"
                      :value="index">
                    </el-option>
                  </el-select>
                  <!-- <div>{{item.template_title}}</div> -->
                </div>
              </div>

              <!-- <div class="frm-tips">只能上传jpg/png文件，且不超过2M（建议尺寸：50px * 50px）</div> -->
              <!-- <el-button :disabled="addedItems.length >= 5" type="default" class="iconfont icon-plus-circle banner-button-uploader" @click="addItemHandle"> 新增实验组</el-button> -->
            </el-row>
          </el-form-item>

        </div>

      </el-form>

      <el-dialog :visible.sync="showSelectCitySideBar" title="选择城市" width="90%">
        <div v-loading="selectCityLoading" lock>
          <el-checkbox-group v-model="selected">
            <el-card>
              <div slot="header" class="clearfix">
                <span style="font-size: 14px;font-weight: bolder">默认城市</span>
              </div>
              <el-checkbox
                key="000000"
                label="000000">
                默认城市
              </el-checkbox>
            </el-card>
            <div v-for="(item, i) in cityList" :key="i">
              <el-card class="box-card" style="margin-bottom: 10px" :loading="selectCityLoading">
                <div slot="header" class="clearfix">
                  <span style="font-size: 14px;font-weight: bolder">{{item.title}}</span>
                </div>
                <div class="text item">
                  <div style="display: inline-block">
                    <el-checkbox
                      v-for="(value,index) in item.items"
                      :key="index"
                      :label="value.id">
                      {{value.label}}
                    </el-checkbox>
                  </div>
                </div>
              </el-card>
            </div>
          </el-checkbox-group>
          <div style="text-align: center; margin: 30px 0">
            <el-button type="primary" @click="saveCity">保存</el-button>
          </div>
        </div>
      </el-dialog>

          <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>
          <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
    </div>
    <div class="setting-footer">
      <el-button type="primary" @click="onSubmit">保存</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
      <el-button type="warning" @click.native="handleCancel">取消</el-button>
    </div>
    <GoodsSelect :items-visible="itemVisible" :get-status="setItemStatus" :rel-items-ids="relItemsIds" @chooseStore="chooseItemsAction" @closeStoreDialog="closeItemDialogAction"></GoodsSelect>
    <DistributorSelect :store-visible="DistributorVisible" :is-valid="isValid" :rel-data-ids="relDistributors" :get-status="DistributorStatus" @chooseStore="DistributorChooseAction" @closeStoreDialog="closeDialogAction"></DistributorSelect>
    <CustomizeSelect :store-visible="CustomizeVisible" :is-valid="isValid1" :rel-data-ids="customizeList" :get-status="CustomizeStatus" @chooseStore="CustomizeChooseAction" @closeStoreDialog="closeDialogAction1"></CustomizeSelect>
    <linkSetter
      :links="linksArr"
      :visible="linksVisible"
      @setLink="setLink"
      @closeDialog="closeDialog"
      :show_article="false"
      :show_planting="false"
      :show_page="false"
      :show_marketing="false"
      :show_store="false">
    </linkSetter>
  </div>
</template>

<script>
  import { getPreferentialInfo, createPreferential, updatePreferential, getCustomizeList} from '@/api/promotions'
  import { getDistributorList } from '@/api/marketing'
  import imgPicker from '@/components/imageselect'
  import GoodsSelect from '@/components/goodsSelect'
  import linkSetter from '@/components/template_links' // 添加导航连接
  import { getCityList, getCitiesInfo } from '@/api/company'
  import DistributorSelect from '@/components/function/distributorSelect'
  import { pickBy,formatTimeStampToStr } from "@/utils";
  import CustomizeSelect from '@/components/function/customizeSelect'
  import {
    setPagesTemplate,
    getPagesTemplateSetInfo,
    getPagesTemplateList,
  } from "@/api/template";


  export default {
    inject: ['refresh'],
    components: {
      DistributorSelect,
      CustomizeSelect,
      imgPicker,
      linkSetter,
      GoodsSelect
    },

    data() {
      return {
        citiesInfo: [],
        selectCityLoading: false,
        selected: [],
        showSelectCitySideBar: false,
        cityList: [],
        linksArr: [],
        cursymbol: '￥',
        linksVisible: false,
        // relItems: [],
        relItemsIds: [],
        abList:[],
        templateList: [],
        abExperimentList:[],
        setItemStatus: false,
        itemVisible: false,
        imgDialog: false,
        thumbDialog: false,
        isGetImage: false,
        isGetThumb: false,
        isValid: true,
        isValid1: true,
        DistributorStatus: false,
        DistributorVisible: false,
        CustomizeStatus: false,
        CustomizeVisible: false,
        relDistributors: [],
        customizeList: [],
        relItems :[],
        addedItems: [],
        created: '',
        date_range: '',
        pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start,end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start,end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start,end]);
          }
        }],
      },
        form: {
          id : '',
          distributor_ids: [],
          customize: '',
          activity_title: '',
          activity_id: '',
          activity_subtitle: '',
          activity_cover: '',
          page_url: {},
          start_time: '',
          end_time: '',
          items:[],
          pagetype:'cash',
          type: 'applecare',
          rel_city: 'false',
          city_list: [],
        },
        opts: {
          applecare: {
            'Checked': '默认选中',
            'Uncheck' : '默认不选中'
          },
          tradein: {
            'DefaultStyle': '默认样式',
            'TabDefaultChecked': 'tab默认选中',
          },
          accy: {
            'Show' : '展示',
            'Hide' : '不展示',
          },
          handprice: {
            'Yes' : '展示到手价',
            'No' : '不展示到手价',
            'ShowPrice' : '展示原价和预估到手价'
          },
          productchange: {
            'Show' : '产品切换显示',
            'Hide' : '产品切换不显示'
          },
          orderpayload: {
            'jump': '跳转',
            'embedded': '半屏拉起',
          },
          kefuabtest: {
            'jump': '跳转',
            'embedded': '半屏拉起',
            'newembedded': '新版半屏拉起',
          },
          receipttype: {
            'ziti': '自提',
            'logistics': '快递'
          },
          tradeInType: {
            'device': '机型折抵',
            'highest': '最高折抵'
          }
        },
      }
    },
    methods: {
      async getABList() {
      const { experiments } = await this.$api.abtest.getExperimentList({ name: '' })
      this.abList = pickBy(experiments, {title: 'name',value: 'id'})
      // const id = this.abList[0].value
      // await this.getAbDetails(id)
    
    },
    async getAbDetails(id) {
      const { experiment } = await this.$api.abtest.getExperimentDetail({ id })
      this.addedItems =  pickBy(experiment.experiment_groups, {
          text: 'group_variables[0].value',
          value: 'cname',
          pagePath: '',
          name: 'group_variables[0].name',
          iconPath: '',
          selectedIconPath: '',
          sort: 1
      })
    },
      handleCancel () {
        this.refresh();
        this.$router.go(-1)
      },
      changeRelCity() {
        this.addedItems.map(item=>{
          item.pages_template_id=''
          item.name=''
          return item
        })
        this.form.city_list = []
        this.citiesInfo = []
        this.getTemplateList()
      },
      saveCity() {
        this.form.city_list = JSON.parse(JSON.stringify(this.selected))
        this.showSelectCitySideBar = false
        this.getCitiesInfo()
        this.getTemplateList()
      },
      handleSelectCity() {
        this.showSelectCitySideBar = this.selectCityLoading = true
        let params = {
          first_charter: 1
        }
        getCityList(params).then(response => {
          this.cityList = response.data.data
          this.selectCityLoading = false
          this.selected = JSON.parse(JSON.stringify(this.form.city_list))
        }).catch(error => {
          this.selectCityLoading = false
        })
      },
      chooseItems () {
        this.itemVisible = true
        this.setItemStatus = true
      },
      chooseItemsAction (data) {
        this.itemVisible = false
        this.relItemsIds = data || []
        console.log(data, 228)
        if (data === null || data.length <= 0) return
        let arr = []
        data.forEach((items, index) => {
          let newData = ''
          let isInArr = this.form.items.findIndex((n) => n.item_id == item.itemId)
          if (isInArr == -1) {
            newData = {
              item_id: item.itemId,
              item_name: item.itemName,
              item_bn: item.item_bn,
              activity_store: item.store,
              activity_price: item.price/100,
              sort: item.sort,
              vip_price: 0,
              svip_price: 0,
              points: 0,
              limit_num: 0,
              initial_sales: 10,
              selling_text: item.selling_text || '',
              home_pic: item.home_pic,
              goods_id: item.goods_id,
              brief: item.brief
            }
          } else {
            newData = this.form.items[index]
          }
          arr.push(newData)
        })
        this.form.items = arr
        console.log(this.form.items, 277)
      },
      closeItemDialogAction () {
        this.itemVisible = false
      },
      handleGoodsChange() {
        this.linksVisible = true
      },
      setLink (data, type) {
        console.log(data, type, 177)
        let obj = Object.assign(data, {'linkPage': type})
        this.form.page_url = obj
      },
      closeDialog () {
        this.linksVisible = false
      },
      dateChange(val) {
        if (val && val.length > 0  ) {
          // this.form.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          // this.form.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
          this.form.start_time = this.dateStrToTimeStamp(val[0] )
          this.form.end_time = this.dateStrToTimeStamp(val[1] )
        }else{
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },

      handleClick () {
        if (this.form.type === "service") {
          this.isShow = true;
        } else if (this.form.type === "activity") {
          this.isShow = true;
        } else if (this.form.type === "selling") {
          this.isShow = false;
        }
      },
      handleChangeAbTest(e){
        const item = this.abList.find(item => item.value === e)
        // this.form.activity_title = item.title
        this.getAbDetails(e)
      },
      getItems(data) {
        let arr = []
        data.forEach((item, index) => {
          let newData = ''
         // let isInArr = this.addedItems.findIndex((n) => n.item_id == item.itemId)
          // if (isInArr == -1) {
            newData = {
              item_id: item.itemId,
              item_title: item.itemName,
              activity_store: item.store,
              activity_price: item.price / 100,
              item_spec_desc: item.item_spec_desc,
              sort: item.sort,
              limit_num: "",
              item_type: item.item_type,
              home_pic: item.home_pic,
              goods_id: item.goods_id,
              brief: item.brief
             }
          // } else {
          //   newData = this.addedItems[isInArr]
          // }
          let flag = false;
          if (newData) {
            arr.forEach((k,v) => {
              if(k.item_title == newData.item_title){
                  flag = true;
              };
            })
            if(!flag) {
              arr.push(newData)
            }
          }
        })
        this.form.items = arr
      },

    getTemplateList(status) {
      this.listLoading = true;
      let params = {
        ...this.params,
        city_list: this.form.city_list,
        page_type: 'default',//this.page_type,
        abtest_open: 1,//this.page_type,
      };
      if (this.form.rel_city === 'true') {
        params.city_list = this.form.city_list.length > 0 ? this.form.city_list : [-1]
        params.rel_city = 'true'
      } else {
        params.rel_city = 'false'
      }
      getPagesTemplateList(params).then(res => {
        // this.te·mplateList = res.data.data.list
        let list = [];
        res.data.data.list.map(item => {
          list.push({
            ...item,
            element_edit_status: item.element_edit_status || 2,
            status: item.status || 2,
            timer_status: item.timer_status || 2,
            showTime: false,
            timer_time: !item.timer_time
              ? ""
              : formatTimeStampToStr(item.timer_time),
            showTime: item.timer_status == 1
          });
        });
        this.templateList = list;
        this.total_count = res.data.data.total_count;
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
      });
    },
  
      addItemHandle () {
        if (!this.addedItems) {
          this.addedItems = []
        }
        let item = {
          pagePath: '',
          text: '',
          name: '',
          iconPath: '',
          selectedIconPath: '',
          sort: 1
        }
        if(this.addedItems.length > 4) {
          this.$message({
            message: '最多添加5个',
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          this.addedItems.push(item)
        }
      },

      removeItem (index) {
        this.addedItems.splice(index, 1)
      },

      handleChange (index) {
        let value=this.addedItems[index].pages_template_id

        console.log("handleChange.value:",value)
        console.log("handleChange.index:",index)

        console.log("h-select:value:",this.addedItems)
        let tempn = this.templateList.find(item => item.pages_template_id === value)
        console.log("h-select:tempn:template_title:",tempn.template_title)
        this.addedItems[index].name = tempn.template_title
      },

      handleClicktemp (index) {
        // console.log("h-select:index:",index)
        // console.log("h-select:value:",this.addedItems)
        // let id=this.addedItems[index].pages_template_id
        // let tempn = this.templateList.find(item => item.pages_template_id === id)
        // console.log("h-select:id:",id)
        // console.log("h-select:tempn:template_title:",tempn.template_title)
        // this.addedItems[index].name = tempn.template_title

      },

      handleIconChange (index) {
        this.$emit('bindImgs', index, 'default')
      },
      handleSelectedIconChange (index) {
        this.$emit('bindImgs', index, 'selected')
      },




      dateStrToTimeStamp(str) {
        return Date.parse(new Date(str)) / 1000
      },
      handleClose(index) {
          this.relDistributors.splice(index, 1);
          this.form.distributor_ids.splice(index, 1);
      },
      handleClose1(index) {
        this.customizeList.splice(index, 1);
        this.form.customize_id = 0;
      },
      closeDialogAction () {
        this.DistributorStatus = false
        this.DistributorVisible = false
      },
      closeDialogAction1 () {
        this.CustomizeStatus = false
        this.CustomizeVisible = false
      },
      addDistributoreAction() {
        this.DistributorVisible = true
        this.DistributorStatus = true
      },
      addCustomizeAction() {
        this.CustomizeStatus = true
        this.CustomizeVisible = true
      },
      CustomizeChooseAction (data) {
        this.CustomizeVisible = false
        this.CustomizeStatus = false
        if (data === null || data.length <= 0) return
        this.customizeList = data
      },

      DistributorChooseAction (data) {
        this.DistributorVisible = false
        this.DistributorStatus = false
        if (data === null || data.length <= 0) return
        this.relDistributors = data
      },
      addImgPreview () {
        this.imgDialog = true
        this.isGetImage = true
      },
      pickImg (data) {
        if (data && data.url !== '') {
          this.imgDialog = false
          var loc = this.$refs.editor
          var img = new Image()
          img.src = this.wximageurl + data.url
          if (loc.range) {
            loc.range.insertNode(img)
          } else {
            loc.$refs.content.appendChild(img)
            loc.focus()
            loc.restoreSelection()
          }
          this.form.viewcontent = loc.$refs.content.innerHTML
        }
      },
      closeImgDialog () {
        this.imgDialog = false
      },
      addThumbPreview () {
        this.thumbDialog = true
        this.isGetThumb = true
      },
      handleTypeChange (val) {
      },
      pickThumb (data) {
        if (data && data.url !== '') {
          this.thumbDialog = false
          this.form.activity_cover = data.url
        }
      },
      closeThumbDialog () {
        this.thumbDialog = false
      },
      getDistributor(ids) {
        let param = {distributor_id: ids}
        getDistributorList(param).then( res => {
          this.relDistributors = res.data.data.list
        })
      },
      getCustomize(ids) {
        let param = {id: ids}
        getCustomizeList(param).then( res => {
          this.customizeList = res.data.data.list
        })
      },
      onSubmit () {
        this.form.distributor_ids = []
        if (this.relDistributors.length > 0) {
          this.relDistributors.forEach( distributor => {
            this.form.distributor_ids.push({distributor_id: distributor.distributor_id});
          })
        }
        if (this.customizeList.length > 0) {
          this.form.customize_id = this.customizeList[0].id
        }

        if (!this.form.activity_title) {
          this.$message.error('请填写实验名称');
          return;
        }
        // if (!this.form.activity_subtitle || this.form.activity_subtitle.trim() == '' ) {
        //   this.$message.error('请填写实验参数');
        //   return;
        // }
        if (!this.form.start_time) {
          this.$message.error('请填写时间');
          return;
        }
        if (!this.form.end_time) {
          this.$message.error('请填写时间');
          return;
        }

        console.log(this.addedItems)
        console.log("addedItemslentgh:",this.addedItems.length)

        if (!this.addedItems || this.addedItems.length == 0) {
          this.$message.error('请参数值信息');
          return;
        }

        this.form.items = [];
        this.addedItems.forEach( distributor => {
            if ( !distributor.sort ) {
              distributor.sort = 2;
          }
          this.form.activity_subtitle = distributor.name
          this.form.items.push(distributor);
        })
        console.log('items: ', this.form.items)
        // if(this.form.type == 'selling') {
        //   if (!this.form.items) {
        //     this.$message.error('请选择');
        //     return;
        //   } else {
        //     this.form.items.forEach( distributor => {
        //       this.form.distributor_ids.push({item_ids: distributor.item_id,limit_num:distributor.limit_num});
        //     })
        //   }
        // }
     //   console.log(this.customizeList)
        let param = {}
        if (this.form.id) {
          param = {
            distributor_ids : this.form.distributor_ids,
            activity_title: this.form.activity_title,
            activity_subtitle: this.form.activity_subtitle,
            start_time: this.form.start_time,
            end_time: this.form.end_time,
            customize_id: this.form.customize_id,
            type: this.form.type,
            activity_cover: this.form.activity_cover,
            items: this.form.items,
            page_url: this.form.page_url,
            city_list: this.form.city_list,
            rel_city: this.form.rel_city,
          }
          updatePreferential (this.form.id, param).then(response => {
            const that = this
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 2 * 1000,
              onClose () {
                that.refresh()
                that.$router.go(-1)
              }
            })
          })
        } else {
          param = {
            distributor_ids : this.form.distributor_ids,
            activity_title: this.form.activity_title,
            customize_id: this.form.customize_id,
            type: this.form.type,
            start_time: this.form.start_time,
            end_time: this.form.end_time,
            activity_subtitle: this.form.activity_subtitle,
            activity_cover: this.form.activity_cover,
            items: this.form.items,
            page_url: this.form.page_url,
            city_list: this.form.city_list,
            rel_city: this.form.rel_city,
          }
          createPreferential (param).then(res => {
            const that = this
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 2 * 1000,
              onClose () {
                that.refresh()
                that.$router.go(-1)
              }
            })
          })
        }
      },

      getCitiesInfo() {
        let params = {
          id: this.form.city_list
        };
        getCitiesInfo(params).then(response => {
          this.citiesInfo = response.data.data
          if (params.id.indexOf('000000') >= 0) {
            this.citiesInfo.push({
              label: '默认城市',
              id: '000000',
            })
          }
        })
      },
    },
    mounted () {
      if (this.$route.query.id) {
        this.form.id = this.$route.query.id
        getPreferentialInfo(this.$route.query.id).then(async res => {
          if (res.data.data) {
            if (res.data.data.distributor_ids && res.data.data.distributor_ids.length > 0) {
              let ids = []
              res.data.data.distributor_ids.forEach(item => {
                  ids.push(item.distributor_id)
              })
              this.getDistributor(ids)
            }
            if (res.data.data.customize_id) {
              let customizeid = res.data.data.customize_id
              this.getCustomize(customizeid)
            }
            this.form.activity_title = res.data.data.activity_title
            this.form.activity_subtitle = res.data.data.activity_subtitle
            this.form.activity_cover = res.data.data.activity_cover
            this.form.start_time = res.data.data.start_time
            //  * 1000
            this.form.end_time = res.data.data.end_time
            // * 1000
            this.form.page_url = res.data.data.page_url
            this.form.type = res.data.data.type
            this.form.items = JSON.parse(res.data.data.items) || []
            this.date_range = [this.form.start_time * 1000, this.form.end_time * 1000]
            this.form.rel_city = res.data.data.rel_city
            this.form.city_list = JSON.parse(JSON.stringify(res.data.data.city_list))

            // this.date_range = res.data.data.date_range
            this.relItemsIds = JSON.parse(res.data.data.items) || []
            this.addedItems  = JSON.parse(res.data.data.items) || []

          }
          if (this.form.city_list.length > 0) {
           await this.getCitiesInfo();
          }
          this.getTemplateList()
        })
      }else{
        this.getTemplateList()
      }
      this.getABList()
      //relItems
    }
  }
</script>

<style scoped lang="scss">
  .content-padded {
    padding: 40px;
  }
  .setting-view {
    position: relative;
    background: #fff;
  }
  .appmsg_input_area {
    overflow-y: auto;
    position: relative;
  }

  .appmsg_preview_area {
    position: absolute;
    left: 0;
    height: 100%;
  }
  .appmsg_preview_container {
    width: 250px;
    padding: 20px 0;
    border-right: 1px solid #e7e7eb;
    background-color: #f8f8f8;
    height: 100%;
  }
  .appmsg {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid #e7e7eb;
    background-color: #fff;
    color: #666;
  }
  .appmsg_content {
    position: relative;
  }
  .appmsg_preview_container .appmsg {
    overflow: visible;
    margin-bottom: 0;
    padding: 0;
  }
  .appmsg_preview_container .appmsg_content {
    min-height: 134px;
    margin-top: -1px;
  }
  .multi .appmsg_content {
    padding: 0;
  }
  .appmsg_preview_container .appmsg_item_wrp {
    cursor: pointer;
  }
  .appmsg_preview_container .appmsg_item_wrp.current {
    position: relative;
    z-index: 1;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
  }
  .appmsg_preview_container .appmsg_item, .appmsg_preview_container .first_appmsg_item {
    padding: 10px;
  }
  .appmsg_preview_container .first_appmsg_item {
    border-top: 1px solid #e7e7eb;
    position: relative;
  }
  .appmsg_preview_container .appmsg_item_wrp.current .first_appmsg_item, .appmsg_preview_container .appmsg_item_wrp.current .appmsg_item {
    border-top-width: 0;
    padding: 9px 9px;
    border: 2px solid #43b548;
  }
  .cover_appmsg_item {
    position: relative;
    margin: 12px 14px;
  }
  .has_first_cover .cover_appmsg_item {
    margin: 12px 14px 12px;
  }
  .appmsg_preview_container .first_appmsg_item .cover_appmsg_item {
    margin: 0;
  }
  .appmsg_title {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.6;
    max-height: 88px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
    color: #222;
  }
  .appmsg_preview_container .cover_appmsg_item .appmsg_title {
    max-height: 28px;
  }
  .appmsg_preview_container .first_appmsg_item .appmsg_title {
    color: #fff;
    padding: 0 8px;
  }
  .has_first_cover .cover_appmsg_item .appmsg_title {
    font-size: 14px;
    padding-top: 0;
    line-height: 28px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.6)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000',endcolorstr = '#99000000');
  }
  .appmsg_title a {
    display: block;
    color: #222;
  }
  .has_first_cover .cover_appmsg_item .appmsg_title a {
    padding: 0 8px;
    color: #fff;
  }
  .appmsg_thumb_wrp {
    height: 160px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .appmsg_preview_container .appmsg_thumb_wrp {
    height: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .appmsg_thumb {
    width: 100%;
  }
  .appmsg_thumb.default {
    display: none;
    color: #c0c0c0;
    text-align: center;
    line-height: 160px;
    font-weight: 400;
    font-style: normal;
    background-color: #ececec;
    font-size: 14px;
  }
  .appmsg_preview_container .appmsg_thumb.default {
    line-height: 120px;
  }
  .editing .appmsg_thumb.default {
    display: block;
  }
  .icon_appmsg_thumb {
    width: 44px;
    height: 34px;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    font-size: 35px;
  }
  .appmsg_edit_mask {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(229,229,229,0.85)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#d9e5e5e5',endcolorstr = '#d9e5e5e5');
    text-align: center;
  }
  .appmsg_preview_container div.appmsg_edit_mask {
    top: auto;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    background: rgba(0,0,0,0.5)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#80000000',endcolorstr = '#80000000');
    text-align: right;
  }
  .appmsg_preview_container .first_appmsg_item .appmsg_edit_mask {
    line-height: 188px;
  }
  .appmsg_preview_container .first_appmsg_item:hover .appmsg_edit_mask {
    display: block
  }
  .appmsg_preview_container .appmsg_item_wrp.current .appmsg_edit_mask {
    bottom: -1px;
  }
  .editing .cover_appmsg_item:hover .appmsg_edit_mask,.editing .appmsg_item:hover .appmsg_edit_mask {
    display: block
  }
  .icon20_common {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    line-height: 1.5;
    color: #fff;
  }
  .appmsg_edit_mask a {
    margin-left: 8px;
    margin-right: 8px;
  }
  .appmsg_preview_container div.appmsg_edit_mask .sort_up_white, .appmsg_preview_container div.appmsg_edit_mask .sort_down_white {
      float: left;
      margin-top: 10px;
  }

  .appmsg_item {
    position: relative;
    padding: 12px 14px;
  }
  .appmsg_preview_container .appmsg_item, .appmsg_preview_container .first_appmsg_item {
    padding: 10px;
  }
  .multi .appmsg_item {
    border-top: 1px solid #e7e7eb;
  }
  .multi .appmsg_item:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
  }
  .multi .appmsg_item .appmsg_thumb_wrp {
    float: right;
    width: 78px;
    height: 78px;
    margin-left: 14px;
  }
  .appmsg_preview_container .appmsg_item .appmsg_thumb {
    width: 78px;
    height: 78px;
  }
  .appmsg_item .appmsg_thumb.default {
    line-height: 78px;
    font-size: 24px;
  }
  .appmsg_preview_container .appmsg_item .appmsg_thumb.default {
    line-height: 78px;
  }
  .create_access_primary {
    display: block;
    border: 2px dotted #d9dadc;
    line-height: 60px;
    text-align: center;
    margin-bottom: 20px;
  }
  .appmsg_add {
    margin: 20px 14px;
  }
  .appmsg_preview_container .appmsg_add {
    margin: 0;
    border-top-width: 0;
    line-height: 90px;
  }
  .icon35_common {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    display: inline-block;
    font-size: 35px;
    color: #ccc;
  }
  .create_access_primary i {
    cursor: pointer;
    overflow: hidden;
  }

  .appmsg_tpl_container {
    padding: 20px 0;
    border-left: 1px solid #e7e7eb;
    background-color: #fff;
    height: 100%;
  }
  .appmsg_container_hd {
    padding: 10px 20px 0;
  }
  .appmsg_container_title {
    font-weight: 400;
  }
  .appmsg_container_bd {
    padding: 15px;
    position: relative;
  }

  .appmsg_edit_highlight_area {
    padding-bottom: 80px;
    background-color: #fcfcfc;
    .header-title {
      margin-bottom: 20px;
    }
    .header_tips {
      margin-bottom: 10px;
    }
    .content-num {
      color: #999;
      text-align: right;
    }
  }
  .appmsg_input_area .cover_preview {
    float: left;
    position: relative;
    text-align: center;
    color: #fff;
    margin-top: 10px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .appmsg_input_area .cover_preview.first_appmsg_cover {
    width: 188px;
  }

  .tool_area_wrp {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    border-top: 1px solid #d9dadc;
  }
  .tool_area_wrp .tool_area {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .tool_area_wrp .tool_area .tool_bar {
    padding: 11px 210px 11px 250px;
  }
  #submitContent {
    height: 0;
    overflow: hidden;
  }
  .setting-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 200px;
    background: #ffffff;
    border-top: 1px solid #efefef;
  }

  .setting-item {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 10px;
    flex-direction: inherit;
}
</style>
