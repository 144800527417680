<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row :gutter="20">
        <el-col :md="4" :lg="8">
          <el-button
            v-if="login_type === 'disti'"
            size="mini"
            type="primary"
            icon="plus"
            @click="addPrivacyAgreement">
            添加
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="list" style="width: 100%" v-loading="loading" element-loading-text="数据加载中">
        <el-table-column prop="id" width="60" label="编号"></el-table-column>
        <el-table-column prop="privacy_title" label="标题"></el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="login_type === 'disti'"
                type="text"
                @click="editPrivacyAgreement(scope.$index, scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                @click="seePrivacyAgreement(scope.$index, scope.row)"
              >
                查看
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          :total='total_count' :page-size="params.pageSize">
        </el-pagination>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPrivacyAgreementList } from '@/api/promotions'
import ImportDialog from '@/components/importDialog'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data () {
    return {
      loading: false,
      params: {
        page: 1,
        pageSize: 20,
      },
      total_count: 0,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    addPrivacyAgreement () {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editPrivacyAgreement (index, row) {
      this.$router.push({path: this.matchHidePage('editor'), query: {id: row.id}})
    },
    seePrivacyAgreement (index, row) {
      this.$router.push({path: this.matchHidePage('detail'), query: {id: row.id}})
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getList()
    },
    getList() {
      this.loading = true
      let filter = this.params
      getPrivacyAgreementList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      })
    },
  },
  mounted () {
    this.getList()
  },
  watch: {
    '$route' () {
      this.getList()
    }
  },
}
</script>
