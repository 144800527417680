<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <div>
        提交时间
        <el-date-picker @change="searchList" v-model="params.created" value-format="timestamp" type="datetimerange"
          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-input clearable class="input-m" placeholder="请输入活动名称" v-model="params.activity_title" @change="searchList">
        </el-input>
        <el-button class="btn_input" @click="onDialog('shop')">{{ shop_num }} <i
            class="el-icon-circle-plus icon"></i></el-button>
        <el-button v-if="login_type != 'distributor'" class="btn_input" @click="onDialog('sgency')">{{ agency_num }} <i
            class="el-icon-circle-plus icon"></i></el-button>
        <el-button type="primary" @click="searchList">查询</el-button>
        <el-button type="primary" @click="exportData">导出</el-button>
      </div>

      <el-tabs type="border-card" v-model="params.platform" @tab-click="handleClick" >
        <el-tab-pane label="全部平台" name="all"></el-tab-pane>
        <el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
        <el-tab-pane label="抖音小程序" name="byte_mini"></el-tab-pane>
      <el-table :data="list" style="width: 100%" :height="wheight - 190" v-loading="loading"
        element-loading-text="数据加载中">
        <el-table-column prop="id" width="60" label="编号"></el-table-column>
        <el-table-column prop="activity_title" label="活动名称"></el-table-column>
        <el-table-column width="150" label="使用平台">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.platform === 'wxapp'" type="primary" size="mini">微信小程序</el-tag>
            <el-tag v-if="scope.row.platform === 'byte_mini'" type="primary" size="mini">抖音小程序</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="门店名称">
          <template slot-scope="scope">
            <span>{{ scope.row.distributor_info.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="门店AppleID" width="140px">
          <template slot-scope="scope">
            <span>{{ scope.row.distributor_info.shop_code }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="所属经销商">
          <template slot-scope="scope">
            <span>{{ scope.row.distributor_info.dealer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dummy_id" label="DummyID"></el-table-column>
        <el-table-column prop="total_fee" width="200" label="提交时间">
          <template slot-scope="scope">
            <div>
              <template>
                {{ scope.row.created | datetime("yyyy-MM-dd hh:mm:ss") }}
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-padded content-center">
        <el-pagination layout="total, prev, pager, next" @current-change="handleCurrentChange"
          :current-page.sync="params.page" :total='total_count' :page-size="params.page_size">
        </el-pagination>
      </div>
      </el-tabs>
    </div>
    <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
      :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
      returnType="selectRow"></DistributorSelect>
    <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers"
      :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
      :isSingleSelect="isSingleSelect"></DealerSelect>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import {
  getOfflineJoinOfflineActivityLogList
} from '@/api/promotions'

export default {
  provide() {
    return {
    }
  },
  components: {
    DistributorSelect,
    DealerSelect,
  },
  data() {
    return {
      distributorLoading: false,
      distributorVisible: false,
      DealerVisible: false,
      isValid: true,
      relDealers: [],
      DealerStatus: false,
      loading: false,
      params: {
        page: 1,
        page_size: 20,
        created: [],
        distributor_ids: [],
        dealer_ids: [],
        activity_title: '',
        platform: 'all'
      },
      total_count: 0,
      list: [],
      distributor_info: [],
      selectedDistributors: [],
      setDistributorStatus: false,
      shop_num: '归属店铺',
      agency_num: '归属经销商',
      dialog_type: 1,
      rolesListData: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ]),
    isSingleSelect() {
      const role = this.rolesListData.find(item => item.role_id === this.form.role_id)
      return role && role.role_name !== 'VAD_Admin'
    }
  },
  methods: {
    handleClick (tab, event) {
      this.params.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.page_size = pageSize
      this.getList()
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    searchList() {
      this.params.page = 1
      this.getList()
    },
    getList() {
      // this.params.page = 1
      this.loading = true
      let filter = {
        ...this.params,
      }
      getOfflineJoinOfflineActivityLogList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    onDialog(type) {
      if (type == 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      });
      this.params.distributor_ids = arr.join(',')
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '归属店铺'
      }
      this.closeDialogAction()
    },
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.params.dealer_ids = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.closeDialogAction()
    },
    closeDialogAction() {
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
    },
    exportData () {
      let filter = {
        ...this.params,
        is_export: 1
      }
      getOfflineJoinOfflineActivityLogList(filter).then(response => {
        this.$message.success('导出成功')
      }).catch(error => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.getList()
  },
  watch: {
    '$route'() {
      this.getList()
    }
  },
}
</script>

<style lang="scss" scoped>
.btn_input {
  .el-icon-circle-plus {
    margin-left: 20px !important;
  }
}

.pagination {
  margin-top: 10px
}
</style>
