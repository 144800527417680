import fetch from '@/utils/fetch'

export function getOpenStatus (query) {
  return fetch({
    url: '/members/devices_tag/get_open_status',
    method: 'get',
    params: query
  })
}

export function setOpenStatus (data) {
  return fetch({
    url: '/members/devices_tag/set_open_status',
    method: 'post',
    data
  })
}

export function getTagList (query) {
  return fetch({
    url: '/members/devices_tag/lists',
    method: 'get',
    params: query
  })
}

export function createTag (data) {
  return fetch({
    url: '/members/devices_tag/create',
    method: 'post',
    data
  })
}

export function deleteTag (tag_id) {
  return fetch({
    url: '/members/devices_tag/delete',
    method: 'post',
    data: {
      tag_id
    }
  })
}

export function updateTag (data) {
  return fetch({
    url: '/members/devices_tag/update',
    method: 'post',
    data
  })
}
