<template>
  <div v-loading="this.loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>报名信息</span>
      </div>

      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <span>报名序号：</span>{{data.sign_in_code}}
          </el-col>
          <el-col :span="6">
            <span>活动类型：</span>
            <el-tag v-if="data.join_type === 'appointment'" size="mini">预约报名</el-tag>
            <el-tag v-else size="mini">现场报名</el-tag>
          </el-col>
          <el-col :span="6">
            <span>报名状态：</span>
            <el-tag :type="data.tag_type" size="mini">{{ data.sign_in_status_desc}}</el-tag>
          </el-col>
          <el-col :span="6" >
            <span>报名时间：</span>{{data.created | datetime('yyyy-MM-dd hh:mm:ss')}}
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <span>预约场次：</span>
            <span>{{ data.session_date_time }} {{ data.session_name }}</span>
          </el-col>
          <el-col :span="6">
            <span>报名人：</span>
            <span>{{data.reservation_person_name}}</span>
            <PiiInfo pageType='3' memberType='receiver_name' optionScene="offline_activity"  :associatedId='params.id'/>
          </el-col>
          <el-col :span="6">
            <span>报名手机号：</span>
            <span v-if="data.reservation_person_mobile">
              {{data.reservation_person_mobile}}
              <PiiInfo pageType='3' memberType='receiver_mobile' optionScene="offline_activity"  :associatedId='params.id'/>
            </span>
          </el-col>
          <el-col :span="6" v-if="data.cancel_status != 0">
            <span>取消原因：</span>
            <span v-if="data.cancel_status == 1">用户取消</span>
            <span v-else>{{data.cancel_reason}}</span>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <span>礼品发放状态：</span>
            <span>{{data.gifts_issued == 1 ? '已发放' : '未发放'}}</span>
          </el-col>
          <el-col :span="6" v-if="data.gifts_issued == 1">
            <span>礼品发放时间：</span>
            <span>{{data.gifts_issue_time | datetime('yyyy-MM-dd hh:mm:ss')}}</span>
          </el-col>
          <el-col :span="6" v-if="data.gifts_issued == 1">
            <span>发放礼品名称：</span>
            <el-tag size="mini" type="info" v-for="(value, key) in data.gifts_issue_name" :key="`gift-item__${key}`">{{value}}</el-tag>
          </el-col>

          <el-col :span="6" v-if="data.signed_in == '1' && data.need_sign_in === 'true'">
            <span>签到时间：</span>
            <span>{{data.sign_in_time | datetime('yyyy-MM-dd hh:mm:ss')}}</span>
          </el-col>
          <el-col :span="6" v-if="data.need_sign_in === 'false'">
            <span>签到时间：</span>
            <span>{{data.created | datetime('yyyy-MM-dd hh:mm:ss')}}</span>
          </el-col>

        </el-row>
      </div>
    </el-card>

    <el-card class="box-card" v-if="data.relation_sales">
      <div slot="header" class="clearfix">
        <span>搭售记录</span>
      </div>

      <div>
        <el-table
          :data="data.relation_sales"
          style="width: 100%">
          <el-table-column
            prop="product_name"
            label="商品名称">
            <template slot-scope="scope">
              {{scope.row.product_name}}
              <el-tag size="middle" type="danger" v-if="scope.row.cancel_status === '1'">已删除</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="category_name"
            label="分类名称">
          </el-table-column>
          <el-table-column
            prop="product_barcode"
            label="商品编号">
          </el-table-column>
          <el-table-column
            prop='created'
            label="创建时间">
            <template slot-scope="scope">
              {{scope.row.created | datetime("yyyy-MM-dd hh:mm:ss")}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-card class="box-card" v-if="data.questionnaire_list && data.questionnaire_list.length > 0">
      <div slot="header" class="clearfix">
        <span>问卷信息</span>
      </div>

      <div v-for="(item, index) in data.questionnaire_list" :key="index">
        <el-row :gutter="20">
          <el-col :span="6">
            <span>问卷标题：</span>{{item.tpl_name}}
          </el-col>

        </el-row>
        <el-row :gutter="20">
          <el-col>
<!--            <span>问卷内容：</span>-->
            <el-table
              :border="true"
              :data="item.data"
              style="width: 100%">
              <el-table-column
                prop="tpl_list_subject"
                label="问卷内容">
              </el-table-column>
              <el-table-column
                prop="tpl_list_answer"
                label="问卷结果">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>门店信息</span>
      </div>

      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <span>门店名称：</span>
            <span>{{distributorInfo.name}}</span>
          </el-col>
          <el-col :span="6">
            <span>AppleId：</span>
            <span>{{distributorInfo.shop_code}}</span>
          </el-col>
          <el-col :span="6">
            <span>门店地址：</span>
            <span>{{distributorInfo.province + distributorInfo.city + distributorInfo.address}}</span>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <span>经销商名称：</span>
            <span>{{distributorInfo.dealer_name}}</span>
          </el-col>
          <el-col :span="6">
            <span>经销商HQID：</span>
            <span>{{distributorInfo.dealer_hqid}}</span>
          </el-col>
          <el-col :span="6">
            <span>分销商VAD：</span>
            <span>{{distributorInfo.distributor_vad}}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>活动信息</span>
      </div>

      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <span>活动标题：</span>
            <span>{{activityInfo.activity_title}}</span>
          </el-col>
          <el-col :span="6">
            <span>活动副标题：</span>
            <span>{{activityInfo.activity_subtitle}}</span>
          </el-col>
          <el-col :span="6">
            <span>活动类型：</span>
            <el-tag v-if="activityInfo.join_type === 'appointment'" size="mini">预约报名</el-tag>
            <el-tag v-else size="mini">现场报名</el-tag>
          </el-col>
          <el-col :span="6">
            <span>核销方式：</span>
            <el-tag v-if="activityInfo.sign_in_system === 'local'" size="mini" type="info">数字签到码</el-tag>
            <el-tag v-if="activityInfo.sign_in_system === 'guestbook'" size="mini" type="info">GuestBook</el-tag>
            <el-tag v-if="activityInfo.sign_in_system === 'wecom'" size="mini" type="info">Wecom</el-tag>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card" v-if="data.sign_in_system !== 'wecom'">
      <div slot="header" class="clearfix">
        <span>操作</span>
      </div>

      <div>
        <el-row :gutter="20">
          <el-col :span="6" v-show="showSignInBtn">
            <el-button
              :disabled="!(data.allow_sign_in !== 0 && this.login_type === 'distributor' && this.data.signed_in == 0 && this.data.cancel_status == 0 && data.sign_in_system === 'local' && this.data.need_sign_in !== 'false')"
              type="success"
              size="mini"
              @click="handleSignIn">
              签到
            </el-button>
          </el-col>
          <el-col :span="6" v-show="showGiftBtn">
            <el-button
              :disabled="!(this.login_type === 'distributor' && this.data.activity_info.gift.length > 0 && (this.data.signed_in == 1 || this.data.need_sign_in === 'false') && this.data.cancel_status == 0 && this.data.gifts_issued == 0)"
              type="primary"
              size="mini"
              @click="handleGiftIssue">
              发放礼品
            </el-button>
          </el-col>
          <el-col :span="6" v-show="showCancelBtn">
            <el-button
              :disabled="!(this.login_type === 'distributor' && this.data.signed_in == 0 && this.data.cancel_status == 0)"
              type="danger"
              size="mini"
              @click="handleSignInCancel">
              取消
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <div style="text-align: center; margin-bottom: 20px">
      <el-button @click="back">返回</el-button>
    </div>

    <el-dialog
      title="签到"
      :visible.sync="signInVisible"
      width="450px"
      :before-close="signInHandleClose">
      <span>签到码：</span><el-input class="input-b" v-model="signCode"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="signInVisible = false">取 消</el-button>
        <el-button type="primary" @click="signIn" :loading="signInLoading">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="发放礼品"
      :visible.sync="giftVisible"
      width="450px"
      :before-close="signInCancelHandleClose">
      <template>
        <el-select :multiple="true" v-model="giftValue" placeholder="请选择礼品">
          <el-option
            v-for="(item, index) in gifts"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="giftVisible = false">稍后发放</el-button>
        <el-button type="primary" @click="giftIssue" :loading="giftIssueLoading">确认发放</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="取消"
      :visible.sync="signInCancelVisible"
      width="450px"
      :before-close="signInCancelHandleClose">
      <span>取消原因：</span>
      <el-select v-model="cancelReason" placeholder="请选择取消原因">
        <el-option
          v-for="item in cancelReasons"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
<!--      <el-input class="input-b" v-model="cancelReason"></el-input>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="signInCancelVisible = false">取 消</el-button>
        <el-button type="primary" @click="signInCancel" :loading="signInCancelLoading">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getOfflineActivitySignUpLogDetail,
  offlineActivitySignIn,
  offlineActivitySignUpCancel,
  issuedGift,
} from '@/api/promotions'
import PiiInfo from '@/components/piiInfo/index'

export default {
  provide() {
    return {
    }
  },
  components: {
    PiiInfo
  },
  data () {
    return {
      cancelReasons: [
        {label: '临时闭店', value: '临时闭店'},
        {label: '活动改期', value: '活动改期'},
      ],
      loading: false,
      signCode: '',
      signInVisible: false,
      signInLoading: false,
      signInCancelLoading: false,
      signInCancelVisible: false,
      cancelReason: '',
      giftVisible: false,
      giftIssueLoading: false,
      gifts: [],
      giftValue: '',
      params: {
        id: 0,
        sign_in_code: '',
      },
      activityInfo: {},
      distributorInfo: {},
      data: {},
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type',
    ])
  },
  methods: {
    getInfo() {
      this.loading = true
      let filter = this.params
      getOfflineActivitySignUpLogDetail(filter).then(response => {
        this.activityInfo = response.data.data.activity_info
        this.distributorInfo = response.data.data.distributor_info
        this.data = response.data.data
        this.loading = false
      }) . catch(error => {
        this.activityInfo = {}
        this.distributorInfo = {}
        this.loading = false
      })
    },
    handleSignIn() {
      this.signInVisible = true
    },
    signInHandleClose() {
      this.signCode = ''
      this.signInVisible = false
    },
    signIn() {
      this.signCode = this.signCode.trim()
      if (!this.signCode.trim()) {
        this.$message.error('请输入签到码')
        return;
      }
      this.signInLoading = true
      let params = {
        sign_in_code: this.data.sign_in_code,
        id: this.data.id,
        activity_id: this.data.activity_id,
        sign_code: this.signCode,
      }
      offlineActivitySignIn(params).then(response => {
        this.signInLoading = false
        if (response.data.data.success) {
          this.$message.success('签到成功');
          this.signInVisible = false
          if (this.data.activity_info.gift.length > 0) {
            this.gifts = JSON.parse(JSON.stringify(this.data.activity_info.gift))
            this.giftVisible = true
          }
          this.getInfo()
        } else {
          this.$message.error('签到失败');
        }
      }).catch(error => {
        this.signInLoading = false
        // this.$message.error('签到失败');
      })
    },
    showSignInBtn () {
      return this.login_type === 'distributor' && this.data.signed_in == 0 && this.data.cancel_status == 0
    },
    showCancelBtn () {
      return this.login_type === 'distributor' && this.data.signed_in == 0 && this.data.cancel_status == 0
    },
    showGiftBtn () {
      return this.login_type === 'distributor' && this.data.activity_info.gift.length > 0 && this.data.signed_in == 1 && this.data.cancel_status == 0 && this.data.gifts_issued == 0
    },
    handleSignInCancel() {
      this.signInCancelVisible = true
    },
    signInCancelHandleClose() {
      this.giftVisible = false
      this.signInCancelVisible = false
    },
    signInCancel() {
      this.signInCancelLoading = true
      let params = {
        sign_in_code: this.data.sign_in_code,
        id: this.data.id,
        activity_id: this.data.activity_id,
        cancel_reason: this.cancelReason,
      }
      offlineActivitySignUpCancel(params).then(response => {
        this.signInCancelLoading = false
        if (response.data.data.success) {
          this.$message.success('取消成功');
          this.signInCancelVisible = false
          this.getInfo()
        } else {
          this.$message.error('取消失败');
        }
      }).catch(error => {
        this.signInCancelLoading = false
        // this.$message.error('取消失败');
      })
    },
    handleGiftIssue () {
      this.gifts = this.data.activity_info.gift
      this.giftVisible = true
    },
    giftIssue () {
      this.giftIssueLoading = true
      let params = {
        sign_in_code: this.data.sign_in_code,
        id: this.data.id,
        activity_id: this.data.activity_id,
        gift_value: this.giftValue,
      }
      issuedGift(params).then(response => {
        this.giftIssueLoading = false
        if (response.data.data.success) {
          this.$message.success('更新成功');
          this.giftVisible = false
          this.getInfo()
        } else {
          this.$message.error('更新失败');
        }
      }).catch(error => {
        this.giftIssueLoading = false
        // this.$message.error('取消失败');
      })
    },
    back() {
      this.$router.go(-1);
    },

  },
  mounted () {
    let id = this.$route.query.id
    let signInCode = this.$route.query.sign_in_code
    this.params.id = id
    this.params.sign_in_code = signInCode

    this.getInfo()
  },
  watch: {
  },
}
</script>

<style>
  .clearfix{
    font-size: 16px;
  }
</style>
