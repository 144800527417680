import fetch from '@/utils/fetch'

export const List = '/promotions/activity/exchange/statistics/list'

export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}
