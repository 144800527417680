<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row :gutter="20">
        <el-col :md="4" :lg="16">
          <el-input
            class="input-b"
            placeholder="优惠信息"
            v-model="params.discount_msg"
            @change="getOfflineActivityList">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="4" :lg="8">
          <el-button
            v-if="login_type === 'disti'"
            size="mini"
            type="primary"
            icon="plus"
            @click="addActivityData">
            创建配件优惠信息
          </el-button>
        </el-col>
      </el-row>

        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="id" width="60" label="id"></el-table-column>
          <el-table-column prop="discount_msg" width="120" label="优惠信息"></el-table-column>

          <el-table-column prop="shop_type_name" min-width="100" label="适用门店"></el-table-column>
          <el-table-column prop="time_limit" min-width="90" label="有效期"></el-table-column>
          <el-table-column prop="shop_type_import_nae" min-width="100" label="门店导入规则"></el-table-column>
          <el-table-column label="优先级" width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.sort" @change="handleIsShowChange(scope.row)"></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="status" label="上架" width="120">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                         active-text="上"
                         inactive-text="下"
                         active-value="1"
                         inactive-value="0"
                         @change="handleIsShowChange(scope.row)"></el-switch>
              <!-- {{scope.row.activity_subtitle  }}
              {{typeof(scope.row.activity_subtitle)  }} -->
            </template>
          </el-table-column>
<!--          <el-table-column prop="activity_subtitle" min-width="150" label="活动副标题"></el-table-column>-->
<!--          <el-table-column prop="total_fee" min-width="50" label="活动状态">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tag v-if="scope.row.is_end == 1 && scope.row.is_open == '1'" type="warning" size="mini">已结束</el-tag>-->
<!--              <el-tag v-else-if="scope.row.is_open == '1'" type="success" size="mini">已开启</el-tag>-->
<!--              <el-tag v-else type="danger" size="mini">已关闭</el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="操作" min-width="150">

            <template slot-scope="scope">
              <div>

                <ImportDialog
                  v-if="login_type === 'disti' && scope.row.shop_type == 2"
                  buttonName="导入适用门店"
                  :buttonId="'coupon_up_'+scope.row.id"
                  buttonType="text"
                  buttonSize="medium"
                  fileName="门店导入信息模版"
                  fileType="show_part_promotion"
                  :relId="scope.row.id"
                  :title="'优惠信息展示- 适用门店导入'"
                ></ImportDialog>
                <!--                <ImportDialog-->
                <!--                  v-if="login_type === 'disti'"-->
                <!--                  buttonName="导入商品"-->
                <!--                  buttonType="text"-->
                <!--                  fileName="上传活动商品模版"-->
                <!--                  fileType="offline_activity_distributors"-->
                <!--                  :relId="scope.row.id"-->
                <!--                ></ImportDialog>-->
                <el-divider direction="vertical"></el-divider>
                <el-button type="text"  v-if="login_type === 'disti'" @click="editActivityAction(scope.$index, scope.row)">编辑</el-button>
<!--                <el-button type="text"  v-if="scope.row.activity_begin_time >= new Date().getTime()/1000" @click="delActivityAction(scope.$index, scope.row)" >删除</el-button>-->
              </div>


              <div>

                <!--                <el-button type="warning" size="mini" round  @click="showDistributors(scope.row)">查看适用商品</el-button>-->
                <el-button v-if="scope.row.shop_type == 2" type="text"  @click="showDistributors(scope.row)">查看适用店铺</el-button>
              </div>



              <div>
<!--                <el-button-->
<!--                  v-if="login_type === 'disti'"-->
<!--                  type="text"-->
<!--                  @click="editActivityAction(scope.$index, scope.row)"-->
<!--                >-->
<!--                  编辑-->
<!--                </el-button>-->
<!--                <el-button-->
<!--                  type="text"-->
<!--                  @click="seeActivityAction(scope.$index, scope.row)"-->
<!--                >-->
<!--                  查看-->
<!--                </el-button>-->
<!--                <ImportDialog-->
<!--                  v-if="login_type === 'disti'"-->
<!--                  buttonName="上传店铺"-->
<!--                  buttonType="text"-->
<!--                  fileName="上传活动店铺模版"-->
<!--                  fileType="offline_activity_distributors"-->
<!--                  :relId="scope.row.id"-->
<!--                ></ImportDialog>-->
<!--                <el-button-->
<!--                  type="text"-->
<!--                  @click="showDistributors(scope.row)"-->
<!--                >-->
<!--                  查看店铺-->
<!--                </el-button>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
    </div>

    <el-dialog
      :before-close="beforeCloseDistributorDialog"
      :title=dialogTitle
      :visible.sync="distributorDialogVisible"
      width="60%">
      <div v-loading="dialogLoading"  element-loading-text="数据加载中">
        <div>
<!--          <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>-->
          <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" @click="setCouponStoreList"></el-button>
        </div>

        <el-table
          :data="distributorList"
          v-loading="ShopLoading"
          style="line-height: normal"
        >
          <template>
            <el-table-column label="门店名称" prop="name"></el-table-column>
            <el-table-column
              label="AppleID"
              prop="shop_code"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="dealer_name"
              label="所属经销商"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="操作"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
              <el-button type="text"  v-if="login_type === 'disti'" @click="deleteShopData(scope.row)">删除</el-button>
              </template>
            </el-table-column>

             </template>
        </el-table>
        <div class="content-padded content-center"">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindStoreCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :page-sizes="[5,10,20,50]"
            :total="totalStoreCount"
            :page-size="bindStorePagination.page_size">
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getOfflineActivityList,
  getCouponActivityShops,
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  syncLingzhiOfflineActivityDistributors,
  getShowPartList,
  getPromoShopList
} from '@/api/promotions'

import ImportDialog from '@/components/importDialog'
import {deleteShop, updateList} from "../../../../api/promotions";

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data () {
    return {
      ShopLoading:false,
      dialogTitle:'门店',
      dialogLoading:false,
      is_activity_end:false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        page_size: 5,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 20,
        activity_title: '',
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    deleteShopData(row){
      this.ShopLoading = true;
      deleteShop(row).then(response => {
        this.ShopLoading = false;
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 2 * 1000,
          onClose:()=> {

            this.setCouponStoreList()
          }
        })
      })
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getOfflineActivityList()
    },
    addActivityData () {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editActivityAction (index, row) {
      this.$router.push({path: this.matchHidePage('editor'), query: {activity_id: row.id}})
    },
    seeActivityAction (index, row) {
      this.$router.push({path: this.matchHidePage('detail'), query: {activity_id: row.id}})
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getOfflineActivityList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    handleIsShowChange(row){
      updateList(row).then(response => {
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 2 * 1000,
          onClose:()=> {
            this.getOfflineActivityList()
          }
        })
      })
    },
    getOfflineActivityList() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getShowPartList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },

    changeDistributorJoinStatus(row) {
      this.distributorStatusLoading = true
      if (!this.activity_id) {
        this.$message.error('编辑失败')
        return
      }
      let changeStatus = 1
      if (row.status === '1') {
        changeStatus = 2
      }
      let params = {
        status: changeStatus,
        distributor_id: row.distributor_id,
        activity_id: this.activity_id,
      }
      changeDistributorJoinStatus(params).then(response => {
        if (response.data.data.success == true) {
          this.distributorStatusLoading = false
          this.$message.success('修改成功')
          this.setBindStoreList()
        } else {
          this.$message.error('修改失败')
          this.distributorStatusLoading = false
        }
      }).catch(error => {
        // this.$message.error('修改失败')
        this.distributorStatusLoading = false
      })
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setBindStoreList()
    },
    async setBindStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: bindList } = await getPromoShopList({
        activity_id: this.activity_id,
        ...this.bindStorePagination
      }) .catch (error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
    },
    // showDistributors(row) {
    //   if(row.sign_up_end_time <= new Date().getTime()/1000){
    //     this.is_activity_end = true
    //   }else{
    //     this.is_activity_end = false
    //   }
    //   this.activity_id = row.id
    //   this.distributorDialogVisible = true
    //   this.setBindStoreList()
    // },
    showDistributors(row) {
      this.dialogLoading = true
      this.activity_id = row.id
      this.distributorDialogVisible = true
      this.dialogTitle = ' 适用店铺列表'
      this.dialogId = row.id
      this.setCouponStoreList()
    },
    async setCouponStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getPromoShopList({"activity_id":this.activity_id, "page":this.bindStorePagination}).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
      this.dialogLoading = false
    },
    beforeCloseDistributorDialog(){
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
    },
    exportData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = {
        activity_id: this.activity_id,
        ...this.bindStorePagination,
        is_export: 1
      }
      getBindOfflineActivityStoreList(params).then(response => {
        loading.close()
        if (response.data.data.success) {
          this.$message.success('导出成功')
        }
      }) .catch (error => {
        loading.close()
      })
    },
    syncLingzhi() {
      let params = {
        activity_id: this.activity_id,
      }
      syncLingzhiOfflineActivityDistributors(params).then(response => {
        if (response.data.data.success) {
          this.$message.success('操作成功')
          this.distributorDialogVisible = false
        }
      }) .catch (error => {
      })
    },
  },
  mounted () {
    this.getOfflineActivityList()
  },
  watch: {
    '$route' () {
      this.getOfflineActivityList()
    }
  },
}
</script>
