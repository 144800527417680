import fetch from '@/utils/fetch'

export function getMemberData (query) {
  return fetch({
    url: '/members/guarantee/search',
    method: 'get',
    params: query
  })
}

/** 核销 */
export function chargeOff (data) {
  return fetch({
    url: '/members/guarantee',
    method: 'post',
    data
  })
}
