<template>
  <el-form ref="form" :model="form" class="box-set" label-width="150px">
    <el-card header="设置规则" shadow="naver">
      <el-form-item v-show="false" label="名称" prop="activity_name" :rules="{required: true, message: '请填写规则名称', trigger: 'blur'}">
        <el-col :span="20">
          <el-input v-model="form.activity_name" :maxlength=30 placeholder="最多30个字"></el-input>
        </el-col>
      </el-form-item>

<!--      <el-form-item  v-show="true"  label="每人进行中的次数" prop="limit_num">-->

<!--        <el-input v-model="form.limit_num" :maxlength=30  style="width: 100px" disabled></el-input>-->
<!--        次-->
<!--        <p class="frm-tips">限制用户进行中的以旧换新次数，0不限制</p>-->
<!--      </el-form-item>-->

      <el-form-item  v-show="false" label="生效时间">
        <el-col :span="20">
          <el-date-picker
            v-model="activity_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="生效时间"
            end-placeholder="过期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-col>
      </el-form-item>
      <!-- 删除 删除 删除 删除 删除 删除 删除 删除 删除 删除 删除 -->
      <!--
      <el-form-item v-if="1==2" label="商品维度">
        <el-switch
          v-model="limit_num_type"
          active-text="SKU"
          inactive-text="SPU"
          active-value="sku"
          inactive-value="spu"
          inactive-color="#13ce66"
          v-if="this.$route.params.limit_id"
          disabled>
        </el-switch>
        <el-switch
          v-model="limit_num_type"
          active-text="SKU"
          inactive-text="SPU"
          active-value="sku"
          inactive-value="spu"
          inactive-color="#13ce66"
          @change="changeLimitNumType"
          v-else>
        </el-switch>
      </el-form-item> -->
      <!-- 删除 删除 删除 删除 删除 删除 删除 删除 删除 删除 删除 -->

      <el-form-item v-if="1==1" label="启用开关">
        <!-- inactive-color="#13ce66" -->
        <el-switch
          v-model="form.is_tradein_open"
          active-text="开"
          inactive-text="关"
          active-value="1"
          inactive-value="0"
          v-if="this.$route.params.limit_id"
          disabled>
        </el-switch>
        <!-- inactive-color="#13ce66" -->
        <el-switch
          v-model="form.is_tradein_open"
          active-text="开"
          inactive-text="关"
          active-value="1"
          inactive-value="0"
          @change="changeTradeinOpen"
          v-else>
        </el-switch>
      </el-form-item>
      <el-form-item label="活动区域">

      <section class="section section-white shipping-calc">
        <p class="font-bold"></p>
        <p class="font-bold nopost" v-if="nopost_number_conf && nopost_number_conf.length > 0">{{nopost_number_conf|formatCityData(district)}}</p>
        <p class="font-bold nopost" v-else>暂无数据</p>
        <div class="content-v-padded">
          <el-button type="primary" size="mini"
                     @click="handleNumberNoPostEditArea()">编辑地区
          </el-button>
        </div>
      </section>
      </el-form-item>
      <el-form-item label="商详页折扣金额显示">
        <section class="section section-white shipping-calc">
          <el-radio-group v-model="form.price_show_type">
            <el-radio-button :label="1">机型折抵</el-radio-button>
            <el-radio-button :label="2">最高折抵</el-radio-button>
          </el-radio-group>
        </section>
      </el-form-item>
      <el-form-item label="补贴规则">
        <section class="section section-white shipping-calc">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入补贴规则说明，回车换行"
            v-model="form.subsidy_rule"
            style="width:40%"
          >
          </el-input>
        </section>
      </el-form-item>

    </el-card>

    <el-card v-if="1==2" header="选择换新商品" shadow="naver">
      <el-form-item label="适用商品">
        <el-radio-group v-model="form.use_bound" @change="itemTypeChange">
          <el-radio label="goods">指定商品适用</el-radio>
          <!--          <el-radio label="category">指定分类适用</el-radio>-->
          <!--          <el-radio label="tag">指定商品标签适用</el-radio>-->
          <!--          <el-radio label="brand">指定品牌适用</el-radio>-->
        </el-radio-group>
      </el-form-item>
      <div v-if="!zdItemHidden" style="position: relative">
        <SkuSelector  @change="getItems" :data="relItems" :selectSku="isSelectSku"></SkuSelector>
        <!--        <div style="position: absolute; bottom: 0px; left: 112px">-->
        <!--          <el-upload style="display: inline-block; height: 0" action="" :on-change="uploadHandleChange" :auto-upload="false" :show-file-list="false">-->
        <!--            <el-button type="primary">批量上传</el-button>-->
        <!--          </el-upload>-->
        <!--          <el-button style="margin-left: 10px" type="primary" @click="uploadHandleTemplate()">下载模板</el-button>-->
        <!--        </div>-->
      </div>
      <el-col :xs="12" :sm="12" :md="12">
        <div style="height: 350px" v-if="!categoryHidden">
          <treeselect
            :options="categoryList"
            :show-count="true"
            :multiple="true"
            :disable-branch-nodes="true"
            :clearable="false"
            v-model="form.item_category"
            @select="categorySelect"
            @deselect="categoryDeselect"
          >
          </treeselect>
        </div>
      </el-col>
      <template v-if="!tagHidden">
        <div class="selected-tags view-flex">
          <div class="label">已选中标签：</div>
          <div class="view-flex-item">
            <el-tag :key="index" v-for="(tag, index) in tag.currentTags" closable size="small" :disable-transitions="false" @close="tagRemove(index)">
              {{tag.tag_name}}
            </el-tag>
          </div>
        </div>
        <div>
          <el-tag class="tag-item" :key="index" v-for="(tag, index) in tag.tags" size="medium" color="#ffffff" :disable-transitions="false" @click.native="tagAdd(tag, index)">
            {{tag.tag_name}}
          </el-tag>
        </div>
      </template>
      <template v-if="!brandHidden">
        <div class="selected-tags view-flex">
          <div class="label">已选中品牌：</div>
          <div class="view-flex-item">
            <el-tag :key="index" v-for="(brand, index) in brand.currentBrands" closable size="small" :disable-transitions="false" @close="brandRemove(index)">
              {{brand.attribute_name}}
            </el-tag>
          </div>
        </div>
        <div>
          <el-tag class="tag-item" :key="index" v-for="(brand, index) in brand.brands" size="medium" color="#ffffff" :disable-transitions="false" @click.native="brandAdd(brand, index)">
            {{brand.attribute_name}}
          </el-tag>
        </div>
      </template>
    </el-card>
    <el-card v-if="1==2" header="设置商品" shadow="naver">
      <el-table v-if="form.items.length>0" :data="form.items" style="line-height: normal">
        <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
        <el-table-column label="名称" prop="item_name"></el-table-column>
        <el-table-column label="规格" prop="item_spec_desc"></el-table-column>
        <el-table-column label="商品编码" prop="item_bn"></el-table-column>

      </el-table>
    </el-card>
    <el-card  v-if="1==2" header="无效商品（已参加其他活动）" shadow="naver">
      <el-table v-if="invalidItemsList.length>0" :data="invalidItemsList" style="line-height: normal">
        <el-table-column label="ID" prop="item_id" width="60"></el-table-column>
        <el-table-column label="名称" prop="itemName"></el-table-column>
        <el-table-column label="规格" prop="item_spec_desc"></el-table-column>
        <!-- <el-table-column label="操作" width="50">
          <template slot-scope="scope">
          <i class="iconfont icon-trash-alt" @click="deleteItemRow(scope.$index, form.items)"></i>
          </template>
        </el-table-column> -->
      </el-table>
    </el-card>
    <div class="content-center">
      <el-button type="primary" v-if="hasSaveButton" @click="submitOldbynewAction()">保存</el-button>
      <el-button @click.native="handleCancel">返回</el-button>
      <el-dialog title="选择区域" :visible.sync="dialogAreaFormVisible" width="960px">
        <el-row>
          <el-col>小程序版本在v2.3.2（不包含v2.3.2）以上地区选择才有效，请及时升级小程序 >> <router-link :to="{ path: '/wxapp/manage/editauthorize'}">小程序版本</router-link></el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-row class="province-list">
              <el-col>省份</el-col>
              <el-col v-for="(item, index) in areaInfo"
                      :style="provinceIndex == index?'background: #ddd':''"
                      :key="'province' + index">
                <el-checkbox v-model="item.checked" :value="item.value" style="width: 50%"
                             @change="handleCheckProvinceChange(index, item.checked)">
                  {{item.label}}
                </el-checkbox>
                <div class="el-icon-caret-right" @click="handlechildProvinceChoose(index)"></div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" v-if="provinceIndex > -1">
            <el-row class="city-list">
              <el-col>城市</el-col>
              <el-col v-for="(item, index) in areaInfo[provinceIndex].children"
                      :style="cityIndex == index?'background: #ddd':''" :key="'city' + index">
                <el-checkbox v-model="item.checked" :value="item.value" style="width: 50%"
                             @change="handleCheckCityChange(index, item.checked)">
                  {{item.label|subStr(12)}}
                </el-checkbox>
                <div class="el-icon-caret-right" @click="handlechildCityChoose(index)"></div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" v-else>
            <el-row class="region-list">
              <el-col>城市</el-col>
            </el-row>
          </el-col>
<!--          <el-col :span="6" v-if="cityIndex > -1">-->
<!--            <el-row class="region-list">-->
<!--              <el-col>地区</el-col>-->
<!--              <el-col v-for="(item, index) in areaInfo[provinceIndex].children[cityIndex].children"-->
<!--                      :key="'city' + index">-->
<!--                <el-checkbox v-model="item.checked" :value="item.value"-->
<!--                             @change="handleCheckRegionChange(index, item.checked)">-->
<!--                  {{item.label|subStr(12)}}-->
<!--                </el-checkbox>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
<!--          <el-col :span="6" v-else>-->
<!--            <el-row class="region-list">-->
<!--              <el-col>地区</el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
          <el-col :span="6">
            <el-row class="region-list">
              <el-col>
                已选：<br />
                {{conf_area|formatCityData(district, false)}}
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAreaFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleAreaChooseFinish">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </el-form>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import SkuSelector from '@/components/function/skuSelector'
import {getGradeList} from '../../../../api/membercard'
import {listVipGrade} from '../../../../api/cardticket'
import {
  createLimitPromotions,
  oldbynewActivityGetInfo,
  updateLimitPromotions,
  seckillActivityGetItemsList,
  oldbynewActivityGetItemsList,
  createOldbynewPromotions, updateOldbynewPromotions
} from '../../../../api/promotions'
import { getItemsList, getCategory, getTagList, getGoodsAttr } from '@/api/goods'
import {handleUploadFile, exportUploadTemplate} from '../../../../api/common'
import {getAddress} from '../../../../api/common'
import {getShippingTemplatesInfo} from "../../../../api/shipping";
export default {
  inject: ['refresh'],

  components: {
    SkuSelector,
    Treeselect
  },
  data() {
    return {
      relItems: [],
      activity_date: '',
      validGrade: [],
      vipGrade: [],
      memberGrade: [],
      limit_num: 0,
      rule: {

        limit_num: 0,
      },
      limit_num_type: 'spu',
      is_tradein_open: 0,
      isSelectSku: false,
      form: {
        activity_name: '',
        items: [],
        start_time: '',
        end_time: '',
        is_tradein_open: 0,
        price_show_type: 1,
        subsidy_rule: '',
        valid_grade: [],
        use_bound: 'goods',
        item_category: [],
        tag_ids : [],
        brand_ids: [],
        is_presale: false,
        activity_start_time: '',
        activity_end_time: '',
        preheat_time: '',
        presale_time: '',
        sale_time: '',
        preheat_text: '',
        presale_text: '',
        // limit_num: 1,
        limit_num: 0,
        sale_text: '',
        verification_mobile_number: false,
      },
      relMain: [],
      setMainStatus: false,
      mainVisible: false,
      hasSaveButton: true,
      zdItemHidden: false,
      categoryHidden: true,
      categoryList: [],
      nopost_number_conf: [],
      dialogAreaFormVisible: false,
      provinceIndex: -1,
      cityIndex: -1,
      conf_area: [],
      templatesId: '',
      district: {},
      areaInfo: [],
      areaInfoCount: [],
      tagHidden: true,
      tag: {
        list: [],
        form: {
          tag_ids: [],
        },
        currentTags: [],
        tags: []
      },
      brandHidden: true,
      brand: {
        list: [],
        form: {
          brand_ids: [],
        },
        currentBrands: [],
        brands: []
      },
      ItemsList: [],
      invalidItemsList: [],
      params: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        templates_id: '',
        keywords: '',
        category: 0,
        is_warning: false,
        tag_id: '',
        is_gift: 'all',
        type: 0,
        is_sku: 'flase',
        item_id: '',
      },
    }
  },
  methods: {
    submitOldbynewAction() {
      const that = this
      let formData = JSON.parse(JSON.stringify(this.form))
      if (this.activity_date.length > 0) {
        formData.start_time = this.activity_date[0]
        formData.end_time = this.activity_date[1]
      }
      formData.limit = this.rule.limit
      formData.is_tradein_open = this.form.is_tradein_open
      formData.price_show_type = this.form.price_show_type
      formData.subsidy_rule = JSON.stringify(this.form.subsidy_rule)

      formData.item_limits = this.form.items
      formData.limit_num = this.form.limit_num
      formData.nopost_number_conf = this.nopost_number_conf
      if(this.invalidItemsList.length > 0 ){
        this.$message({
          type: 'error',
          message: '存在不可用商品!'
        })
        return false
      }


      if (this.$route.params.old_id) {
        updateOldbynewPromotions(this.$route.params.old_id, formData).then(response => {
          this.loading = false
          this.$message({
            message: '修改成功',
            type: 'success',
            // duration: 2 * 1000,
            // onClose() {
            //   that.$router.go(-1)
            // }
            onClose () {
              // that.submitLoading = false
              // that.refresh()
              // that.isLeave = true
              that.$router.go(-1)
            }

          })
        }).catch()
      } else {
        createOldbynewPromotions(formData).then(response => {
          this.loading = false
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()
      }
    },
    // 格式化城市编辑
    formatCityDataEdit(conf, row) {
      if (row == null) row = []
      // console.log(conf, row)
      let that = this,
        searchIndex

      this.provinceIndex = -1
      this.cityIndex = -1
      this.areaInfo = JSON.parse(JSON.stringify(this.district))
      var cityChooseList = []
      var cityChooseNowList = []
      if (row.length > 0) {
        for (var k in row) {
          cityChooseNowList.push(row[k])
        }
      }
      if (typeof(row.area) != "undefined") {
        for (var k in row.area) {
          cityChooseNowList.push(row.area[k])
        }
      }

      for (var i in conf) {
        for (var j in conf[i].area) {
          cityChooseList.push(conf[i].area[j])
        }
      }

      for (var provinceKey = this.areaInfo.length - 1; provinceKey >= 0; provinceKey--) {
        Object.assign(this.areaInfo[provinceKey], {"checked": false})
        if (cityChooseNowList.indexOf(this.areaInfo[provinceKey].value) > -1) {
          Object.assign(this.areaInfo[provinceKey], {"checked": true})
        } else {
          searchIndex = cityChooseList.indexOf(this.areaInfo[provinceKey].value)
          if (searchIndex > -1) {
            this.areaInfo.splice(provinceKey, 1)
            continue
          }
        }
        for (var cityKey = this.areaInfo[provinceKey].children.length - 1; cityKey >= 0; cityKey--) {
          Object.assign(this.areaInfo[provinceKey].children[cityKey], {"checked": false})
          this.$set(this.areaInfo[provinceKey].children, cityKey, this.areaInfo[provinceKey].children[cityKey])
          if (cityChooseNowList.indexOf(this.areaInfo[provinceKey].value) > -1 || cityChooseNowList.indexOf(this.areaInfo[provinceKey].children[cityKey].value) > -1) {
            Object.assign(this.areaInfo[provinceKey], {"checked": true})
            Object.assign(this.areaInfo[provinceKey].children[cityKey], {"checked": true})
          } else {
            searchIndex = cityChooseList.indexOf(this.areaInfo[provinceKey].children[cityKey].value)
            if (searchIndex > -1) {
              this.areaInfo[provinceKey].children.splice(cityKey, 1)
              continue
            }
          }
          for (var regionKey = this.areaInfo[provinceKey].children[cityKey].children.length - 1; regionKey >= 0; regionKey--) {
            Object.assign(this.areaInfo[provinceKey].children[cityKey].children[regionKey], {"checked": false})
            this.$set(this.areaInfo[provinceKey].children[cityKey].children, regionKey, this.areaInfo[provinceKey].children[cityKey].children[regionKey])
            if (cityChooseNowList.indexOf(this.areaInfo[provinceKey].value) > -1 || cityChooseNowList.indexOf(this.areaInfo[provinceKey].children[cityKey].value) > -1 || cityChooseNowList.indexOf(this.areaInfo[provinceKey].children[cityKey].children[regionKey].value) > -1) {
              Object.assign(this.areaInfo[provinceKey], {"checked": true})
              Object.assign(this.areaInfo[provinceKey].children[cityKey], {"checked": true})
              Object.assign(this.areaInfo[provinceKey].children[cityKey].children[regionKey], {"checked": true})
            } else {
              searchIndex = cityChooseList.indexOf(this.areaInfo[provinceKey].children[cityKey].children[regionKey].value)
              if (searchIndex > -1) {
                this.areaInfo[provinceKey].children[cityKey].children.splice(regionKey, 1)
                continue
              }
            }
          }
          //   if (0 == this.areaInfo[provinceKey].children[cityKey].children.length) {
          //     this.areaInfo.splice(cityKey, 1)
          //     continue
          //   }
          this.$set(this.areaInfo, provinceKey, this.areaInfo[provinceKey])
        }
        if (0 == this.areaInfo[provinceKey].children.length) {
          this.areaInfo.splice(provinceKey, 1)
          continue
        }
      }
    },
    // 地区选择完成
    handleAreaChooseFinish() {
      if (this.conf_area.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择地区'
        })
        return false
      }
      switch (this.conf_name) {
        case 'fee_conf':
          if (this.conf_id !== null) {
            this.fee_conf[this.conf_id].area = this.conf_area
            this.$set(this.fee_conf, this.conf_id, this.fee_conf[this.conf_id])
          } else {
            this.fee_conf.push({
              area: this.conf_area.sort(),
              start_standard: '',
              start_fee: '',
              add_standard: '',
              add_fee: '',
            })
          }
          break
        case 'nopost_conf':
          this.nopost_conf = this.conf_area.sort()
          break
        case 'nopost_self_conf':
          this.nopost_self_conf = this.conf_area.sort()
          break
        case 'nopost_number_conf':
          this.nopost_number_conf = this.conf_area.sort()
          this.nopost_number_conf = this.conf_area.sort()
          break
        case 'nopost_money_conf':
          this.nopost_money_conf = this.conf_area.sort()
          break
        case 'nopost_volume_conf':
          this.nopost_volume_conf = this.conf_area.sort()
          break
        case 'free_conf':
          if (this.conf_id !== null) {
            this.free_conf[this.conf_id].area = this.conf_area
            this.$set(this.free_conf, this.conf_id, this.free_conf[this.conf_id])
          } else {
            this.free_conf.push({
              area: this.conf_area.sort(),
              freetype: '1',
              inweight: '',
              upmoney: '',
            })
          }
          break
        case 'fee_number_conf':
          if (this.conf_id !== null) {
            this.fee_number_conf[this.conf_id].area = this.conf_area
            this.$set(this.fee_number_conf, this.conf_id, this.fee_number_conf[this.conf_id])
          } else {
            this.fee_number_conf.push({
              area: this.conf_area.sort(),
              start_standard: '',
              start_fee: '',
              add_standard: '',
              add_fee: '',
            })
          }
          break
        case 'free_number_conf':
          if (this.conf_id !== null) {
            this.free_number_conf[this.conf_id].area = this.conf_area
            this.$set(this.free_number_conf, this.conf_id, this.free_number_conf[this.conf_id])
          } else {
            this.free_number_conf.push({
              area: this.conf_area.sort(),
              freetype: '1',
              upquantity: '',
              upmoney: '',
            })
          }
          break
        case 'fee_volume_conf':
          if (this.conf_id !== null) {
            this.fee_volume_conf[this.conf_id].area = this.conf_area
            this.$set(this.fee_volume_conf, this.conf_id, this.fee_volume_conf[this.conf_id])
          } else {
            this.fee_volume_conf.push({
              area: this.conf_area.sort(),
              start_standard: '',
              start_fee: '',
              add_standard: '',
              add_fee: '',
            })
          }
          break
        case 'free_volume_conf':
          if (this.conf_id !== null) {
            this.free_volume_conf[this.conf_id].area = this.conf_area
            this.$set(this.free_volume_conf, this.conf_id, this.free_volume_conf[this.conf_id])
          } else {
            this.free_volume_conf.push({
              area: this.conf_area.sort(),
              freetype: '1',
              upvolume: '',
              upmoney: '',
            })
          }
          break
        case 'fee_money_conf':
          if (this.conf_id !== null) {
            this.fee_money_conf[this.conf_id].area = this.conf_area
            this.$set(this.fee_money_conf, this.conf_id, this.fee_money_conf[this.conf_id])
          } else {
            this.fee_money_conf.push({
              area: this.conf_area.sort(),
              rules: [{
                up: 0.00,
                down: '',
                basefee: ''
              }]
            })
          }
          break
      }
      console.log(this.nopost_number_conf)
      this.dialogAreaFormVisible = false
    },
    // 选择地区
    handleAddArea(type) {
      var conf = []
      switch (type) {
        case 'fee_conf':
          conf = this.fee_conf
          break
        case 'free_conf':
          conf = this.free_conf
          break
        case 'fee_number_conf':
          conf = this.fee_number_conf
          break
        case 'free_number_conf':
          conf = this.free_number_conf
          break
        case 'fee_volume_conf':
          conf = this.fee_volume_conf
          break
        case 'free_volume_conf':
          conf = this.free_volume_conf
          break
        case 'fee_money_conf':
          conf = this.fee_money_conf
          break
      }
      this.conf_name = type
      this.formatCityDataClear(conf)
      this.conf_id = null
      this.conf_area = []
      this.provinceIndex = -1
      this.cityIndex = -1
      this.dialogAreaFormVisible = true

    },

    // 省份区域选择
    handleCheckProvinceChange(index, checked) {
      let that = this,
        searchIndex,
        regionAllNum = 0,
        checkedCityAllNum = 0,
        checkedRegionAllNum = 0,
        checkedRegionAllArrNum = [],
        provinceInfo = this.areaInfo,
        cityInfo = this.areaInfo[index].children
      // 样式开始
      Object.assign(provinceInfo[index], {"checked": checked})
      this.$set(provinceInfo, index, provinceInfo[index])
      searchIndex = this.conf_area.indexOf(provinceInfo[index].value)
      if (searchIndex > -1) this.conf_area.splice(searchIndex, 1)
      cityInfo.forEach(function (cityItem) {
        if (typeof(checkedRegionAllArrNum[cityItem.value]) == 'undefined') checkedRegionAllArrNum[cityItem.value] = 0
        searchIndex = that.conf_area.indexOf(cityItem.value)
        if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
        Object.assign(cityItem, {"checked": checked})
        if (true == cityItem.checked) checkedCityAllNum++
        regionAllNum += that.areaInfoCount[cityItem.value]
        cityItem.children.forEach(function (regionItem) {
          searchIndex = that.conf_area.indexOf(regionItem.value)
          if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
          Object.assign(regionItem, {"checked": checked})
          if (true == regionItem.checked) {
            checkedRegionAllArrNum[cityItem.value]++
            checkedRegionAllNum++
          }
        })
      })
      // 样式结束
      if (true == checked) {
        if (this.areaInfoCount[provinceInfo[index].value] == checkedCityAllNum && regionAllNum == checkedRegionAllNum) {
          this.conf_area.push(provinceInfo[index].value)
        } else {
          cityInfo.forEach(function (cityItem) {
            if (true == cityItem.checked && checkedRegionAllArrNum[cityItem.value] == that.areaInfoCount[cityItem.value]) {
              that.conf_area.push(cityItem.value)
            } else {
              cityItem.children.forEach(function (regionItem) {
                that.conf_area.push(regionItem.value)
              })
            }
          })
        }
      }

      // console.log('province', this.conf_area)
    },
    handlechildProvinceChoose(index) {
      this.cityIndex = -1
      this.provinceIndex = index
    },
    // 城市选择
    handleCheckCityChange(index, checked) {
      let that = this,
        searchIndex,
        checkedCityAllNum = 0,
        checkedRegionAllNum = 0,
        checkedRegionAllArrNum = [],
        provinceInfo = this.areaInfo,
        cityInfo = this.areaInfo[this.provinceIndex].children,
        regionInfo = this.areaInfo[this.provinceIndex].children[index].children
      // 选择样式开始
      Object.assign(provinceInfo[this.provinceIndex], {"checked": false})
      this.$set(provinceInfo, this.provinceIndex, provinceInfo[this.provinceIndex])
      Object.assign(cityInfo[index], {"checked": checked})
      searchIndex = this.conf_area.indexOf(provinceInfo[this.provinceIndex].value)
      if (searchIndex > -1) this.conf_area.splice(searchIndex, 1)
      cityInfo.forEach(function (cityItem) {
        if (typeof(checkedRegionAllArrNum[cityItem.value]) == 'undefined') checkedRegionAllArrNum[cityItem.value] = 0
        searchIndex = that.conf_area.indexOf(cityItem.value)
        if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
        if (true == cityItem.checked) {
          checkedCityAllNum++
          Object.assign(provinceInfo[that.provinceIndex], {"checked": true})
        }
        cityItem.children.forEach(function (regionItem) {
          if (true == regionItem.checked) {
            checkedRegionAllArrNum[cityItem.value]++
          }
        })
      })

      checkedRegionAllArrNum[cityInfo[index].value] = 0
      regionInfo.forEach(function (regionItem) {
        searchIndex = that.conf_area.indexOf(regionItem.value)
        if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
        if (true == checked) {
          checkedRegionAllArrNum[cityInfo[index].value]++
          checkedRegionAllNum++
        }
        Object.assign(regionItem, {"checked": checked})
      })
      // 选择样式结束
      if (checkedCityAllNum == this.areaInfoCount[provinceInfo[this.provinceIndex].value] && checkedRegionAllNum == this.areaInfoCount[cityInfo[index].value]) {
        this.conf_area.push(provinceInfo[this.provinceIndex].value)
      } else {
        cityInfo.forEach(function (cityItem) {
          if (cityItem.checked == true && checkedRegionAllArrNum[cityItem.value] == that.areaInfoCount[cityItem.value]) that.conf_area.push(cityItem.value)
        })
        if (checkedRegionAllNum != this.areaInfoCount[cityInfo[index].value]) {
          searchIndex = that.conf_area.indexOf(cityInfo[index].value)
          if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
          regionInfo.forEach(function (regionItem) {
            if (checked == true) that.conf_area.push(regionItem.value)
          })
        }
      }
      // console.log('city', this.conf_area)
    },
    handlechildCityChoose(index) {
      this.cityIndex = index
    },
    // 选择区
    handleCheckRegionChange(index, checked) {
      let that = this,
        searchIndex,
        checkedCityAllNum = 0,
        checkedRegionAllNum = 0,
        provinceInfo = this.areaInfo,
        cityInfo = this.areaInfo[this.provinceIndex].children,
        regionInfo = this.areaInfo[this.provinceIndex].children[this.cityIndex].children
      // 样式选择开始
      Object.assign(provinceInfo[this.provinceIndex], {"checked": false})
      this.$set(provinceInfo, this.provinceIndex, provinceInfo[this.provinceIndex])
      Object.assign(cityInfo[this.cityIndex], {"checked": false})
      Object.assign(regionInfo[index], {"checked": checked})
      searchIndex = this.conf_area.indexOf(provinceInfo[this.provinceIndex].value)
      if (searchIndex > -1) this.conf_area.splice(searchIndex, 1)
      regionInfo.forEach(function (regionItem) {
        searchIndex = that.conf_area.indexOf(regionItem.value)
        if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
        if (true == regionItem.checked) {
          checkedRegionAllNum++
          Object.assign(cityInfo[that.cityIndex], {"checked": true})
        }
      })
      cityInfo.forEach(function (cityItem) {
        searchIndex = that.conf_area.indexOf(cityItem.value)
        if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
        if (true == cityItem.checked) {
          checkedCityAllNum++
          Object.assign(provinceInfo[that.provinceIndex], {"checked": true})
        }
      })
      // 样式选择结束
      if (checkedCityAllNum == this.areaInfoCount[provinceInfo[this.provinceIndex].value] && checkedRegionAllNum == this.areaInfoCount[cityInfo[this.cityIndex].value]) {
        this.conf_area.push(provinceInfo[this.provinceIndex].value)
      } else {
        cityInfo.forEach(function (cityItem) {
          if (true == cityItem.checked) that.conf_area.push(cityItem.value)
          cityItem.children.forEach(function (regionItem) {
            if (false == regionItem.checked) {
              searchIndex = that.conf_area.indexOf(cityItem.value)
              if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
            }
          })
        })
        if (checkedRegionAllNum != this.areaInfoCount[cityInfo[this.cityIndex].value]) {
          searchIndex = that.conf_area.indexOf(cityInfo[this.cityIndex].value)
          if (searchIndex > -1) that.conf_area.splice(searchIndex, 1)
          regionInfo.forEach(function (regionItem) {
            if (true == regionItem.checked) that.conf_area.push(regionItem.value)
          })

        }
      }

    },
    // 城市数据清理
    formatCityDataClear(conf) {
      let searchIndex
      this.areaInfo = JSON.parse(JSON.stringify(this.district))
      var cityChooseList = []
      for (var i in conf) {
        for (var j in conf[i].area) {
          cityChooseList.push(conf[i].area[j])
        }
      }

      for (var provinceKey = this.areaInfo.length - 1; provinceKey >= 0; provinceKey--) {
        searchIndex = cityChooseList.indexOf(this.areaInfo[provinceKey].value)
        Object.assign(this.areaInfo[provinceKey], {"checked": false})
        this.$set(this.areaInfo, provinceKey, this.areaInfo[provinceKey])
        if (searchIndex > -1) {
          this.areaInfo.splice(provinceKey, 1)
          continue
        }
        for (var cityKey = this.areaInfo[provinceKey].children.length - 1; cityKey >= 0; cityKey--) {
          searchIndex = cityChooseList.indexOf(this.areaInfo[provinceKey].children[cityKey].value)
          Object.assign(this.areaInfo[provinceKey].children[cityKey], {"checked": false})
          this.$set(this.areaInfo[provinceKey].children, cityKey, this.areaInfo[provinceKey].children[cityKey])
          if (searchIndex > -1) {
            this.areaInfo[provinceKey].children.splice(cityKey, 1)
            continue
          }
          for (var regionKey = this.areaInfo[provinceKey].children[cityKey].children.length - 1; regionKey >= 0; regionKey--) {
            searchIndex = cityChooseList.indexOf(this.areaInfo[provinceKey].children[cityKey].children[regionKey].value)
            Object.assign(this.areaInfo[provinceKey].children[cityKey].children[regionKey], {"checked": false})
            this.$set(this.areaInfo[provinceKey].children[cityKey].children, regionKey, this.areaInfo[provinceKey].children[cityKey].children[regionKey])
            if (searchIndex > -1) {
              this.areaInfo[provinceKey].children[cityKey].children.splice(regionKey, 1)
              continue
            }
          }
          if (0 == this.areaInfo[provinceKey].children[cityKey].children.length) {
            this.areaInfo.splice(cityKey, 1)
            continue
          }
        }
        if (0 == this.areaInfo[provinceKey].children.length) {
          this.areaInfo.splice(provinceKey, 1)
          continue
        }
      }
    },

    getAddress() {
      getAddress().then(res => {
        let that = this
        this.district = res.data.data

        this.district.forEach(function (provinceItem) {

          that.areaInfoCount[provinceItem.value] = provinceItem.children.length
          provinceItem.children.forEach(function (cityItem) {
            that.areaInfoCount[cityItem.value] = cityItem.children.length
          })
        })
        this.loading = false
      })
    },
    handleNumberNoPostEditArea() {
      this.formatCityDataEdit([], this.nopost_number_conf)
      this.conf_area = JSON.parse(JSON.stringify(this.nopost_number_conf))
      this.conf_name = 'nopost_number_conf'
      this.dialogAreaFormVisible = true
    },
    getItems(data) {
      this.ItemsList = []
      let items = []

      data.forEach(item => {
        var itemData = {
          'item_id': item.item_id,
          'goods_id': item.goods_id,
          'default_item_id': item.default_item_id,
          'item_name': item.item_name,
          'item_bn': item.item_bn,
          'item_spec_desc': item.item_spec_desc,
          'limit_num_type': this.limit_num_type,
          'is_tradein_open': this.is_tradein_open,
        }
        // if (this.limit_num_type === 'spu') { // 限购维度为spu时，只存储一条记录
        //   if (item.is_default == '1') {
        //     itemData.item_spec_desc = '全规格'
        //     items.push(itemData)
        //   }
        // } else {
        //   items.push(itemData)
        // }

        // this.ItemsList.push(item);
      })
      this.form.items = items
      this.getGoodsList()
    },
    oldbynewActivityGetInfo(id) {
      oldbynewActivityGetInfo({old_id: id}).then(response => {
        let res = response.data.data
        let data = {
          activity_name: res.activity_name,
          item_category: res.item_category,
          tag_ids: res.tag_ids,
          tag_list: res.tag_list,
          brand_ids: res.brand_ids,
          brand_list: res.brand_list,
          rel_brand_ids: res.rel_brand_ids,
          rel_category_ids: res.rel_category_ids,
          rel_tag_ids: res.rel_tag_ids,
          is_presale: res.is_presale === 1 ? true : false,

          is_tradein_open: res.is_tradein_open == "1" ? "1" : "0",
          price_show_type: res.price_show_type,
          subsidy_rule: res.subsidy_rule ? JSON.parse(res.subsidy_rule) : '',
          preheat_text: res.preheat_text,
          presale_text: res.presale_text,
          start_time:res.start_time,
          end_time:res.end_time,
          sale_text: res.sale_text,
          limit_num: res.limit_num,
          verification_mobile_number: res.verification_mobile_number === 1 ? true : false,
        }
        this.nopost_number_conf = res.nopost_number_conf
        data.preheat_time = [res.preheat_start_time, res.preheat_end_time]
        data.presale_time = [res.presale_start_time, res.presale_end_time]
        data.sale_time = res.sale_start_time
        this.limit_num_type = res.limit_num_type

        this.is_tradein_open = res.is_tradein_open

        Object.assign(this.form, data)

        this.relItems = response.data.data.itemTreeLists
        this.validGrade = response.data.data.valid_grade
        this.activity_date = [response.data.data.start_date, response.data.data.end_date]

        this.zdItemHidden = true
        this.categoryHidden = true
        this.tagHidden = true
        this.brandHidden = true
        if (response.data.data.use_bound == 1) {
          this.form.use_bound = 'goods'
          this.zdItemHidden = false
        }

        if (response.data.data.use_bound == 2) {
          this.form.use_bound = 'category'
          this.categoryHidden = false
          this.generateSku()
        }

        if (response.data.data.use_bound == 3) {
          this.form.use_bound = 'tag'
          this.tagHidden = false
          this.tag.currentTags = response.data.data.tag_list || []
          this.showTags()
          this.generateSku()
        }

        if (response.data.data.use_bound == 4) {
          this.form.use_bound = 'brand'
          this.brandHidden = false
          this.brand.currentBrands = response.data.data.brand_list || []
          this.showBrands()
          this.generateSku()
        }
      })
    },
    handleCancel() {
      this.$router.back(-1)
    },
    getListVipGrade() {
      listVipGrade().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          this.vipGrade = response.data.data
        }
      })
    },
    getGradeList() {
      getGradeList().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          var result = response.data.data
          if (result) {
            this.memberGrade = result
          }
        }
      })
    },
    itemTypeChange: function (val) {
      this.params.main_cat_id = ''
      this.params.tag_id = ''
      this.params.brand_id = ''
      this.zdItemHidden = true
      this.categoryHidden = true
      this.tagHidden = true
      this.brandHidden = true
      this.form.items = []
      this.invalidItemsList = []
      this.form.rel_item_ids = []
      this.form.itemTreeLists = []
      this.form.item_category = []
      this.form.item_category = []
      this.tag.currentTags = []
      if (val === "goods") {
        this.zdItemHidden = false
      } else if (val === "category") {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.categoryHidden = false
        this.form.item_category = []
      } else if (val === "tag") {
        this.tagHidden = false
        this.tag.currentTags = []
        this.showTags()
      } else if (val === "brand") {
        this.brandHidden = false
        this.brand.currentBrands = []
        this.showBrands()
      }
    },
    fetchMainCate: function () {
      getCategory({is_main_category: true}).then(response => {
        this.categoryList = response.data.data
      })
    },
    addItemTag: function () {
      this.tag.currentTags = []
      if (this.item_id.length) {
        this.showTags()
        this.tag.form.item_ids = this.item_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个商品!'
        })
      }
    },
    showTags: function () {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
    },
    tagRemove: function (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
      this.form.tag_ids = []
      let tagItems = []
      let tagInvalidItems = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
        let items = []

        this.ItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) items.push(i)
        })
        tagItems = items

        let invalidItems = []
        this.invalidItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) invalidItems.push(i)
        })
        tagInvalidItems = invalidItems
      })
      this.ItemsList = tagItems
      this.invalidItemsList = tagInvalidItems
      this.getItems(this.ItemsList)
    },
    tagAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
      this.params.tag_id = item.tag_id
      this.getGoodsList()
    },
    getAllTagLists: function () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
      })
    },
    // 获取品牌列表
    getBrandList: function (searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({attribute_name: item.attribute_name, attribute_id: item.attribute_id})
        }
        this.brand.list = list
      })
    },
    showBrands: function () {
      this.brand.brands = [...this.brand.list]
      this.brand.brands.forEach((item, index) => {
        let isInArr = this.brand.currentBrands.findIndex((n) => n.attribute_id == item.attribute_id)
        if (isInArr != -1) this.brand.brands.splice(index, 1)
      })
    },
    brandAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.brand.currentBrands.findIndex(n => n.attribute_id == item.attribute_id)
      if (isInArr == -1) {
        this.brand.currentBrands.push(item)
        this.brand.brands.splice(index, 1)
      }
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
      this.params.brand_id = item.attribute_id
      this.getGoodsList()
    },
    brandRemove: function (index) {
      let items = []
      this.ItemsList.forEach(item => {
        if (this.brand.currentBrands[index].attribute_id != item.brand_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (this.brand.currentBrands[index].attribute_id != i.brand_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)

      this.brand.brands.unshift(this.brand.currentBrands[index])
      this.brand.currentBrands.splice(index, 1)
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    getGoodsList: function () {
      let params = JSON.parse(JSON.stringify(this.params))
      if (this.$route.params.old_id) {
        params.activity_id = this.$route.params.old_id
      }
      params.items_goods = ''
      this.form.items.forEach(item=>{
        params.items_goods += item.item_id+','
      })


      params.secgetGoodsListkill_type = this.form.seckill_type
      params.start_time = params.activity_start_time = this.activity_date[0]
      params.end_time = params.activity_end_time = this.activity_date[1]
      if (params.is_gift == 'all') {
        this.$delete(params, 'is_gift')
      }

      oldbynewActivityGetItemsList(params).then(response => {
        let itemList = response.data.data.list.validItems.concat(this.ItemsList)
        this.invalidItemsList = []
        let invalidItemList = response.data.data.list.invalidItems.concat(this.invalidItemsList)
        let newItemList = []
        let invalidItems = []
        let obj = {}
        let obj1 = {}

        itemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj[item.item_id]) {
            newItemList.push(item)
            obj[item.item_id] = true
          }
        })
        invalidItemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj1[item.item_id]) {
            invalidItems.push(item)
            obj1[item.item_id] = true
          }
        })

        this.ItemsList = newItemList
        this.invalidItemsList = invalidItems

      })
    },
    categorySelect: function (node, instanceId) {
      this.params.main_cat_id = node.category_id
      this.getGoodsList()
    },
    categoryDeselect: function (node, instanceId) {
      let items;
      items = [];
      this.ItemsList.forEach((item, index) => {
        if (node.category_id != item.item_main_cat_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (node.category_id != i.item_main_cat_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)
    },
    generateSku() {
      let noSkuItem;
      noSkuItem = [];
      let response = []
      let goodsList = JSON.parse(JSON.stringify(this.relItems))
      goodsList.forEach(item => {
        if (!item.nospec && item.spec_items.length === 0) {
          noSkuItem.push(item.default_item_id)
        }
      })
      if (noSkuItem.length > 0) {
        let param = this.params
        param.item_id = noSkuItem
        getItemsList(this.params).then(res => {
          goodsList.forEach(item => {
            if (!item.nospec) {
              res.data.data.list.forEach(sku => {
                if (item.item_id === sku.default_item_id) {
                  item.spec_items.push(sku)
                }
              })
            }
          })
          goodsList.forEach(item => {
            if (!item.nospec) {
              response = [...response, ...item.spec_items]
            } else {
              response = [...response, item]
            }
          })
          this.ItemsList = response
          this.getItems(response)
        })
      } else {
        goodsList.forEach(item => {
          if (!item.nospec) {
            response = [...response, ...item.spec_items]
          } else {
            response = [...response, item]
          }
        })
        this.ItemsList = response
        this.getItems(response)
      }
    },
    /**
     * 下载模板
     * */
    uploadHandleTemplate() {
      let params = {file_type: 'marketing_goods', file_name: '商品模板'}
      exportUploadTemplate(params).then(response => {
        let {data} = response.data
        if (data.file) {
          var a = document.createElement('a')
          a.href = data.file
          a.download = data.name
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    /**
     * 上传模板
     * */
    uploadHandleChange(file, fileList) {

      let params = {isUploadFile: true, file_type: 'marketing_goods', file: file.raw}
      handleUploadFile(params).then(response => {
        this.$message({
          type: 'success',
          message: '上传成功'
        })

        let {data} = response.data

        if (data.fail.length > 0) {
          let str = data.fail.map(item => {
            return item.item_bn
          })

          setTimeout(() => {
            this.$message({
              showClose: true,
              message: `以下商品编号不存在：${str}`,
              type: 'error',
              duration: 100000
            })
          }, 1500)
        }
        if (data.succ.length <= 0) return
        this.relItems = data.succ
        let list = []
        data.succ.forEach(item => {
          if (!item.nospec) {
            list.push(Object.assign(item, {spec_items: []}))
          } else {
            list.push(item)
          }
        })
      })
    },

    /**
     * 切换sku/spu
     */
    changeLimitNumType(){
      // this.getItems(this.ItemsList)
      // if (this.limit_num_type === 'sku') {
      //   this.isSelectSku = true
      // } else {
      //   this.isSelectSku = false
      // }
    },

    changeTradeinOpen(){
      console.log("is_tradein_open",this.is_tradein_open)
    }
  },
  mounted() {
    this.getListVipGrade()
    this.getGradeList()

    if (this.$route.query.isshow) {
      this.hasSaveButton = false
    }

    if (this.$route.params.old_id) {
      this.oldbynewActivityGetInfo(this.$route.params.old_id)
    }
    this.fetchMainCate()
    this.getAllTagLists()
    this.getBrandList('', true)
    this.getAddress()
    if (this.$route.params.templatesId) {
      this.templatesId = this.$route.params.templatesId
      getShippingTemplatesInfo(this.templatesId).then(res => {
        if (res.data.data) {
          this.form.name = res.data.data.name
          this.form.is_free = res.data.data.is_free
          res.data.data.nopost_conf = JSON.parse(res.data.data.nopost_conf)
          if (this.form.is_free != 1) {
            this.form.valuation = res.data.data.valuation
            this.form.status = res.data.data.status ? '1' : '0'
            res.data.data.fee_conf = JSON.parse(res.data.data.fee_conf)
            res.data.data.free_conf = JSON.parse(res.data.data.free_conf)
            switch (res.data.data.valuation) {
              case '1':
                this.fee_conf = res.data.data.fee_conf
                this.free_conf = res.data.data.free_conf
                this.nopost_conf = res.data.data.nopost_conf
                break
              case '2':
                this.fee_number_conf = res.data.data.fee_conf
                this.free_number_conf = res.data.data.free_conf
                this.nopost_number_conf = res.data.data.nopost_conf
                break
              case '3':
                this.fee_money_conf = res.data.data.fee_conf
                this.nopost_money_conf = res.data.data.nopost_conf
                for (var item in this.fee_money_conf) {
                  for (var rules_item in this.fee_money_conf[item].rules) {
                    this.fee_money_conf[item].rules[rules_item].now = this.fee_money_conf[item].rules[rules_item].down
                    this.$set(this.fee_money_conf[item].rules, rules_item, this.fee_money_conf[item].rules[rules_item])
                  }
                }
                break
              case '4':
                this.fee_volume_conf = res.data.data.fee_conf
                this.free_volume_conf = res.data.data.free_conf
                this.nopost_volume_conf = res.data.data.nopost_conf
                break
            }
          } else {
            this.nopost_self_conf = res.data.data.nopost_conf
          }
        }
      })
    }
  }
}
</script>
