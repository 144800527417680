<template>
  <div>
    <el-tabs v-if="$route.path.indexOf('_editor') === -1">
<!--      <el-row :gutter="10">-->
<!--          <el-col :span="3">-->
            <el-button type="primary" icon="plus" @click="addPreferential">添加优惠活动</el-button>
<!--          </el-col>-->

<!--        <el-col>-->
          <el-select v-model="selectType" placeholder="请选择类型" clearable @change="getDataList()">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input placeholder="请输入优惠活动名称" v-model="name" style='width:400px'>
            <el-button slot="append" icon="el-icon-search" @click="dataSearch"></el-button>
          </el-input>
<!--        </el-col>-->

<!--      </el-row>-->

      <el-tabs type="border-card" v-model="params.platform" @tab-click="handleClick" >
        <el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
        <el-tab-pane label="抖音小程序" v-if="login_type === 'disti' && admin_type === 'order'" name="byte_mini"></el-tab-pane>
      <el-table :data="dataList" v-loading="loading" >
        <el-table-column prop="id" label="活动ID" width="90"></el-table-column>
        <el-table-column prop="activity_title" label="活动标题" width=""></el-table-column>
        <el-table-column prop="activity_subtitle" label="活动副标题" width=""></el-table-column>
        <el-table-column prop="activity_desc" label="活动描述" width=""></el-table-column>
        <el-table-column prop="type" label="类型" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 'service'">服务</el-tag>
            <el-tag v-if="scope.row.type == 'welfare'">福利</el-tag>
            <!--<el-tag v-if="scope.row.type == 'hotbuy'">热卖</el-tag>-->
            <el-tag v-if="scope.row.type == 'activity'">活动</el-tag>
            <el-tag v-if="scope.row.type == 'selling'">热卖</el-tag>
            <el-tag v-if="scope.row.type == 'ecPurchase'">企业员工购</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="page_name" label="小程序模板页面名称" width="150"></el-table-column>
        <el-table-column prop="create_time" label="开始时间" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.start_time}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="end_time" label="结束时间" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.end_time}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operating-icons">
              <i class="iconfont icon-edit1" @click="PreferentialEdit(scope.$index, scope.row)"></i>
              <i class="mark iconfont icon-trash-alt1" @click="PreferentialDelete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="params.pageSize"
        ></el-pagination>
      </div>
      </el-tabs>

    </el-tabs>
    <router-view></router-view>

  </div>
</template>

<script>
import {getPreferential, deletePreferential} from '../../../../api/promotions'
import {mapGetters} from "vuex";

export default {
  provide() {
      return {
        refresh: this.getDataList
      }
    },
  data() {
    return {
      name: '',
      loading: false,
      total_count: 0,
      dataList: [],
      params: {
        page: 1,
        pageSize: 20,
        platform: 'wxapp'
      },
      form: {
        id: '',
      },
      selectType: "",
      typeOptions: [
        {
          value: 'service',
          label: '服务',
        },{
          value: 'activity',
          label: '活动',
        },{
          value: 'selling',
          label: '热卖',
        },{
          value: 'welfare',
          label: '福利',
        },{
          value: 'ecPurchase',
          label: '企业员工购',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type',
      'admin_type'
    ])
  },

  methods: {
    handleClick (tab, event) {
      this.params.page = 1
      this.getDataList()
    },

    dataSearch() {
      this.params.name = this.name
      this.params.page = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      console.log(this.params)
      this.params.type = this.selectType
      getPreferential(this.params).then(response => {
        this.dataList = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.getDataList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getDataList()
    },

    addPreferential () {
       console.log(this.$router.push({path: this.matchHidePage('editor'), query: {platform: this.params.platform}}))
    },
    PreferentialEdit (index, row) {
      console.log(row)
      this.$router.push({path: this.matchHidePage('editor?id=') + row.id})
    },

    PreferentialDelete (index, row) {
        this.$confirm('此操作将删除该优惠活动, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletePreferential(row.id).then(response => {
            this.dataList.splice(index, 1)
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },

  },
  mounted() {
    // if (this.login_type === 'disti' && this.admin_type === 'order') {
    //   this.params.platform = 'byte_mini'
    // } else {
    //   this.params.platform = 'wxapp'
    // }
    this.getDataList()
  }
}
</script>
