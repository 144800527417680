<template>
  <div class="section section-white">
    <el-form ref="form" :model="form" label-position="left" label-width="180px">
      <div class="section-body">
        <el-form-item label="是否开启：" prop="ad_title">
          <el-switch v-model="form.is_open" :width="60" active-value="true" inactive-value="false" inactive-color="#ccc" active-text="开启" inactive-text="关闭" active-color="#13ce66"></el-switch>
        </el-form-item>
        <el-form-item label="注册引导广告标题：" prop="ad_title">
          <el-input v-model="form.ad_title" placeholder="用于门店小程序注册引导入口标题" style="width: 340px;" />
        </el-form-item>
        <el-form-item label="注册引导图片：">
          <div class="frm-tips">只能上传jpg/png文件，且不超过2M （建议尺寸：400px * 450px）</div>
          <div class="frm-tips">引导用户授权手机号注册，类似新用户专享广告图片</div>
          <div>
            <div @click="handleImgChange" class="upload-box">
              <img v-if="form.ad_pic" :src="wximageurl + form.ad_pic" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </div>
          <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
        </el-form-item>
        <el-form-item label="请选择赠送会员卡类型：">
          <el-radio-group v-model="membercards.index_value" @change="vipGradeChange">
            <el-radio :label="index" v-for="(item,index) in vipGrade" :key="index" @click="vipGradeClick(index,item)">{{item.grade_name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请选择赠送会员卡：" v-if="priceList.length > 0">
          <el-radio-group v-model="membercards.card_type">
            <el-radio :label="item.name" v-for="(item,index) in priceList" :key="index">{{item.desc}}({{item.price}}元)</el-radio>
          </el-radio-group>
        </el-form-item>

      </div>
      <div class="section-footer with-border content-center">
        <el-button type="primary" @click="save">保 存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
  import imgPicker from '../../../components/imageselect'
  import {  listVipGrade } from '../../../api/cardticket'
  import { saveRegisterPromotions, getRegisterPromotions,  } from '../../../api/promotions'
  export default {
    components: {
      imgPicker
    },
    props: ['getStatus'],
    data () {
      return {
        isGetImage: false,
        imgDialog: false,
        activeName: 'third',
        total_count: 0,
        vipGrade: [],
        priceList: [],
        form: {
          id: '',
          is_open: 'false',
          register_type: 'membercard',
          ad_title: '',
          ad_pic: '',
          promotions_value: {
            membercard: {
              vip_grade_id : '',
              card_type: '',
              index_value: 0,
            }
          },
        },
        membercards: {
          vip_grade_id : '',
          card_type: '',
          index_value: 0,
        }
      }
    },
    methods: {
      handleImgChange () {
        this.imgDialog = true
        this.isGetImage = true
      },
      pickImg (data) {
        this.form.ad_pic = data.url
        this.imgDialog = false
      },
      closeImgDialog () {
        this.imgDialog = false
      },
      save() {
        this.form.promotions_value.membercard = this.membercards
        saveRegisterPromotions(this.form).then(res => {
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
        })
      },
      getMemberVipGrade(){
        var params = {is_disabled: 'false'}
        listVipGrade(params).then(res => {
          this.vipGrade = res.data.data
          if (this.vipGrade.length > 0) {
            var item = this.vipGrade[this.membercards.index_value]
            this.membercards.vip_grade_id = item.vip_grade_id
            this.priceList = item.price_list
            this.membercards.card_type = this.priceList[0].name
          }
        })
      },
      vipGradeChange(index){
        var item = this.vipGrade[index]
        this.membercards.vip_grade_id = item.vip_grade_id
        this.priceList = item.price_list
        this.membercards.card_type = this.priceList[0].name
      },
      getRegisterData() {
        var params = {register_type: 'membercard'}
        getRegisterPromotions(params).then(response => {
          this.form.ad_pic = response.data.data.ad_pic
          this.form.id = response.data.data.id
          this.form.is_open = response.data.data.is_open
          this.form.ad_title = response.data.data.ad_title
          if(response.data.data.promotions_value && response.data.data.promotions_value.membercard) {
            this.membercards = response.data.data.promotions_value.membercard
            this.membercards.index_value = parseInt(this.membercards.index_value)
          }
        })
      }
    },
    mounted () {
      this.getRegisterData()
      this.getMemberVipGrade()
    },
    watch: {
      getStatus (newVal, oldVal) {
        if (newVal) {
          this.getRegisterData()
          this.getMemberVipGrade()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .upload-box {
    display: inline-block;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
  }
  .promotion-card {
    border: 1px solid #dcdfe6;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    &-header {
      position: relative;
      height: 40px;
      line-height: 40px;
      background: #f5f7fa;
      margin: 0;
      padding-left: 15px;
      border-bottom: 1px solid #ebeef5;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      color: #303133;
      font-weight: 400;
    }
    &-list {
      margin: 0;
      padding: 6px 0;
      list-style: none;
      height: 246px;
      overflow: auto;
      box-sizing: border-box;
      li {
        display: flex;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 36px;
        line-height: 30px;
        .promotion-name {
          flex: 1;
          padding-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .el-input-number--mini {
          width: 80px;
          height: 28px;
        }
        .remove-btn {
          display: none;
          position: absolute;
          top: 8px;
          right: 10px;
          cursor: pointer;
        }
        &:hover {
          background-color: #f8f8f8;
          .remove-btn {
            display: block;
          }
        }
      }
    }
    &-btn {
      position: absolute;
      right: 15px;
      font-size: 14px;
    }
  }
  .dialog-list li {
    width: 50%;
    float: left;
    padding-left: 30px;
    padding-right: 20px;
    line-height: 30px;
    cursor: pointer;
    &.checked {
      color: #ff5000;
    }
    &:hover {
      background: #f8f8f8;
    }
  }
</style>
<style type="text/css" lang="scss">
  .register-promotion {
    .el-transfer-panel {
      width: 270px;
    }
    .el-transfer-panel__body.is-with-footer {
      box-sizing: content-box;
    }
  }
</style>
