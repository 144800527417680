<template>
  <div>
    <el-tabs v-if="$route.path.indexOf('_editor') === -1">

      <el-row :gutter="24">
        <el-col :md="6" :lg="6">
          <el-input placeholder="请输入广告标题" v-model="search.name" size="mini">
            <el-button slot="append" icon="el-icon-search" @click="dataSearch" size="mini"></el-button>
          </el-input>
        </el-col>
        <el-col :md="6" :lg="6">
          <el-select v-model="search.orderad_type" placeholder="请选择广告类型" clearable @change="dataSearch()" size="mini">
            <el-option value="">请选择广告类型</el-option>
            <el-option
                v-for="(opts_val, opts_key) in opts.orderad_type"
                :key="opts_key"
                :label="opts_val"
                :value="opts_key">
              </el-option>
            </el-select>
        </el-col>
        <el-col :md="6" :lg="6">
          <el-date-picker v-model="between_time" type="daterange" value-format="yyyy/MM/dd"
            placeholder="根据添加时间筛选" @change="dateChange" size="mini" style="width: 100%;"></el-date-picker>
        </el-col>
        <el-col :md="6" :lg="6">
          <el-button type="primary" icon="plus" @click="addOrderAdData()" size="mini">创建广告</el-button>
        </el-col>
      </el-row>

      <el-table :data="dataList" v-loading="loading" >
        <!-- <el-table-column prop="id" label="ID" width="60"></el-table-column> -->
        <el-table-column prop="activity_title" label="广告标题"></el-table-column>
        <el-table-column prop="orderad_type" label="广告类型" width="80">
          <template slot-scope="scope">
            <label v-if="scope.row.items_arr.type == 'text'">文字</label>
            <label v-else>图片</label>
          </template>
        </el-table-column>
        <el-table-column prop="activity_cover" label="广告内容" width="180">
          <template slot-scope="scope">
            <img v-if="scope.row.items_arr.type == 'image'" :src="scope.row.activity_cover"  @click.self="showBigImage($event)" height="60" width="60" />
            <label v-else>{{ scope.row.items_arr.title }}</label>
          </template>
        </el-table-column>

        <el-table-column prop="page_name" label="展示页面" width="240" style="white-space: pre-line;">
          <template slot-scope="scope">
            <div v-html="orderadPageValue(scope.row)"></div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="type" label="广告位" width="70">
          <template slot-scope="scope">
            {{scope.row.items_arr.pos_no}}
          </template>
        </el-table-column> -->

        <el-table-column label="排序" width="80">
              <template slot-scope="scope">
                <el-input v-model="scope.row.items_arr.sort" @change="editItemsSort(scope.$index, scope.row)" ></el-input>
              </template>
        </el-table-column>


        <!-- <el-table-column prop="type" label="类型" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 'orderAD'">广告管理</el-tag> -->
            <!--<el-tag v-if="scope.row.type == 'hotbuy'">热卖</el-tag>-->
          <!-- </template>
        </el-table-column> -->

        <el-table-column prop="page_link" label="广告链接" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.items_arr.page_link">
              {{+scope.row.items_arr.page_link ? +scope.row.items_arr.page_link.customize_id : ''}} {{scope.row.items_arr.page_link.customize_title}}
            </span>
            <span v-else>
              {{+scope.row.items_arr.page_link ? +scope.row.items_arr.page_link.customize_id : ''}} {{scope.row.items_arr.customize_title}}
            </span>

          </template>
        </el-table-column>

        <!-- <el-table-column prop="created" label="创建时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.created | datetime('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column> -->
        <el-table-column prop="start_time" label="广告时间" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.start_time && scope.row.start_time != 0">
              {{ scope.row.start_time }}~{{ scope.row.end_time }}
            </span>
            <span class="red" v-if="scope.row.is_expire">已过期</span>
            <span class="red" v-if="scope.row.long_time">永久有效</span>
          </template>
        </el-table-column>


        <el-table-column prop="activity_subtitle" label="上架" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_expire ? 'F' : scope.row.activity_subtitle"
            active-value="T"
            inactive-value="F"
            :disabled="scope.row.is_expire"
            @change="handleIsShowChange(scope.row)"></el-switch>
            <!-- {{scope.row.activity_subtitle  }}
            {{typeof(scope.row.activity_subtitle)  }} -->
          </template>
        </el-table-column>


        <el-table-column label="操作" min-width="60px" max-width="150px">
          <template slot-scope="scope">
            <div>
              <el-button type="text" class="el-icon-edit" style="margin-left: 1px;"
              @click="editOrderAdData(scope.row)">编辑</el-button>
              <el-button type="text" class="el-icon-delete" style="margin-left: 5px;color: red;"
              @click="PreferentialDelete(scope.row)">删除</el-button>
              <ImportDistributor
                  v-if="!scope.row.is_expire"
                  buttonName="上传门店"
                  buttonType="text"
                  fileName="上传门店模版"
                  fileType="orderad_shop_update_pool"
                  :relId="scope.row.id"
                ></ImportDistributor>
                <el-button type="text"  @click="showDistributors(scope.row)">查看店铺</el-button>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="search.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="search.pageSize"
        ></el-pagination>
      </div>

      <el-dialog
        :title="dialogTitle"
        :visible.sync="page_dialog"
        :close-on-click-modal="false"
        :before-close="handleCancel"
      >
        <el-form v-model="pageForm" label-width="200px">
          <el-form-item label="广告标题">
            <el-input
              v-model="pageForm.activity_title"
              placeholder="广告标题"
              style="width: 55%"
            ></el-input>
          </el-form-item>

          <el-form-item label="展示页">
            <el-select v-model="pageForm.items_arr.page_type" placeholder="请选择">
              <el-option label="订单详情" value="order_detail">订单详情</el-option>
              <!--
              <el-option label="热卖" value= "selling">热卖</el-option>
              <el-option label="福利" value= "welfare">福利</el-option>
              -->
            </el-select>
            </br>
            </el-radio-group>

            <el-radio-group v-model="pageForm.items_arr.pos_no" size="mini" :disabled="is_edit_radio_adpos">
              <el-radio label="1">广告位1</el-radio>
              <!-- <el-radio label="2">广告位2</el-radio> -->
              <el-radio label="3">广告位3</el-radio>
            </el-radio-group>

          </el-form-item>



          <el-form-item label="广告图">
            <div @click="handleImgChange()" class="upload-box">
              <img
                v-if="pageForm.activity_cover"
                :src="wximageurl + pageForm.activity_cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
            <imgPicker
              :dialog-visible="imgDialog"
              :sc-status="isGetImage"
              @chooseImg="pickImg"
              @closeImgDialog="closeImgDialog"
            ></imgPicker>
          </el-form-item>

          <el-form-item  label="链接页面">

              <el-button size="medium" class="button-new-tag" @click="addCustomizeAction">+ 选择自定义页面 </el-button>
              <div class="goods-select"
              style=" width: 400px;text-align:left;
              margin-left: 10px;">{{pageForm.items_arr.customize_id}}  {{pageForm.items_arr.customize_title}}
              </div>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer content-center">
          <el-button type="primary" @click="onSubmit">确认保存</el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="template_dialog"
        width="80%"
        title="编辑页面"
        fullscreen
        lock-scroll
      >
        <shopDecoration
          :id="pageForm.id"
          @saved="closeDialog"
          usage="page"
          :template_name="template_name"
        />
      </el-dialog>


    </el-tabs>
    <router-view></router-view>

    <CustomizeSelect :store-visible="CustomizeVisible" :is-valid="isValid1" :rel-data-ids="customizeList" :get-status="CustomizeStatus" @chooseStore="CustomizeChooseAction" @closeStoreDialog="closeDialogAction1"></CustomizeSelect>

    <!-- //显示放大图片的组件 -->
    <imgPreview :visible="photoVisible" :url="bigImgUrl" @closeClick="()=>{photoVisible=false}"></imgPreview>

    <el-dialog
      :before-close="beforeCloseDistributorDialog"
      :title=dialogTitle
      :visible.sync="distributorDialogVisible"
      width="60%">
      <div v-loading="dialogLoading"  element-loading-text="数据加载中">
        <div  v-if="distributorList.length > 0">
          <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
          <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" @click="searchDistributorShopList"></el-button>
        </div>
        <div class="empty-box"  v-if="distributorList.length <= 0 ">
            <div style="height: 200px;">
              <i class="el-icon-menu" style="font-size: 160px;color: #ecebeb"></i>
            </div>
            <span style="color:#409EFF;font-size: 16px"><i class="el-icon-warning-outline"></i> 当前未上传平台订单的履约门店，即为不限门店</span>
            <br>
            <!-- <el-button type="primary" @click="dialogToUpload">导入适用门店<i class="el-icon-upload el-icon--right"></i></el-button> -->

        </div>
        <el-table
          v-if="distributorList.length > 0 "
          :data="distributorList"
          style="line-height: normal"
        >
          <template>
            <el-table-column label="门店名称" prop="name"></el-table-column>
            <el-table-column
              label="AppleID"
              prop="shop_code"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="dealer_name"
              label="所属经销商"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" min-width="60px" max-width="150px">
              <template slot-scope="scope">
                <div>
                  <el-button type="text" class="el-icon-delete" style="margin-left: 5px;color: red;"
                  @click="relPreferentialDelete(scope.row)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="content-padded content-center" v-if="distributorList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindStoreCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :total='totalStoreCount'
            :page-size="bindStorePagination.pageSize">
          </el-pagination>
        </div>
      </div>
    </el-dialog>

  </div>



</template>

<script>
import {getPreferential, deletePreferential} from '../../../../api/promotions'
import imgPicker from '../../../../components/imageselect'
import { mapGetters } from 'vuex'
import { getPreferentialInfo, createPreferential, updatePreferential, getCustomizeList} from '@/api/promotions'
import CustomizeSelect from '@/components/function/customizeSelect'
import { cloneDeep } from 'lodash'

import imgPreview from '@/components/element/imgPreview'
import ImportDistributor from '@/components/importDistributor'
import { getPreferentialShops,deletePreferentialRelShops } from '@/api/promotions'

export default {
  components: {
    imgPicker,
    CustomizeSelect,
    imgPreview,
    ImportDistributor
  },

  provide() {
      return {
        refresh: this.getDataList
      }
    },
  data() {
    return {
      name: '',
      loading: false,
      total_count: 0,
      dataList: [],
      params: {
        page: 1,
        pageSize: 10,
      },
      form: {
        id: '',
      },
      selectType: "orderAD",
      typeOptions: [
        {
          value: 'orderAD',
          label: '订单广告',
        },
        // {
        //   value: 'service',
        //   label: '服务',
        // },{
        //   value: 'activity',
        //   label: '活动',
        // },{
        //   value: 'selling',
        //   label: '热卖',
        // },{
        //   value: 'welfare',
        //   label: '福利',
        // },
      ],

      between_time: '',
      search: {
        name: '',
        type: 'orderAD',
        start_time: '',
        page: 1,
        pageSize: 10,
      },

      dialogTitle: '新增',
      template_dialog: false,
      page_dialog: false,

      config: {},
      is_edit_radio_adpos: false,

      pageForm: {
        id: '',
        activity_title: '',
        activity_subtitle: '',

        activity_cover: '',
        page_type: '',
        pos_no: '',
        page_description: '',
        page_share_title: '',
        page_share_desc: '',
        page_imageUrl: '',
        is_open: true,
        customize_info: [],
        customize_title: '',
        customize_id: '',
        page_name: '',
        page_url: 0,
        items_arr: [],

      },

      imgDialog: false,
      isGetImage: false,

      CustomizeStatus: false,
      CustomizeVisible: false,
      customize: '',
      isValid1: true,
      customizeList: [],

      previewImg: [],

      photoVisible: false,
      bigImgUrl: "",
      // 门店
      activity_id: 0,
      dialogLoading: true,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        pageSize: 10,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,

      opts: {
        orderad_type : {
          'text' : '文字广告',
          'image': '图片广告'
        },
        orderad_page: {
          text : {
            item_detail: {
              title : '商品详情',
              page : {
                'rotation' : '轮播提示栏',
                'courtesy' : '礼遇',
              }
            },
            order_confirm : {
              title : '订单确认页',
              page : {
                'item_gift_card' : '商品赠券区域'
              }
            },
            order_detail : {
              title : '订单详情页',
              page : {
                'order_gift_card' : '商品赠券区域'
              }
            },
            after_sales: {
              title: '售后维修',
              page : {
                'after_sales_card' : '广告位1'
              }
            },
            payment_success: {
              title : '支付成功页',
              page: {
                'courtesy' : '礼遇',
              }
            }
          },
          image : {
            item_detail: {
              title : '商品详情',
              page : {
                'item_info_top' : '详情介绍上方区域',
                'item_info_bottom' : '详情介绍底部区域'
              }
            },
            order_detail : {
              title : '订单详情页',
              page : {
                'order_info' : '订单信息和寻求帮助中间',
                'order_foot' : '页面最底部'
              }
            },
            items_category : {
              title : '分类产品页',
              page : {
                'shelf_bot' : '分类页货架底部',
                'page_bot' : '分类页底部',
                'shelf_top_bot' : '分类页顶部'
              }
            },
            home_page : {
              title : '首页',
              page : {
                'home_open_ad' : '首页开屏广告'
              }
            },
            payment_success: {
              title : '支付成功页',
              page: {
                'recommend_foot' : '为你推荐与专属服务之间',
                'exclusive' : '页面最底部'
              }
            }
          }
        }
      },

    }
  },

  methods: {
    dateChange (val) {
      if ( val && val.length > 0 ) {
        this.search.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.search.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.search.start_time = 0
        this.search.end_time = 0
      }
      this.dataSearch()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getOptsValue (row, column, cellValue) {
      var val = '';
      if(column.property) {
        if (this.opts[column.property] != undefined && this.opts[column.property][cellValue]) {
          val = this.opts[column.property][cellValue];
        }
      }
      return val;
    },
    orderadPageValue(item) {
      const row = cloneDeep(item)
      if ( row.items_arr.type ) {
        let pageConfig = this.opts.orderad_page[row.items_arr.type];
        let pageArr = Object.keys(pageConfig);
        let posArr = {};let pageText = '';let posText = '';
        var itemPos = [];
        if ( row.items_arr.page_arr == undefined || row.items_arr.page_pos == undefined ) {
          return '';
        }
        // 判断是否为对象
        if ( Object.prototype.toString.call(row.items_arr.page_pos) == '[object Object]' ) {
          row.items_arr.page_pos = Object.values(row.items_arr.page_pos);
        }
        pageArr.forEach(item => {
          if ( row.items_arr.page_arr.indexOf(item) > -1 ) {
            pageText += pageConfig[item]['title'] + ':';
            posArr = Object.keys(pageConfig[item]['page']);
            posText = '';
            posArr.forEach(pos => {
              if ( row.items_arr.page_pos.indexOf(pos) > -1 ) {
                if ( posText ) {
                  posText += '、' + pageConfig[item]['page'][pos];
                } else {
                  posText += pageConfig[item]['page'][pos];
                }
              }
            });
            if (row.items_arr.page_reserve && row.items_arr.page_reserve[item] && row.items_arr.page_reserve[item] === 'true') {
              posText += '<span class="red">*永久保留广告</span>'
            }
            pageText += posText + "\r\n";
          }
        });
        return pageText;
      }
    },
    addOrderAdData () {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editOrderAdData (row) {
      this.$router.push({path: this.matchHidePage('editor'), query: { id: row.id }});
    },
    getCustomize(ids) {
      let param = {id: ids}
      getCustomizeList(param).then( res => {
        this.customizeList = res.data.data.list
      })
    },


    dataSearch() {
      this.search.page = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      getPreferential(this.search).then(response => {
        this.dataList = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    handleCurrentChange(page_num) {
      this.search.page = page_num
      this.getDataList()
    },
    handleSizeChange(pageSize) {
      this.search.page = 1
      this.search.pageSize = pageSize
      this.getDataList()
    },

    addPreferential () {
       console.log(this.$router.push({path: this.matchHidePage('editor')}))
    },
    PreferentialEdit (index, row) {
      console.log(row)
      this.$router.push({path: this.matchHidePage('editor?id=') + row.id})
    },

    PreferentialDelete (row) {
        var that = this;
        this.$confirm('此操作将删除该广告配置, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          deletePreferential(row.id).then(response => {
            this.$message({
              message: '广告配置删除成功',
              type: 'success',
              duration: 2 * 1000
            });
            loading.close();
            that.dataSearch();
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },

      temDialog (id, type) {
        this.pageForm.id = id
        this.template_dialog = true
      },

      closeDialog () {
        console.log("template_dialog---template_dialog---template_dialog-----closeDialog false")
        this.template_dialog = false
        console.log("page_dialog---page_dialog---page_dialog-----closeDialog false")

        this.page_dialog = false

      },

      closeImgDialog () {
      this.imgDialog = false
      },

      openDialog (detail = null) {
        console.log("detail:::::::::", detail)
        if (detail) {
          this.pageForm = detail
          if (detail.is_open == 1) {
            this.pageForm.is_open = true
          }
          if (detail.sync_avarto == 1) {
            this.pageForm.sync_avarto = true
          }
          this.dialogTitle = '编辑'
        } else {
          this.dialogTitle = '新增'
          this.pageForm = {
            id: '',
            items_arr: [],
            activity_title: '',
            page_description: '',
            page_share_title: '',
            page_share_desc: '',
            activity_cover: '',
            page_type: '',
            pos_no: '',
            page_name: '',
            page_url: '',
            page_imageUrl: '',
            is_open: true,
            sync_avarto: false
          }
        }

        this.page_dialog = true

    },

    handleCancel () {
      this.page_dialog = false
    },

    pickImg (data) {
      this.pageForm.activity_cover = data.url
      this.imgDialog = false
    },

    // 上传卡封面
    handleImgChange () {
      this.imgDialog = true
      this.isGetImage = true
    },


    editItemsSort (index, row) {

      var reg = /(^[1-9]?$)/
      if (row.items_arr.sort == '') {
        this.$message.error('不能为空');
          return;
        // callback(new Error('不能为空'))
      } else if (!reg.test(row.items_arr.sort)) {
        this.$message.error('必须为1-9之间的数字');
          return;
        // callback(new Error('必须为1-9之间的数字'))
      }

      console.log("editItemsSort------------------row:", row)
      let param = {
            id: row.id,
            type: row.type,
            items: row.items_arr,
          }

        updatePreferential (row.id, param).then(response => {
        const that = this
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 3 * 1000,
          onClose () {
            // this.closeDialogAction1()
            that.closeDialog()
            that.dataSearch()
            // this.$router.go(-1)
          }
        })
      })

      },



    handleIsShowChange (row)  {
      console.log("handleIsShowChange row:", row)
      console.log("handleIsShowChange row.id:", row.id)
      console.log("handleIsShowChange row.activity_subtitle:", row.activity_subtitle)

      let param = {
            id: row.id,
            type: row.type,
            activity_subtitle: row.activity_subtitle,
          }

      updatePreferential (row.id, param).then(response => {
        const that = this
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 3 * 1000,
          onClose () {
            // this.closeDialogAction1()
            that.closeDialog()
            that.getDataList()
            // that.dataSearch()
            // this.$router.go(-1)
          }
        })
      })
    },

    onSubmit () {
        console.log("onSubmit::this.pageForm:::::::::::::::",this.pageForm)

        this.form.distributor_ids = []
        if (this.customizeList.length > 0) {
          this.pageForm.customize_id = this.pageForm.customizeinfo
        }

        if (!this.pageForm.activity_title || this.pageForm.activity_title.trim() == '' ) {
          this.$message.error('请填写实验名称');
          return;
        }

        let items_page = {
          page_type: this.pageForm.items_arr.page_type,
          page_name: this.pageForm.items_arr.page_type,
          pos_no: this.pageForm.items_arr.pos_no,
          customize_title: this.pageForm.items_arr.customize_title,
          customize_id: this.pageForm.items_arr.customize_id,
          activity_cover: this.pageForm.activity_cover,
        }
        let param = {}
        param = {
            distributor_ids : this.pageForm.distributor_ids | [],
            activity_title: this.pageForm.activity_title,
            activity_subtitle: this.pageForm.activity_subtitle,
            customize_id: this.pageForm.items_arr.customize_id,
            activity_cover: this.pageForm.activity_cover,
            type: "orderAD",
            items: items_page,//this.pageForm.customize_info,
            page_name: this.pageForm.page_type,
            page_url: this.pageForm.pos_no,

            start_time: this.pageForm.start_time | 0,
            end_time: this.pageForm.end_time | 0,
            city_list: this.form.city_list | '',
            rel_city: this.form.rel_city | '',
          }
          console.log("onsubmit edit-----------this.pageForm.id:",this.pageForm.id)
          console.log("pageForm.items_arr----------:",this.pageForm.items_arr)

          console.log("onsubmit edit-----------this.pageForm.customize_info:",this.pageForm.customize_info)

          console.log("onsubmit edit-----------params:",param)
        if (this.pageForm.id) {
          param.items.sort = this.pageForm.items_arr.sort,

          updatePreferential (this.pageForm.id, param).then(response => {
            const that = this
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 2 * 1000,
              onClose () {
                that.closeDialog()
                that.dataSearch()
              }
            })
          })
        } else {
          param.activity_subtitle = ""
          param.items.sort = 1

          createPreferential (param).then(res => {
            const that = this
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 2 * 1000,
              onClose () {
                that.closeDialog()
                that.dataSearch()
                // that.$router.go(-1)
              }
            })
          })
        }
      },

      refresh () {
        closeDialogAction1()
        this.getDataList()
      },

      handleClose1(index) {
        this.customizeList.splice(index, 1);
        this.form.customize_id = 0;
      },
      closeDialogAction1 () {
        this.CustomizeStatus = false
        this.CustomizeVisible = false
      },

      addCustomizeAction() {
        this.CustomizeStatus = true
        this.CustomizeVisible = true
      },
      CustomizeChooseAction (data) {
        this.CustomizeVisible = false
        this.CustomizeStatus = false
        if (data === null || data.length <= 0) return
        console.log("CustomizeChooseAction------------------data:", data)
        this.pageForm.customize_info  = data[0]
        this.pageForm.items_arr.customize_id    =  this.pageForm.customize_info.id
        this.pageForm.items_arr.customize_title =  this.pageForm.customize_info.page_name
      },

      changePage(data) {
      if (data !== "goods") {
        this.config.leaderboard = false;
      } else {
        this.seckill = [];
        this.limitTimeSale = [];
      }
      this.data.splice(0);
      this.config.seckillId = "";
      this.config.type = data;
    },

    showBigImage(e) {//点击图片函数，点击后，把photoVisible设置成true
      if (e != "") {
        this.photoVisible = true;
        this.bigImgUrl = e.currentTarget.src;
      }
    },

    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.getDistributorShopList()
    },
    searchDistributorShopList() {
      this.bindStorePagination.page = 1
      this.getDistributorShopList()
    },
    async getDistributorShopList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getPreferentialShops(this.activity_id, this.bindStorePagination).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
      this.dialogLoading = false
    },
    showDistributors(row) {
      this.dialogLoading = true
      this.activity_id = row.id
      this.distributorDialogVisible = true
      this.dialogTitle = '活动（' + row.activity_title + '）- 适用店铺列表'
      this.dialogId = row.activity_id
      this.getDistributorShopList();// 获取门店列表
    },
    beforeCloseDistributorDialog(){
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
    },
    relPreferentialDelete(row) {
      console.log('del-row: ',row);
      let that = this;
      this.$confirm('此操作将删除该优惠活动关联的门店, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        deletePreferentialRelShops(row.id).then((response) => {
          this.$message({
            message: '关联门店删除成功',
            type: 'success',
            duration: 2 * 1000
          });
          loading.close()
          that.searchDistributorShopList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  },

  computed: {
    ...mapGetters(['wheight', 'template_name', 'login_type'])
  },

  mounted() {
    this.is_edit_radio_adpos = false

    // this.getDataList()
    this.dataSearch()
  }
}
</script>
