<template>
  <div>
    <div>
      <shop-select distributors  @update="storeSearch" :shopIdDefault="params.distributor_id"></shop-select>
      <el-input
        class="input-m"
        v-model="params.shop_code"
        placeholder="AppleID">
      </el-input>
      <el-input
        clearable
        class="input-m"
        v-model="params.activity_title"
        placeholder="活动名称">
      </el-input>
      <el-input
        clearable
        class="input-m"
        v-model="params.activity_id"
        placeholder="活动编号">
      </el-input>
      <el-input
        clearable
        class="input-m"
        v-model="params.dealer_name"
        placeholder="经销商名称">
      </el-input>
      <el-input
        clearable
        class="input-m"
        v-model="params.dealer_hqid"
        placeholder="经销商HQID">
      </el-input>
      <el-button icon="el-icon-search" circle @click="searchList" type="primary"></el-button>
    </div>

    <div style="margin-top: 20px">
      <ImportDialog
        v-if="login_type === 'disti'"
        buttonName="上传名额"
        buttonType="primary"
        fileName="上传店铺活动名额模版"
        fileType="offline_activity_distributors_quota"
        :relId="0"
      ></ImportDialog>

      <el-button type="primary" @click="showExportDialog">导出</el-button>
      <el-popover
        placement="top-start"
        width="200"
        trigger="hover"
        content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
        <i class="el-icon-question" slot="reference"></i>
      </el-popover>
    </div>

    <div style="margin-top: 20px">
      <el-tabs type="border-card" v-model="params.status" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="进行中" name="going"></el-tab-pane>
        <el-tab-pane label="已结束" name="stopped"></el-tab-pane>
        <el-table
          v-loading.fullscreen.lock="listLoading"
          :data="activityList"
          stripe
          style="width: 100%">
          <el-table-column
            prop="activityInfo.activity_id"
            label="活动编号"
            width="80">
          </el-table-column>
          <el-table-column
            prop="activityInfo.activity_title"
            label="活动标题">
          </el-table-column>
          <el-table-column
            prop="distributor_name"
            label="店铺名称">
            <template slot-scope="scope">
              <div>{{scope.row.distributorInfo.distributor_name}}</div>
              <div>{{scope.row.distributorInfo.shop_code}}</div>
            </template>
          </el-table-column>
          <el-table-column
            width="180px"
            prop="activityInfo.sign_up_start_time"
            label="报名时间">
            <template slot-scope="scope">
              <div>{{scope.row.activityInfo.sign_up_start_time | datetime("yyyy-MM-dd hh:mm:ss")}}~</div>
              <div>{{scope.row.activityInfo.sign_up_end_time | datetime("yyyy-MM-dd hh:mm:ss")}}</div>
              <div style="color: red" v-if="scope.row.is_end == 1">已结束</div>
            </template>
          </el-table-column>
          <el-table-column
            width="100px"
            prop="is_open"
            label="活动状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_end == 1 && scope.row.is_open !== '0'" type="warning">已结束</el-tag>
              <el-tag v-else-if="scope.row.is_open === '1'" type="success">已开启</el-tag>
              <el-tag v-if="scope.row.is_open === '0'" type="danger">已关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            width="100px"
            prop="status"
            label="参与状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_end == 1" type="warning">已结束</el-tag>
              <el-tag v-else-if="scope.row.status === '1'" type="success">正在参与</el-tag>
              <el-tag v-if="scope.row.status === '2'" type="danger">停止参与</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            label="已报名数量"
            width="90px">
            <template slot-scope="scope">
              <span>{{scope.row.used_quota}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="signed_in_count"
            label="已签到数量"
            width="90px">
          </el-table-column>
          <el-table-column
            prop=""
            label="可报名数量"
            width="90px">
            <template slot-scope="scope">
              <span>{{scope.row.total_quota - scope.row.used_quota}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="join_type"
            label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                round
                size="mini"
                @click="handleEditQuota(scope.row)"
              >
                管理名额
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.page_size">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-drawer
      title="管理名额"
      v-loading="quotaLoading"
      :visible.sync="drawerVisible"
      size="50%"
      :before-close="beforeCloseQuota"
      direction="rtl">
      <div style="padding: 20px">
        <el-card v-for="(session, sessionIndex) in sessions" :key="sessionIndex">
          <div slot="header" class="clearfix">
            <span v-if="activityRow.activityInfo.join_type === 'appointment'">{{session.session_time}}</span>
            <span v-else>现场报名</span>

            <el-tag type="danger" v-if="session.session_end == 1" size="mini">已过期</el-tag>
            <el-tag type="danger" v-if="activityRow.is_open == 0" size="mini">活动已关闭</el-tag>
            <el-tag type="danger" v-if="activityRow.is_end == 1" size="mini">活动已结束</el-tag>
          </div>
          <el-table
            :data="session.session_names"
          >
            <el-table-column
              prop="session_name"
              label="场次名称">
              <template slot-scope="scope">
                <span v-if="activityRow.activityInfo.join_type === 'appointment'">{{scope.row.session_name}}</span>
                <span v-else>现场报名</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="total_quota"
              label="总名额">
            </el-table-column>
            <el-table-column
              prop="used_quota"
              label="已报名">
            </el-table-column>
            <el-table-column
              prop="signed_in_count"
              label="已签到">
            </el-table-column>
            <el-table-column
              prop="surplus_quota"
              label="剩余名额">
              <template slot-scope="scope">
                <span>{{scope.row.surplus_quota}}</span>
                <span>
                  <el-popover
                    v-if="
                      login_type === 'disti'
                      && activityRow.is_open == '1'
                      && session.session_end == 0
                      && activityRow.is_end == 0"
                    placement="bottom"
                    width="300"
                    trigger="click"
                    v-model="scope.row.storePop"
                  >
                    <div
                      ref="store"
                      class="store-pop"
                    >
                      <div class="store-content">
                        <el-radio-group
                          v-model="editSessionInfo.operate_type"
                          class="content-bottom-padded"
                        >
                          <el-radio label="increase">增加</el-radio>
                          <el-radio label="reduce">减少</el-radio>
                        </el-radio-group>
                        <el-row>
                          <el-col :span="22">
                            <el-input
                              placeholder="名额总数不能少于已报名数"
                              v-model="editSessionInfo.number"
                              el-input-number
                              min="1"
                            ></el-input>
                          </el-col>
                        </el-row>
                      </div>
                      <el-row :gutter="10">
                        <el-col :span="12">
                          <el-button
                            :loading="editBtnLoading"
                            type="primary"
                            style="width: 100%"
                            @click="saveStore(scope.row)"
                          >确定</el-button>
                        </el-col>
                        <el-col :span="12">
                          <el-button
                            style="width: 100%"
                            @click="scope.row.storePop = false"
                          >取消</el-button>
                        </el-col>
                      </el-row>
                    </div>
                    <el-button
                      slot="reference"
                      type="text"
                    ><i class="el-icon-edit"></i></el-button>
                  </el-popover>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <div>
<!--          <el-button type=primary class="center" @click="saveSessionsQuota">确认</el-button>-->
        </div>
      </div>
    </el-drawer>
    <el-dialog width="450px" title="请选择场次日期" :visible="exportDialog" @close="closeExportDialog" append-to-body destroy-on-close >
      <el-date-picker
        v-model="session_date"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
        :default-time="['00:00:00', '23:59:59']"
      />
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeExportDialog">取 消</el-button>
      <el-button type="primary" @click="exportData">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import shopSelect from "@/components/shopSelect";
import ImportDialog from '@/components/importDialog'
import {
  getDistributorOfflineActivityList,
  getDistributorOfflineActivitySessionQuota,
  updateDistributorSessionQuota,
} from '@/api/promotions'
import {updateStore} from "../../../../api/cardticket";

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    shopSelect,
    ImportDialog,
  },
  data () {
    return {
      total_count: 0,
      editBtnLoading: false,
      listLoading: false,
      quotaLoading: false,
      activityList: [],
      drawerVisible: false,
      params: {
        page: 1,
        page_size: 20,
        activity_id: '',
        distributor_id: "",
        dealer_name: '',
        dealer_hqid: '',
        activity_title: '',
        shop_code: '',
        status: 'all'
      },
      sessions: [],
      activityRow: {},
      editSessionInfo: {
        id: 0,
        operate_type: 'increase',
        number: 1,
      },
      exportDialog: false,
      session_date: [],
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      "login_type"
    ])
  },
  methods: {
    handleEditQuota(row) {
      this.activityRow = row
      this.listLoading = true
      this.drawerVisible = true
      let params = {
        activity_id: row.activity_id,
        distributor_id: row.distributor_id,
      }
      getDistributorOfflineActivitySessionQuota(params).then(response => {
        let res = response.data.data
        this.sessions = res;
        this.listLoading = false
      }).catch(error => {
        this.sessions = []
        this.listLoading = false
      })
    },
    editQuota(row) {
      this.editSessionInfo.id = row.id
    },
    saveSessionsQuota() {

    },
    saveStore(row) {
      this.listLoading = true
      let reg = /^[1-9]\d*$/
      if (!reg.test(this.editSessionInfo.number)) {
        this.$message({
          message: '请输入大于0的正整数',
          type: 'error'
        })
        this.listLoading = false
        return
      }
      let param = {
        operate_type: this.editSessionInfo.operate_type,
        activity_id: this.activityRow.activityInfo.activity_id,
        distributor_id: this.activityRow.distributor_id,
        number: this.editSessionInfo.number,
        session_id: row.session_id,
      }
      updateDistributorSessionQuota(param).then(response => {
        if (response.data.data.success) {
          this.$message.success('更新成功');
        } else {
          this.$message.success('更新失败');
        }
        this.handleEditQuota(this.activityRow)
        this.listLoading = false
      }) . catch(error => {
        this.listLoading = false
      })
    },
    searchList() {
      this.params.page = 1;
      this.getList();
    },
    getList() {
      this.listLoading = true
      let params = JSON.parse(JSON.stringify(this.params))
      getDistributorOfflineActivityList(params).then(response => {
        let res = response.data.data
        this.activityList = res.list
        this.total_count = res.total_count
        this.listLoading = false
      }).catch(error => {
        this.listLoading = false
        this.activityList = []
      })
    },
    storeSearch(val) {
      val && val.shop_id;
      this.params.distributor_id = val.shop_id;
      this.params.page = 1;
      if (!val.shop_id) {
        this.activityList = []
        return
      }
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getList()
    },
    beforeCloseQuota () {
      this.getList()
      this.drawerVisible = false
    },
    handleClick() {
      this.getList()
    },
    exportData() {
      this.listLoading = true
      let params = JSON.parse(JSON.stringify(this.params))
      params.is_export = 1
      if (!this.session_date || this.session_date.length == 0) {
        this.listLoading = false
        return this.$message.error('请选择时间范围')
      }
      params.session_date = this.session_date
      getDistributorOfflineActivityList(params).then(response => {
        if (response.data.data.success) {
          this.listLoading = false
          this.$message.success('导出成功')
          this.closeExportDialog()
        }
      }).catch(error => {
        this.listLoading = false
      })
    },
    showExportDialog() {
      this.exportDialog = true
    },
    closeExportDialog () {
      this.exportDialog = false
      this.session_date = []
    },
  },
  mounted () {
  },
  watch: {
  },
}
</script>

<style>
  .store-content{
    margin-bottom: 15px;
  }
</style>
