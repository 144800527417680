<template>
  <div ref="reference" class="sp-store-dialog">
    <el-dialog title="店铺" :visible.sync="visible" width="70%" :close-on-click-modal="false" :before-close="cancelAction">
      <SpFilterForm :model="formData" @onSearch="onSearch" @onReset="onSearch">
        <SpFilterFormItem prop="distributor_name">
          <el-input v-model="formData.distributor_name" placeholder="请输入店铺名称" />
        </SpFilterFormItem>
        <SpFilterFormItem prop="shop_code">
          <el-input v-model="formData.shop_code" placeholder="AppleId" />
        </SpFilterFormItem>
        <SpFilterFormItem prop="status">
          <el-select v-model="formData.filter_status" clearable placeholder="参与状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              size="mini"
              :value="item.value"
            />
          </el-select>
        </SpFilterFormItem>
        <SpFilterFormItem prop="preheat_type">
          <el-select v-model="formData.filter_preheat_type" clearable placeholder="活动类型">
            <el-option
              v-for="item in preheatTypeList"
              :key="item.value"
              :label="item.label"
              size="mini"
              :value="item.value"
            />
          </el-select>
        </SpFilterFormItem>
      </SpFilterForm>
      <SpFinder
        ref="finder"
        :setting="setting"
        row-key="id"
        row-actions-width="200"
        :row-actions-align="'left'"
        :fixed-row-action="true"
        reserve-selection
        :url="url"
        :hooks="{
          beforeSearch: beforeSearch,
          afterSearch: afterSearch
        }"
      >
        <template slot="tableTop">
          <el-button type="primary" style="margin-bottom: 20px;" @click="onExportChange">导出</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </template>
      </SpFinder>
    </el-dialog>
  </div>
</template>

<script>
import { createSetting } from '@shopex/finder'
import { setPresaleStop, getPresaleStoreList, editPresaleStatus } from '@/api/promotions'

export default {
  name: 'StoreDialog',
  props: {
    visible: Boolean,
    url: String,
    params: Object
  },
  data() {
    return {
      formData: {
        distributor_name: '',
        shop_code: '',
        filter_status: '',
        filter_preheat_type: ''
      },
      statusList: [
        { label: '正在参与', value: '1' },
        { label: '停止参与', value: '2' }

      ],
      preheatTypeList: [
        { label: '全流程', value: '1' },
        { label: '仅预热', value: '2' }
      ],
      setting: createSetting({
        columns: [
          { name: 'ID', key: 'id' },
          { name: 'AppleId', key: 'shop_code' },
          { name: '名称', key: 'distributor_name' },
          { name: '地址', key: 'address' },
          {
            name: '活动类型',
            key: 'preheat_type',
            render: (h, { row }) => {
              return (
                <el-select v-model={row.preheat_type} on-change={(value) => this.onSelectChange(value, row)}>
                  <el-option label='全流程' size="mini" value="1" />
                  <el-option label='仅预热' size="mini" value="0" />
                </el-select>
              )
            }
          },
          {
            name: '状态',
            key: 'status',
            render: (h, { row }) => {
              return <span>{row.status == 1 ? '正在参与' : '停止参与'}</span>
            }
          },
        ],
        // actions: [{
        //   name: '结束参与',
        //   key: 'apply',
        //   type: 'button',
        //   buttonType: 'text',
        //   visible: (row) => {
        //     return row.status == 1 && this.params.status_desc != 'invalid'
        //   },
        //   action: {
        //     handler: async ([row]) => {
        //       this.onStopChange(row)
        //     }
        //   }
        // }]
      })
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.$refs.finder) {
          this.$refs.finder.refresh(true)
        }
      }
    }
  },
  methods: {
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    beforeSearch(params) {
      console.log(this.formData)
      if (this.formData.distributor_name) {
        params.distributor_name = this.formData.distributor_name
      }
      if (this.formData.shop_code) {
        params.shop_code = this.formData.shop_code
      }
      if (this.formData.filter_status) {
        params.filter_status = this.formData.filter_status
      }
      if (this.formData.filter_preheat_type) {
        params.filter_preheat_type = this.formData.filter_preheat_type
      }
      if (this.params.activity_id) {
        params.activity_id = this.params.activity_id
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      return data
    },
    cancelAction () {
      this.formData = {
        distributor_name: '',
        shop_code: '',
        filter_status: '',
        filter_preheat_type: ''
      }
      this.$emit('onCloseDialog', false)
    },
    onStopChange (row) {
      console.log(row, '----')
      this.$confirm(`确认结束？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const params = {
          activity_id: row.activity_id,
          distributor_id: row.distributor_id,
          status: 0,
        }
        editPresaleStatus(params).then(res => {
          this.$message.success('操作成功!')
          this.$refs.finder.refresh()
        }).catch(err => {
          this.$refs.finder.refresh()
        })
      })
    },
    onReset () {
      this.formData = {
        distributor_name: '',
        shop_code: '',
        filter_status: '',
        filter_preheat_type: ''
      }
      this.$refs.finder.refresh(true)
    },
    onExportChange () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = {
        activity_id: this.params.activity_id,
        ...this.formData,
        is_export: 1
      }
      getPresaleStoreList(params).then(response => {
        loading.close()
        if (response.data.data.success) {
          this.$message.success('导出成功')
        }
      }) .catch (error => {
        loading.close()
      })
    },
    async onSelectChange (value, row) {
      const params = {
        activity_id: this.params.activity_id,
        distributor_id: row.distributor_id,
        preheat_type: value,
      }
      await editPresaleStatus(params).then(res => {
        this.$message.success('修改成功！')
      }).catch(err => {
        this.$refs.finder.refresh()
      })
    }
  }
}
</script>
<style lang="scss">
</style>
