<template>
  <div>
    <el-form ref="form" :model="form" class="box-set" label-width="120px" v-loading="loading">
      <el-card header="增加视频数据" shadow="naver">
        <el-form-item label="* 视频名称" prop="title">
          <el-col :span="20">
            <el-input v-model="form.title" :maxlength=30 placeholder="视频名称"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="视频别名" prop="alias_name">
          <el-col :span="20">
            <el-input v-model="form.alias_name" :maxlength=30 placeholder="视频别名"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="视频描述" prop="material_desc">
          <el-input type="textarea" v-model="form.material_desc" :maxlength=30 placeholder="视频描述"></el-input>
        </el-form-item>

        <el-form-item label="开启同步客服">
          <el-switch v-model="form.can_push"></el-switch>
        </el-form-item>

        <el-form-item label="是否上架">
          <el-switch v-model="form.status"></el-switch>
        </el-form-item>

        <el-form-item label="* 视频素材" prop="material_id" class="video-html-fix">
          <video  v-if="form.page_link.customize_id > 0" class="video-html" :src="form.page_link.material_url" controls="controls" controlslist="nodownload">
            您的浏览器不支持 video 标签。
          </video>
          <el-row>
            <el-col :span="4">
              <el-button :disabled="canEdit" size="medium" type="primary" plain @click="addCustomizeAction">选择视频素材 </el-button>
            </el-col>
          </el-row>
          <div class="goods-select"
               style=" width: 400px;text-align:left;
              margin-left: 10px;" v-if="form.page_link.customize_id > 0">{{form.page_link.material_name}}
          </div>

        </el-form-item>

        <el-form-item label="视频封面图">
          <div>
            <div @click="handleHomeImgChange" class="upload-box">
              <img v-if="form.cover_pic" :src="wximageurl + form.cover_pic" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </div>
          <imgPicker :dialog-visible="homeimgDialog" :sc-status="isGetImage" @chooseImg="pickImg"
                      @closeImgDialog="closeHomeImgDialog"></imgPicker>
          <div class="frm-tips">
            <p>1. 最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M（建议尺寸：500px * 500px）</p>
          </div>
        </el-form-item>
      </el-card>

    </el-form>
    <div class="content-center" style="margin:20px 0">
      <!--      v-if="hasSaveButton"-->
      <el-button type="primary"  @click="submitActivityAction()">保存</el-button>
      <el-button @click.native="handleCancel">返回</el-button>
    </div>
    <materialVideoSelect :store-visible="showChooseDialog" :is-valid="true" :rel-data-ids="customizeList" :get-status="CustomizeStatus" @chooseStore="CustomizeChooseAction" @closeStoreDialog="closeDialogAction1"></materialVideoSelect>

  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import imgPicker from '@/components/imageselect'
import SkuSelector from '@/components/function/skuSelector'
import {getGradeList} from '../../../../api/membercard'
import {listVipGrade} from '../../../../api/cardticket'
import materialVideoSelect from '@/components/function/materialVideoSelect'
import {
  createLimitPromotions, getLimitPromotionsInfo, updateLimitPromotions, seckillActivityGetItemsList,
  addShowPart, editShowPart, getShowPartList, getPromoDetail
} from '../../../../api/promotions'
import {
  addRealMaterialData, editRealAfterMaterial, getRealAfterMaterialDetail
} from '../../../../api/aftersale_video'
import { getItemsList, getCategory, getTagList, getGoodsAttr } from '@/api/goods'
import {handleUploadFile, exportUploadTemplate} from '../../../../api/common'

export default {
  components: {
    SkuSelector,
    Treeselect,
    imgPicker,
    materialVideoSelect
  },
  data() {
    return {
      canEdit :false,
      CustomizeStatus: false,
      customizeList:[],
      showChooseDialog: false,
      isGetImage: false,
      imgDialog: false,
      homeimgDialog: false,
      is_edit: false,
      dateTime: '',
      loading: false,
      relItems: [],
      activity_date: '',
      validGrade: [],
      vipGrade: [],
      memberGrade: [],
      rule: {
        day: 0,
        limit: 1,
      },
      limit_num_type: 'sku',
      isSelectSku: false,
      form: {
        page_link: {customize_id: 0,material_url:'',material_name:''},
        title: '',
        alias_name: '',
        material_desc: '',
        can_push: false,
        status: true,
        cover_pic: ''

      },
      relMain: [],
      setMainStatus: false,
      mainVisible: false,
      hasSaveButton: true,
      zdItemHidden: false,
      categoryHidden: true,
      categoryList: [],
      tagHidden: true,
      tag: {
        list: [],
        form: {
          tag_ids: [],
        },
        currentTags: [],
        tags: []
      },
      brandHidden: true,
      brand: {
        list: [],
        form: {
          brand_ids: [],
        },
        currentBrands: [],
        brands: []
      },
      ItemsList: [],
      invalidItemsList: [],
      params: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        templates_id: '',
        keywords: '',
        category: 0,
        is_warning: false,
        tag_id: '',
        is_gift: 'all',
        type: 0,
        is_sku: 'true',
        item_id: '',
      },
    }
  },
  methods: {
    addCustomizeAction() {
      this.CustomizeStatus = true
      this.showChooseDialog = true
    },
    closeDialogAction1 () {
      this.CustomizeStatus = false
      this.showChooseDialog = false
    },
    CustomizeChooseAction (data) {
      this.showChooseDialog = false
      this.CustomizeStatus = false
      if (data === null || data.length <= 0) return
      console.log("CustomizeChooseAction------------------data:", data)
      this.form.customize_info  = data[0]
      this.form.page_link.customize_id    =  this.form.customize_info.id
      this.form.page_link.material_url= this.form.customize_info.material_url
      this.form.page_link.material_name = this.form.customize_info.material_name
      this.form.customize_id = this.form.page_link.customize_id
    },
    pickImg (data) {
      console.log('add pic',data)
      this.form.cover_pic = data.url
      this.homeimgDialog = false
    },
    closeHomeImgDialog () {
      this.homeimgDialog = false
    },
    handleHomeImgChange () {
      console.log('david--this.isGetImage');
      this.homeimgDialog = true
      this.isGetImage = true
    },
    signUpDateTimeChange() {
      const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      if (dateTime) {
        this.form.start_time = dateTime[0] === undefined ? 0 : dateTime[0]
        this.form.end_time = dateTime[1] === undefined ? 0 : dateTime[1]
      } else {
        this.form.start_time = this.form.end_time = 0
      }
    },
    submitActivityAction() {
      const that = this
      // const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      // let startTime = ''
      // let endTime = ''
      // if (dateTime) {
      //   startTime = dateTime[0] === undefined ? 0 : dateTime[0]
      //   endTime = dateTime[1] === undefined ? 0 : dateTime[1]
      // } else {
      //   startTime = endTime = 0
      // }
      let formData = JSON.parse(JSON.stringify(this.form))
      if (this.$route.query.id) {
        editRealAfterMaterial(this.$route.query.id, formData).then(response => {
          this.loading = false
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()
      } else {
        addRealMaterialData(formData).then(response => {
          this.loading = false
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2 * 1000,
            onClose() {
              that.$router.go(-1)
            }
          })
        }).catch()

      }},
    getItems(data) {
      this.ItemsList = []
      let items = []
      data.forEach(item => {
        var itemData = {
          'item_id': item.item_id,
          'goods_id': item.goods_id,
          'default_item_id': item.default_item_id,
          'item_name': item.item_name,
          'item_bn': item.item_bn,
          'item_spec_desc': item.item_spec_desc,
          'limit_num_type': this.limit_num_type,
          'limit_num': item.limit_num ? item.limit_num : 0
        }
        if (item.is_default == '1') {
          itemData.item_spec_desc = '全规格'
          items.push(itemData)
        }
        console.log('david:', items)

        this.ItemsList.push(item);
      })
      this.form.items = items
    },
    getLimitPromotionsInfo(id) {
      this.loading = true
      console.log('david++++',id)
      getRealAfterMaterialDetail({"id":id}).then(response => {
        let res = response.data.data
        let data = {
          title: res.title,
          alias_name: res.alias_name,
          can_push: res.can_push,
          status: res.status,
          cover_pic: res.cover_pic,
          material_desc: res.material_desc,
          page_link: {}
        }
        if(data.can_push == 1){
          data.can_push = true
        }else{
          data.can_push = false
        }
        if(data.status == 1){
          data.status = true
        }else{
          data.status = false
        }
        data.page_link.customize_id = res.customize_info.id
        data.page_link.material_url = res.customize_info.material_url
        data.page_link.material_name = res.customize_info.material_name
        console.log('hidavid', data)
        Object.assign(this.form, data)

        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    handleCancel() {
      this.$router.back(-1)
    },
    getListVipGrade() {
      listVipGrade().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          this.vipGrade = response.data.data
        }
      })
    },
    getGradeList() {
      getGradeList().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          var result = response.data.data
          if (result) {
            this.memberGrade = result
          }
        }
      })
    },
    itemTypeChange: function (val) {
      this.params.main_cat_id = ''
      this.params.tag_id = ''
      this.params.brand_id = ''
      this.zdItemHidden = true
      this.categoryHidden = true
      this.tagHidden = true
      this.brandHidden = true
      this.form.items = []
      this.invalidItemsList = []
      this.form.rel_item_ids = []
      this.form.itemTreeLists = []
      this.form.item_category = []
      this.form.item_category = []
      this.tag.currentTags = []
      if (val === "goods") {
        this.zdItemHidden = false
      } else if (val === "category") {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.categoryHidden = false
        this.form.item_category = []
      } else if (val === "tag") {
        this.tagHidden = false
        this.tag.currentTags = []
        this.showTags()
      } else if (val === "brand") {
        this.brandHidden = false
        this.brand.currentBrands = []
        this.showBrands()
      }
    },
    fetchMainCate: function () {
      getCategory({is_main_category: true}).then(response => {
        this.categoryList = response.data.data
      })
    },
    addItemTag: function () {
      this.tag.currentTags = []
      if (this.item_id.length) {
        this.showTags()
        this.tag.form.item_ids = this.item_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个商品!'
        })
      }
    },
    showTags: function () {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
    },
    tagRemove: function (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
      this.form.tag_ids = []
      let tagItems = []
      let tagInvalidItems = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
        let items = []

        this.ItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) items.push(i)
        })
        tagItems = items

        let invalidItems = []
        this.invalidItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) invalidItems.push(i)
        })
        tagInvalidItems = invalidItems
      })
      this.ItemsList = tagItems
      this.invalidItemsList = tagInvalidItems
      this.getItems(this.ItemsList)
    },
    tagAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
      this.params.tag_id = item.tag_id
      this.getGoodsList()
    },
    getAllTagLists: function () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
      })
    },
    // 获取品牌列表
    getBrandList: function (searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({attribute_name: item.attribute_name, attribute_id: item.attribute_id})
        }
        this.brand.list = list
      })
    },
    showBrands: function () {
      this.brand.brands = [...this.brand.list]
      this.brand.brands.forEach((item, index) => {
        let isInArr = this.brand.currentBrands.findIndex((n) => n.attribute_id == item.attribute_id)
        if (isInArr != -1) this.brand.brands.splice(index, 1)
      })
    },
    brandAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.brand.currentBrands.findIndex(n => n.attribute_id == item.attribute_id)
      if (isInArr == -1) {
        this.brand.currentBrands.push(item)
        this.brand.brands.splice(index, 1)
      }
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
      this.params.brand_id = item.attribute_id
      this.getGoodsList()
    },
    brandRemove: function (index) {
      let items = []
      this.ItemsList.forEach(item => {
        if (this.brand.currentBrands[index].attribute_id != item.brand_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (this.brand.currentBrands[index].attribute_id != i.brand_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)

      this.brand.brands.unshift(this.brand.currentBrands[index])
      this.brand.currentBrands.splice(index, 1)
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    getGoodsList: function () {
      let params = JSON.parse(JSON.stringify(this.params))
      if (this.$route.params.limit_id) {
        params.activity_id = this.$route.params.limit_id
      }
      params.seckill_type = this.form.seckill_type
      params.activity_release_time = params.activity_start_time = this.activity_date[0]
      params.activity_end_time = this.activity_date[1]
      if (params.is_gift == 'all') {
        this.$delete(params, 'is_gift')
      }
      seckillActivityGetItemsList(params).then(response => {
        let itemList = response.data.data.list.validItems.concat(this.ItemsList)
        let invalidItemList = response.data.data.list.invalidItems.concat(this.invalidItemsList)
        let newItemList = []
        let invalidItems = []
        let obj = {}
        let obj1 = {}
        itemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj[item.item_id]) {
            newItemList.push(item)
            obj[item.item_id] = true
          }
        })
        invalidItemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj1[item.item_id]) {
            invalidItems.push(item)
            obj1[item.item_id] = true
          }
        })

        this.ItemsList = newItemList
        this.invalidItemsList = invalidItems
        this.getItems(this.ItemsList)
      })
    },
    categorySelect: function (node, instanceId) {
      this.params.main_cat_id = node.category_id
      this.getGoodsList()
    },
    categoryDeselect: function (node, instanceId) {
      let items;
      items = [];
      this.ItemsList.forEach((item, index) => {
        if (node.category_id != item.item_main_cat_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (node.category_id != i.item_main_cat_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)
    },
    generateSku() {
      let noSkuItem;
      noSkuItem = [];
      let response = []
      let goodsList = JSON.parse(JSON.stringify(this.relItems))
      goodsList.forEach(item => {
        if (!item.nospec && item.spec_items.length === 0) {
          noSkuItem.push(item.default_item_id)
        }
      })
      if (noSkuItem.length > 0) {
        let param = this.params
        param.item_id = noSkuItem
        getItemsList(this.params).then(res => {
          goodsList.forEach(item => {
            if (!item.nospec) {
              res.data.data.list.forEach(sku => {
                if (item.item_id === sku.default_item_id) {
                  item.spec_items.push(sku)
                }
              })
            }
          })
          goodsList.forEach(item => {
            if (!item.nospec) {
              response = [...response, ...item.spec_items]
            } else {
              response = [...response, item]
            }
          })
          this.ItemsList = response
          this.getItems(response)
        })
      } else {
        goodsList.forEach(item => {
          if (!item.nospec) {
            response = [...response, ...item.spec_items]
          } else {
            response = [...response, item]
          }
        })
        this.ItemsList = response
        this.getItems(response)
      }
    },
    /**
     * 下载模板
     * */
    uploadHandleTemplate() {
      let params = {file_type: 'marketing_goods', file_name: '商品模板'}
      exportUploadTemplate(params).then(response => {
        let {data} = response.data
        if (data.file) {
          var a = document.createElement('a')
          a.href = data.file
          a.download = data.name
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    /**
     * 上传模板
     * */
    uploadHandleChange(file, fileList) {

      let params = {isUploadFile: true, file_type: 'marketing_goods', file: file.raw}
      handleUploadFile(params).then(response => {
        this.$message({
          type: 'success',
          message: '上传成功'
        })

        let {data} = response.data

        if (data.fail.length > 0) {
          let str = data.fail.map(item => {
            return item.item_bn
          })

          setTimeout(() => {
            this.$message({
              showClose: true,
              message: `以下商品编号不存在：${str}`,
              type: 'error',
              duration: 100000
            })
          }, 1500)
        }
        if (data.succ.length <= 0) return
        this.relItems = data.succ
        let list = []
        data.succ.forEach(item => {
          if (!item.nospec) {
            list.push(Object.assign(item, {spec_items: []}))
          } else {
            list.push(item)
          }
        })
      })
    },

    /**
     * 切换sku/spu
     */
    changeLimitNumType(){
      this.getItems(this.ItemsList)
      if (this.limit_num_type === 'sku' || this.limit_num_type === 'skus') {
        this.isSelectSku = true
      } else {
        this.isSelectSku = false
      }
    }
  },
  mounted() {
    // this.getListVipGrade()
    // this.getGradeList()
    if (this.$route.query.isshow) {
      this.hasSaveButton = false
    }
    console.log(this.$route.query.id)
    // if (this.$route.params.limit_id) {
    //   this.limit_id = this.$route.params.limit_id
    //   this.getLimitPromotionsInfo(this.$route.params.limit_id)
    // }
    if (this.$route.query.id) {
      this.activity_id = this.$route.query.id
      this.is_edit = true
      this.canEdit = true
      this.getLimitPromotionsInfo(this.$route.query.id)
    }
    // this.fetchMainCate()
    // this.getAllTagLists()
    // this.getBrandList('', true)
  }
}
</script>

<style scoped type="text/css">
.video-html {
  width: 160px !important;
  height: 90px !important;
}
.video-html-fix .el-form-item__content{
  display:flex;
  flex-direction: column;

}

</style>
