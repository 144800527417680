<template>
  <div>
    <el-form ref=form :model=form class=box-set label-width=120px>
      <el-form-item label="标题">
        <el-input v-model="form.privacy_title" class="input-b"></el-input>
      </el-form-item>

      <el-form-item label="内容">
        <template>
          <vue-html5-editor :content="form.privacy_content.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
          <span class="tpl_item img" @click="addImgPreview" style="">
          <i class="iconfont icon-image"></i>图片
        </span>
        </template>
        <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>
      </el-form-item>

      <div class=content-center style="margin: 20px 0">
        <el-button
          type=primary
          @click="submit"
          :loading="submitBtnLoading"
        >保存
        </el-button>
        <el-button @click.native=handleCancel>返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>

import {
  createPrivacyAgreement,
  updatePrivacyAgreement,
  getPrivacyAgreementDetail,
} from '@/api/promotions'
import store from '@/store'
import imgPicker from '@/components/imageselect'

export default {
  inject: ['refresh'],
  components: {
    imgPicker,
  },
  data () {
    return {
      thumbDialog: false,
      isGetThumb: false,
      id: 0,
      is_edit: false,
      submitBtnLoading: false,
      form: {
        privacy_title: '',
        privacy_content: '',
      },
    }
  },
  methods: {
    submit() {
      this.submitBtnLoading = true
      let form = {
        ...JSON.parse(JSON.stringify(this.form))
      }

      if (this.id) {
        form.id = this.id
        updatePrivacyAgreement(form).then(response => {
          if (response.data.data.success) {
            this.$message.success('更新成功')
            this.$router.go(-1)
          } else {
            this.$message.error('更新失败')
          }
          this.submitBtnLoading = false
        }) . catch (error => {
          // this.$message.error('更新失败')
          this.submitBtnLoading = false
        })
      } else {
        createPrivacyAgreement(form).then(response => {
          if (response.data.data.success) {
            this.$message.success('创建成功')
            this.$router.go(-1)
          } else {
            this.$message.error('创建失败')
          }
          this.submitBtnLoading = false
        }) . catch (error => {
          // this.$message.error('创建失败')
          this.submitBtnLoading = false
        })
      }
    },
    handleCancel: function () {
      this.$router.go(-1)
    },

    getPrivacyAgreementDetail() {
      let params = {
        'id': this.id
      }
      getPrivacyAgreementDetail(params).then(response => {
        if (response.data.data) {
          let res = response.data.data
          this.form = res
          this.dateTime = [res.sign_up_start_time, res.sign_up_end_time]
          this.useAllDistributor = res.use_all_distributor || 'all'
          if (res.distributor_ids) {
            this.relDistributorIds = res.distributor_ids
          }
        }
      });
    },

    updateContent: function (data) {
      this.form.privacy_content = data
    },
    addImgPreview: function () {
      this.thumbDialog = true
      this.isGetThumb = true
    },
    pickThumb: function (arr) {
      if (arr.length != 0) {
        arr.forEach(data => {
          if (data && data.url !== '') {
            this.thumbDialog = false
            var index = this.$refs.editor.$el.id
            var loc = this.$refs.editor
            var img = new Image()
            img.src = this.wximageurl + data.url
            if (loc.range) {
              loc.range.insertNode(img)
              var referenceNode = loc.range.endContainer
              if (referenceNode.className !== 'content') {
                loc.range.setStartAfter(referenceNode)
              } else {
                loc.range.setStart(loc.range.endContainer, loc.range.endOffset)
              }
            } else {
              loc.$refs.content.appendChild(img)
              loc.focus()
              loc.restoreSelection()
            }
            this.form.privacy_content = loc.$refs.content.innerHTML
          }
        })
      }
    },
    closeThumbDialog: function () {
      this.thumbDialog = false
    },
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.is_edit = true

      this.getPrivacyAgreementDetail();
    }
    this.loader = false
  },
}
</script>

<style>
  .vue-html5-editor {
    vertical-align: top;
    display: inline-block;
  }
  .vue-html5-editor {
    width: 80%;
  }
</style>
