<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="输入标签名称" v-model="tag_name" clearable>
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
      </el-col>
      <el-col :span="12" v-if="actionAccess">
        <el-button type="primary" size="medium" icon="el-icon-circle-plus" @click="handleCreateTag()">新增机型标签</el-button>
      </el-col>
      <el-col :span="12" v-if="actionAccess" class="fr">
        <el-switch
          v-model="open_status"
          class="fr"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="true"
          inactive-value="false"
          active-text="开启同步"
          inactive-text="关闭同步"
          @change="setOpenStatus"
        />
      </el-col>
    </el-row>
    <el-card>
      <el-table
        :data="tagList"
        row-key="tag_id"
        ref='tableTree'
        default-expand-all
        v-loading="loading"
        :height="wheight-180"
        :tree-props="{children: 'children'}">
        <el-table-column
          label="机型标签"
          min-width="280">
          <template slot-scope="scope">
            <el-input
              v-if="actionAccess"
              v-model="scope.row.tag_name"
              class="input-b"
              size="mini"
              @change="handleNameChange(scope.row)"
            />
            <span v-else class="input-s" size="mini">{{ scope.row.tag_name }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="sort"
          label="标签排序"
          sortable
          min-width="150">
          <template slot-scope="scope">
            <el-input class="input-s" type="number" size="mini" v-model="scope.row.sort" v-if="actionAccess"></el-input>
            <span v-else class="input-s" size="mini">{{ scope.row.sort }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="tag_code"
          label="标签编码"
          min-width="180">
        </el-table-column> -->
        <el-table-column
          prop="tag_code"
          label="标签编号"
          min-width="120">
        </el-table-column>
        <el-table-column
          prop="tag_desc"
          label="标签描述"
          min-width="120">
        </el-table-column>
        <el-table-column
          prop="created"
          label="创建时间"
          min-width="120">
          <template slot-scope="scope">
            {{ scope.row.created | datetime }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="actionAccess"
          width="180"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.level < 4" @click="handleCreateTag(scope.row)">新增子标签</el-button>
            <el-button type="text" @click.native.prevent="deleteTag(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getOpenStatus, setOpenStatus, getTagList, deleteTag, updateTag } from './api'
import { useDialog } from '@shopex/finder'

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '保存', key: 'save', type: 'primary', size: 'default' }
]

export default {
  components: {},
  data() {
    return {
      open_status: 'true',
      tag_name: '',
      login_type: 'default',
      loading: false,
      tagList: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ]),
    // 操作权限
    actionAccess() {
      return ['admin', 'staff', 'disti' ].includes(this.login_type)
    }
  },
  mounted() {
    this.init()
    this.login_type = this.$store.getters.login_type
  },
  methods: {
    handleSearch() {
      this.init()
    },
    init() {
      this.loading = true
      const params = {
        tag_name: this.tag_name
      }
      getTagList(params).then(response => {
        this.tagList = response.data.data
        this.loading = false
      })
      getOpenStatus().then(response => {
        this.open_status = response.data.data.status
      })
    },
    setOpenStatus() {
      let msg = '关闭同步'
      if (this.open_status === 'true') {
        msg = '开启同步'
      }
      this.$confirm('此操作将' + msg + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setOpenStatus({status: this.open_status}).then(response => {
          this.$message({
            type: 'success',
            message: msg + '成功'
          })
          this.init()
        })
      })
    },
    deleteTag(data) {
      this.$confirm('此操作将删除该标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTag(data.tag_id).then(response => {
          this.$message({
            type: 'success',
            message: '删除标签成功'
          })
          this.init()
        })
      })
    },
    handleCreateTag(row) {
      useDialog(null, {
        title: row ? `新增子标签（${row.tag_name}）` : '新增机型标签',
        width: '30%',
        row,
        component: () => import('./components/createTag.vue'),
        actions: commonActions
      }).then(arg => {
        if(arg) this.init()
      })
    },
    async handleNameChange(row) {
      const { tag_id, tag_name } = row
      let data = {
        tag_id,
        tag_name
      }
      await updateTag(data)
      this.$message.success('修改成功')
      this.init()
    },
  }
};
</script>
