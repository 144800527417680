<template>
	<div style="text-align:center;height:80px;line-height: 80px;">
		商城固定头部
	</div>
</template>
<script>
export default {

}
</script>
<style>

</style>

