<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      :hooks="{
        beforeSearch
      }"
      @reset="onReset"
    >
      <template #tableTop>
        <div class="table-top">
          <div>
            <el-tabs v-model="activeTab" type="card" @tab-click="handleClickTab">
              <el-tab-pane
                v-for="item in StatusOption"
                :key="item.value"
                :label="item.label"
                :name="item.value"
              />
            </el-tabs>
          </div>
        </div>
      </template>
      <template #shop>
        <el-button class="btn_input" @click="handleCloseDialogAction()">
          {{ shop_num }}
          <i v-if="relDistributors.length===0" class="el-icon-circle-plus icon"></i>
          <i v-else class="el-icon-circle-close icon" @click.stop="clearDistributor()"></i>
        </el-button>
      </template>
    </SpFinder>
    <DistributorSelect
      :store-visible="distributorVisible"
      :rel-data-ids="relDistributors"
      :get-status="distributorStatus"
      @chooseStore="DistributorChooseAction"
      @closeStoreDialog="handleCloseDialogAction"
    />
  </div>
</template>
<script>
import { tableSetting, StatusOption } from './config'
import { List, handleExport } from './api'
import DistributorSelect from '@/components/function/distributorSelect'

export default {
  components: {
    DistributorSelect
  },
  data () {
    return {
      activeTab: '0',
      table_url: List,
      StatusOption,
      shop_num: '按门店筛选',
      distributorVisible: false,
      distributorStatus: false,
      relDistributors: [],
      shop_code: ''
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
  },
  methods: {
    refresh() {
      this.$refs.finder.refresh()
    },
    handleClickTab() {
      this.refresh()
    },
    openExportDialog() {
      const searchparams = this.$refs.finder.getSearchParams()
      let params = {
        ...searchparams,
        buy_shop_code: this.shop_code,
        order_status: this.activeTab,
        is_export: 1
      }
      handleExport(params).then(_ => {
        this.$message.success('导出成功，请前往导出列表查看')
      })
    },
    // 选择店铺
    DistributorChooseAction(data){
      let arr = []
      data.forEach(i => {
        arr.push(i.shop_code)
      });
      this.shop_code = arr.join(',')
      this.relDistributors = data
      if (data.length > 0) {
        this.shop_num = `已选择${data.length}门店`
      } else {
        this.shop_num = '按门店筛选'
      }
      this.handleCloseDialogAction()
    },
    handleCloseDialogAction(){
      this.distributorStatus = !this.distributorStatus
      this.distributorVisible = !this.distributorVisible
    },
    clearDistributor(){
      this.shop_code = ''
      this.relDistributors = []
      this.shop_num = '按门店筛选'
    },
    beforeSearch(params) {
      return {
        ...params,
        order_status: this.activeTab,
        buy_shop_code: this.shop_code
      }
    },
    onReset() {
      this.activeTab = '0'
      this.shop_code = ''
      this.relDistributors = []
      this.shop_num = '按门店筛选'
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}
</style>

<style lang="scss">
.el-tooltip__popper {
  max-width: 80%;
}
</style>
