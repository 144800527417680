<template>
  <div>
    <el-form ref=form :model=form class=box-set label-width=120px disabled v-loading.fullscreen.lock="dataLoading">
      <el-card header=基础信息>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="*活动标题">
              <el-input v-model="form.activity_title" class="input-b" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="活动副标题">
              <el-input v-model="form.activity_subtitle" class="input-b"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="使用平台">
          <el-radio-group v-model="form.platform" size="mini">
            <el-radio-button label="all">全部平台</el-radio-button>
            <el-radio-button label="wxapp">微信小程序</el-radio-button>
            <el-radio-button label="byte_mini">抖音小程序</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="*描述">
          <el-input
            type="textarea"
            v-model="form.activity_desc"
            :autosize="{ minRows: 6, maxRows: 20}"
            style="width: 50%"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="*活动主图">
          <div>
            <div>
              <div @click="handleCoverImgChange" class="upload-box">
                <img v-if="form.cover_url" :src="wximageurl + form.cover_url" class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="coverImgDialog" :sc-status="isGetPics" @chooseImg="pickCoverImg"
                       @closeImgDialog="closeHomeImgDialog"></imgPicker>
            <div class="frm-tips">
              <p>最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M</p>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="视频">
          <videoPicker :data="itemVideo" @change="pickVideo"></videoPicker>
          <el-button v-if="itemVideo.media_id" @click="deleteVideo" type="text">删除</el-button>
        </el-form-item>
        <el-form-item label="视频自动播放">
          <el-radio-group v-model="form.video_auto_play" size="mini">
            <el-radio-button label="1">开启</el-radio-button>
            <el-radio-button label="0">关闭</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="视频封面">
          <div>
            <div>
              <div @click="handleVideoCoverImgChange" class="upload-box">
                <img v-if="form.video_cover_url" :src="wximageurl + form.video_cover_url" class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="videoCoverImgDialog" :sc-status="isGetPics" @chooseImg="pickVideoCoverImg"
                       @closeImgDialog="closeVideoImgDialog"></imgPicker>
            <div class="frm-tips">
              <p>最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M</p>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="企微分享图">
          <div>
            <div>
              <div @click="handleShareImgChange" class="upload-box">
                <img v-if="form.share_img_url" :src="wximageurl + form.share_img_url" class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="shareImgDialog" :sc-status="isGetPics" @chooseImg="pickShareImg"
                       @closeImgDialog="closeShareImgDialog"></imgPicker>
            <div class="frm-tips">
              <p>1.最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M</p>
              <p>2.建议大小500*400</p>
            </div>
          </div>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="*活动类型">
              <el-radio-group v-model="form.join_type" size="mini" :disabled="is_edit">
                <el-radio label="appointment">预约报名</el-radio>
                <el-radio label="scene">现场报名</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="*每人可报名次数">
              <el-input-number v-model="form.join_limit_num" type="number" class="input-m" :min="1" ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="防黄牛" v-if="form.join_type !== 'scene'">
              <el-radio-group v-model="form.sign_up_check" size="mini">
                <el-radio-button label="1">开启</el-radio-button>
                <el-radio-button label="0">关闭</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="16">
            <el-form-item label="礼品">
              <el-input
                type="textarea"
                v-model="form.gift"
                :autosize="{ minRows: 2, maxRows: 10}"
                style="width: 50%"
              >
              </el-input>
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="多个礼品使用回车分隔，每一行作为一个礼品">
                <i class="el-icon-question" slot="reference"></i>
              </el-popover>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="是否开启">
              <el-radio-group v-model="form.is_open" size="mini">
                <el-radio-button label="1">开启</el-radio-button>
                <el-radio-button label="0">关闭</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="*隐私协议">
              <template>
                <span v-if="selectPrivacyTitle">{{this.selectPrivacyTitle}}</span>
                <el-button size="mini" @click="handlePrivacyAgreement" type="primary">选择</el-button>
                <el-button v-if="selectPrivacyTitle" size="mini" @click="handleDeletePrivacyAgreement" type="danger">删除</el-button>
              </template>
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="*报名时间">
              <el-date-picker
                v-model="dateTime"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                @change="signUpDateTimeChange"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="是否需要签到">
              <el-radio-group v-model="form.need_sign_in" :disabled="is_end === 2" size="mini" @change="changeNeedSignIn">
                <el-radio label="true">是</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="核销方式">
              <el-radio-group v-model="form.sign_in_system" size="small" :disabled="is_end === 2" @change="changeSignInSystem">
                <el-radio label="local">数字签到码</el-radio>
                <el-radio label="guestbook">GuestBook</el-radio>
                <el-radio label="wecom">Wecom</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card header=活动日期及场次 v-show="form.join_type === 'appointment'">
        <!--活动预约可选场次-->
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="*场次">
              <el-button type="primary" plain @click="openSessionDrawer" style="margin-bottom: 15px">编辑</el-button>
              <div style="height: 600px; width: 100%; overflow-y:auto;">
                <el-card v-for="(session, sessionIndex) in form.sessions" :key="sessionIndex" style="width: 400px; border-bottom: #8c939d 1px">
                  <p>{{session.session_date/1000 | datetime('yyyy-MM-dd')}}</p>
                  <div v-for="(name, sessionNameIndex) in session.session_names" style="">
                    场次：<span>{{session.session_names[sessionNameIndex].name}}</span>
                  </div>
                </el-card>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card header="图文详情" v-loading="loader">
        <template>
          <vue-html5-editor :content="form.intro.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
          <span class="tpl_item img" @click="addImgPreview" style="">
          <i class="iconfont icon-image"></i>图片
        </span>
        </template>
        <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>
      </el-card>



      <el-drawer
        title="预约场次"
        :visible.sync="drawerVisible"
        size="500px"
        :before-close="handleDrawerClose"
        direction="rtl">
        <div style="padding: 20px">
          <el-button type="primary" plain @click="addSessionDate" style="margin-bottom: 20px">新增日期</el-button>

          <el-card v-for="(session, sessionIndex) in sessions" :key="sessionIndex">
            <el-date-picker
              v-model="sessions[sessionIndex].session_date"
              type="date"
              value-format="timestamp"
              :picker-options="pickerOptions"
              placeholder="选择日期">
            </el-date-picker>
            <el-button type="danger" plain @click="deleteSessionDate(sessionIndex)" icon="el-icon-delete" size="mini" circle></el-button>
            <p v-for="(name, sessionNameIndex) in session.session_names" style="margin-top: 20px">
              场次：<el-input class="input-b" v-model="session.session_names[sessionNameIndex].name" minlength="1"></el-input>
              <el-button type="danger" plain @click="deleteSessionName(sessionIndex, sessionNameIndex)" icon="el-icon-delete" size="mini" circle></el-button>
              <el-button v-if="sessionNameIndex === session.session_names.length - 1" type="primary" plain @click="addSessionName(sessionIndex)" icon="el-icon-plus" size="mini" circle></el-button>
            </p>
          </el-card>
          <div>
            <el-button type=primary class="center" @click="handleSessions">确认</el-button>
          </div>
        </div>
      </el-drawer>

      <el-dialog title="选择隐私协议" :visible.sync="privacyVisible">
        <el-table
          :data="privacyAgreementList"
          highlight-current-row
          @current-change="handlePrivacyAgreementChange">
          <el-table-column property="id" label="编号" width="150"></el-table-column>
          <el-table-column property="privacy_title" label="标题"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="privacyVisible = false">取 消</el-button>
          <el-button type="primary" @click="choosePrivacyAgreement">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>

    <div class=content-center style="margin: 20px 0">
      <el-button @click.native=handleCancel>返回</el-button>
    </div>

    <DistributorSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :relDataIds="relDistributorIds"
      :get-status="setDistributorStatus"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDistributorDialogAction"
      returnType="selectRow"
    ></DistributorSelect>
  </div>
</template>

<script>

import {
  createOfflineActivity,
  updateOfflineActivity,
  getOfflineActivityDetail,
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  getPrivacyAgreementList,
} from '@/api/promotions'
import store from '@/store'
import imgPicker from '@/components/imageselect'
import DistributorSelect from '@/components/function/distributorSelectOfflineActivity'
import videoPicker from '@/components/videoselect'

export default {
  inject: ['refresh'],
  components: {
    videoPicker,
    imgPicker,
    DistributorSelect,
  },
  data () {
    return {
      dataLoading: false,
      is_end: 0, // 活动是否已结束
      distributorStatusLoading: false,
      activity_id: '',
      is_edit: false,
      coverImgDialog: false,
      shareImgDialog: false,
      isGetPics: false,
      drawerVisible: false,
      loader: true,
      thumbDialog: false,
      isGetThumb: false,
      submitBtnLoading: false,
      useAllDistributor: 'distributor',
      relDistributorIds: [],
      distributorVisible: false,
      setDistributorStatus: false,
      distributor_info: [],
      privacyVisible: false,
      selectPrivacyTitle: '',
      privacyAgreementList: [],
      itemVideo: {},
      videoCoverImgDialog: false,
      join_limit_num_min: 1,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date(new Date().toLocaleDateString()).getTime());
        },
      },
      form: {
        activity_title: '',
        activity_subtitle: '',
        activity_desc: '',
        cover_url: '',
        share_img_url: '',
        join_type: 'appointment',
        sign_up_start_time: 0,
        sign_up_end_time: 0,
        intro: '',
        join_limit_num: 1,
        sign_up_check: 0,
        gift: '',
        is_open: 1,
        sessions: [],
        distributor_ids: [],
        privacy_agreement: 0,
        videos: '',
        video_url: '',
        video_cover_url: '',
        video_auto_play: 0,
        delete_session_ids: [],
        sign_in_system: 'local',
        need_sign_in: 'true',
      },
      deleteSessionIds: [],
      dateTime: '',
      sessions: [],
      bindStorePagination: { // 已绑定店铺&经销商分页数据
        page: 1,
        page_size: 20
      },
      totalStoreCount: 0,
      privacyPagination: {
        page: 1,
        pageSize: 20
      },
      privacyPaginationTotalCount: 0,
      privacyRow: {},
    }
  },
  methods: {
    changeNeedSignIn() {
      if (this.form.need_sign_in === 'false' && this.form.sign_in_system !== 'local') {
        this.$message.error('核销方式必须为数字签到码')
        this.form.need_sign_in = 'true'
      }
    },
    changeSignInSystem() {
      let oldSignInSystem = this.form.sign_in_system
      console.log(oldSignInSystem)
      if (this.form.need_sign_in === 'false' && this.form.sign_in_system !== 'local') {
        this.$message.error('关闭签到仅支持数字签到码核销方式')
        this.form.sign_in_system = 'local'
      }
    },
    signUpDateTimeChange() {
      const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      if (dateTime) {
        this.form.sign_up_start_time = dateTime[0] === undefined ? 0 : dateTime[0]
        this.form.sign_up_end_time = dateTime[1] === undefined ? 0 : dateTime[1]
      } else {
        this.form.sign_up_start_time = this.form.sign_up_end_time = 0
      }
    },
    openSessionDrawer() {
      this.sessions = JSON.parse(JSON.stringify(this.form.sessions));
      this.deleteSessionIds = JSON.parse(JSON.stringify(this.form.delete_session_ids || []));
      this.drawerVisible = true
    },
    addSessionDate() {
      let defaultSessionData = {}
      if (this.sessions.length > 0) {
        defaultSessionData = JSON.parse(JSON.stringify(this.sessions[0]))
        defaultSessionData.session_date = ''
        defaultSessionData.session_names.forEach(v => {
          v.session_id = 0
        })
      } else {
        defaultSessionData = {
          session_date: '',
          session_names: [{'session_id': 0, 'name': ''}],
        }
      }
      this.sessions.push(defaultSessionData)
    },
    deleteSessionDate(index) {
      console.log(index, this.sessions[index])
      if (this.sessions[index].session_names.length > 0) {
        for (let i = 0; i < this.sessions[index].session_names.length; i ++) {
          this.deleteSessionIds.push(this.sessions[index].session_names[i].session_id)
        }
      }
      this.sessions.splice(index, 1)
    },
    addSessionName(sessionIndex) {
      this.sessions[sessionIndex].session_names.push({'session_id': 0, 'name': ''})
    },
    deleteSessionName(sessionIndex, sessionNameIndex) {
      this.deleteSessionIds.push(this.sessions[sessionIndex].session_names[sessionNameIndex].session_id)
      this.sessions[sessionIndex].session_names.splice(sessionNameIndex, 1)
    },
    handleSessions() {
      let _self = this
      let pass = true
      this.sessions.forEach(function (session_date) {
        console.log(session_date)
        if (session_date.session_names.length > 0) {
          session_date.session_names.forEach(function (session_name){
            if (session_name.name.trim() == '') {
              _self.$message.error('场次名称不能为空')
              pass = false
              return false;
            }
          })
        }
      })
      if (!pass) {
        return false
      }
      this.form.sessions = JSON.parse(JSON.stringify(this.sessions));
      this.form.delete_session_ids = JSON.parse(JSON.stringify(this.deleteSessionIds))
      this.sessions = []
      this.deleteSessionIds = []
      this.drawerVisible = false
    },
    handleDrawerClose() {
      this.$confirm('确认关闭？关闭后将不会保留已编辑的数据！')
        .then(_ => {
          this.drawerVisible = false
        })
        .catch(_ => {});
    },
    handleCoverImgChange () {
      this.coverImgDialog = true
      this.isGetPics = true
    },
    handleShareImgChange () {
      this.shareImgDialog = true
      this.isGetPics = true
    },
    pickCoverImg (data) {
      this.form.cover_url = data.url
      this.coverImgDialog = false
    },
    pickShareImg (data) {
      console.log(111)
      this.form.share_img_url = data.url
      this.shareImgDialog = false
    },
    handleVideoCoverImgChange () {
      this.videoCoverImgDialog = true
      this.isGetPics = true
    },
    pickVideoCoverImg (data) {
      this.form.video_cover_url = data.url
      this.videoCoverImgDialog = false
    },
    closeHomeImgDialog () {
      this.coverImgDialog = false
    },
    closeVideoImgDialog () {
      this.videoCoverImgDialog = false
    },
    closeShareImgDialog () {
      this.shareImgDialog = false
    },
    updateContent: function (data) {
      this.form.intro = data
    },
    addImgPreview: function () {
      this.thumbDialog = true
      this.isGetThumb = true
    },
    pickThumb: function (arr) {
      if (arr.length != 0) {
        arr.forEach(data => {
          if (data && data.url !== '') {
            this.thumbDialog = false
            var index = this.$refs.editor.$el.id
            var loc = this.$refs.editor
            var img = new Image()
            img.src = this.wximageurl + data.url
            if (loc.range) {
              loc.range.insertNode(img)
              var referenceNode = loc.range.endContainer
              if (referenceNode.className !== 'content') {
                loc.range.setStartAfter(referenceNode)
              } else {
                loc.range.setStart(loc.range.endContainer, loc.range.endOffset)
              }
            } else {
              loc.$refs.content.appendChild(img)
              loc.focus()
              loc.restoreSelection()
            }
            this.form.intro = loc.$refs.content.innerHTML
          }
        })
      }
    },
    closeThumbDialog: function () {
      this.thumbDialog = false
    },

    submitOfflineActivity() {
      this.submitBtnLoading = true
      let form = {
        ...JSON.parse(JSON.stringify(this.form))
      }
      form.distributor_ids = [] // 编辑活动忽略店铺
      form.use_all_distributor = this.useAllDistributor
      if (form.use_all_distributor === 'all') {
        form.distributor_ids = ''
      }
      if (this.activity_id) {
        form.activity_id = this.activity_id
        updateOfflineActivity(form).then(response => {
          if (response.data.data.activity_id) {
            this.$message.success('更新成功')
            this.$router.go(-1)
          } else {
            this.$message.error('更新失败')
          }
          this.submitBtnLoading = false
        }) . catch (error => {
          // this.$message.error('更新失败')
          this.submitBtnLoading = false
        })
      } else {
        createOfflineActivity(form).then(response => {
          if (response.data.data.activity_id) {
            this.$message.success('创建成功')
            this.$router.go(-1)
          } else {
            this.$message.error('创建失败')
          }
          this.submitBtnLoading = false
        }) . catch (error => {
          // this.$message.error('创建失败')
          this.submitBtnLoading = false
        })
      }
    },
    handleCancel: function () {
      this.$router.go(-1)
    },

    shopTypeChange (val) {
      // this.form.distributor_ids = []
      this.distributor_info = []
      this.setDistributorStatus = false
      if (this.activity_id) {
        // this.setBindStoreList()
      }
    },
    async setBindStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: bindList } = await getBindOfflineActivityStoreList({
        activity_id: this.activity_id,
        ...this.bindStorePagination
      }) .catch (error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributor_info = bindList.data.list
    },
    addDistributorAction () {
      this.distributorVisible = true
      this.setDistributorStatus = true
      // this.relDistributorIds = this.form.distributor_ids
    },
    chooseDistributorAction (data) {
      this.distributorVisible = false
      if (!data || data === null || data.length <= 0) {
        this.distributor_info = []
        return
      }
      this.distributor_info = data
      this.form.distributor_ids = []
      for (let i = 0; i < data.length; i++) {
        const id = data[i].distributor_id
        this.form.distributor_ids.push(Number(id))
      }
    },
    closeDistributorDialogAction () {
      this.distributorVisible = false
    },
    deleteDistritutorRow (index) {
      this.distributor_info.splice(index, 1)
      let shopDatas = this.distributor_info
      this.form.distributor_ids = []
      for (let i = 0; i < shopDatas.length; i++) {
        if (this.form.distributor_ids.indexOf(Number(shopDatas[i].distributor_id)) < 0) {
          this.form.distributor_ids.push(Number(shopDatas[i].distributor_id))
        }
      }
      this.relDistributorIds = this.form.distributor_id
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setBindStoreList()
    },
    changeDistributorJoinStatus(row) {
      this.distributorStatusLoading = true
      if (!this.activity_id) {
        this.$message.error('编辑失败')
        return
      }
      let changeStatus = 1
      if (row.status === '1') {
        changeStatus = 2
      }
      let params = {
        status: changeStatus,
        distributor_id: row.distributor_id,
        activity_id: this.activity_id,
      }
      changeDistributorJoinStatus(params).then(response => {
        if (response.data.data.success == true) {
          this.distributorStatusLoading = false
          this.$message.success('修改成功')
          this.setBindStoreList()
        } else {
          this.$message.error('修改失败')
          this.distributorStatusLoading = false
        }
      }).catch(error => {
        // this.$message.error('修改失败')
        this.distributorStatusLoading = false
      })
    },

    getOfflineActivityDetail() {
      this.dataLoading = true
      let params = {
        'activity_id': this.activity_id
      }
      getOfflineActivityDetail(params).then(response => {
        if (response.data.data) {
          let res = response.data.data
          this.is_end = res.is_end
          this.form = res
          this.itemVideo = {media_id: res.video_url, url:res.video_url}
          this.selectPrivacyTitle = res.privacy_title
          this.dateTime = [res.sign_up_start_time, res.sign_up_end_time]
          this.useAllDistributor = res.use_all_distributor || 'all'
          if (res.distributor_ids) {
            this.relDistributorIds = res.distributor_ids
          }
          this.join_limit_num_min = res.join_limit_num
          this.dataLoading = false
        }
      }).catch (error => {
        this.dataLoading = false
      });
    },
    getPrivacyAgreementList() {
      let params = this.privacyPagination
      getPrivacyAgreementList(params).then(response => {
        this.privacyAgreementList = response.data.data.list
        this.privacyPaginationTotalCount = response.data.data.total_count
      })
    },
    handlePrivacyAgreement() {
      this.getPrivacyAgreementList()
      this.privacyVisible = true
    },
    handlePrivacyAgreementChange(val) {
      this.privacyRow = val
    },
    choosePrivacyAgreement() {
      this.selectPrivacyTitle = this.privacyRow.privacy_title
      this.form.privacy_agreement = this.privacyRow.id
      this.privacyVisible = false
    },
    handleDeletePrivacyAgreement() {
      this.selectPrivacyTitle = ''
      this.form.privacy_agreement = 0
    },
    pickVideo (data) {
      this.form.videos = data.media_id
      this.form.video_url = data.url
    },
    deleteVideo() {
      this.itemVideo = {}
      this.form.videos = ''
      this.form.video_url = ''
    },
  },
  mounted () {
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id
      this.is_edit = true

      this.getOfflineActivityDetail();

      // this.setBindStoreList();
    }
    this.loader = false
  },
}
</script>

<style>
.vue-html5-editor {
  vertical-align: top;
  display: inline-block;
}
.vue-html5-editor {
  width: 80%;
}
</style>
